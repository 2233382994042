import React, { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'

import { BrandColors, SystemColors } from '../../tokens/colors'
import { Icons } from '../../tokens/icons'
import { MediaQueries } from '../../tokens/mediaQueries'
import { Opacities } from '../../tokens/opacities'
import { rem } from '../../utils/rem'
import Body from '../body/Body'
import Icon from '../icon/Icon'
import Row from '../row/Row'
import SizedBox from '../sized-box/SizedBox'

export interface InfoBoxProps {
  closeBtn?: () => void
  plainBackground?: boolean
  variant: 'success' | 'info' | 'warning' | 'danger'
}

const InfoBox: FunctionComponent<React.PropsWithChildren<InfoBoxProps>> = ({
  children,
  plainBackground,
  variant,
  closeBtn,
}) => (
  <StyledInfoBox variant={variant} plainBackground={plainBackground} crossAxisAlignment="flex-start">
    <ColoredIcon icon={Icons[variant]} variant={variant} size={rem(20)} as="div" />
    <SizedBox width={rem(10)} />
    <Body size="medium" emphasis>
      {children}
    </Body>
    {closeBtn && (
      <ColoredCloseIcon icon={Icons['cross']} variant={variant} size={rem(20)} as="div" onClick={closeBtn} />
    )}
  </StyledInfoBox>
)

export default InfoBox

const ColoredIcon = styled(Icon)<Pick<InfoBoxProps, 'variant'>>`
  color: ${({ variant }) => SystemColors[variant].toString()};
  transform: translateY(0.125rem);
`

const ColoredCloseIcon = styled(ColoredIcon)`
  cursor: pointer;
`

const StyledInfoBox = styled(Row)<InfoBoxProps>`
  padding: ${rem(14)} ${rem(10)};
  width: 100%;

  ${MediaQueries.desktop} {
    padding: ${rem(18)} ${rem(16)};
    margin-top: ${rem(12)};
  }

  ${({ variant, plainBackground }) =>
    plainBackground
      ? css`
          outline: 1px solid ${BrandColors.white.alpha(Opacities.twelve).toString()};
          outline-offset: -1px;
          background: ${BrandColors.white.alpha(Opacities.eight).toString()};
          border-left: 4px solid ${SystemColors[variant].toString()};
        `
      : css`
          border-left: 4px solid ${SystemColors[variant].toString()};
          background: ${SystemColors[variant].alpha(Opacities.twelve).toString()};
          border-left: 4px solid ${SystemColors[variant].toString()};
        `}
`
