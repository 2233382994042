const allMusicLibraryLicenseBasePricesFragment = /* GraphQL */ `
  fragment AllMusicLibraryLicenseBasePrices on MusicLibraryLicenseBasePrices {
    socialMediaBranded
    advertisementAllMedia
    advertisementAudioOnly
    advertisementOnlineOnly
    corporateNonProfitCharity
    corporateNonProfitCompanyPresentation
    corporateNonProfitEducational
    filmLargeOnlineOnly
    filmLargeAllMedia
    filmLargeTrailer
    filmSmallAllMedia
    filmSmallOnlineOnly
    filmSmallTrailer
    gamesAppsInAppGame
    gamesAppsTrailerPromo
    podcastPrivateBranded
    podcastPrivateNonBranded
    radioTvAudioOnly
    radioTvTrailer
    radioTvTvShow
    socialMediaNonBranded
  }
`

export const getMusicLibraryLicenseBasePrices = /* GraphQL */ `
  query GetMusicLibraryLicenseBasePrices {
    getMusicLibraryLicenseBasePrices {
      ...AllMusicLibraryLicenseBasePrices
    }
  }

  ${allMusicLibraryLicenseBasePricesFragment}
`

export const editMusicLibraryTrackConfig = /* GraphQL */ `
  mutation editMusicLibraryTrackConfig($input: EditMusicLibraryTrackConfigInput!) {
    editMusicLibraryTrackConfig(input: $input) {
      id
    }
  }
`

export const editMusicLibraryProductConfig = /* GraphQL */ `
  mutation editMusicLibraryProductConfig($input: EditMusicLibraryProductConfigInput!) {
    editMusicLibraryProductConfig(input: $input) {
      id
    }
  }
`

export const enableMusicLibraryProductConfig = /* GraphQL */ `
  mutation enableMusicLibraryProductConfig($productId: ID!) {
    enableMusicLibraryProductConfig(productId: $productId) {
      id
    }
  }
`

export const disableMusicLibraryProductConfig = /* GraphQL */ `
  mutation disableMusicLibraryProductConfig($productId: ID!) {
    disableMusicLibraryProductConfig(productId: $productId) {
      id
    }
  }
`

export const disableMusicLibraryTrackConfig = /* GraphQL */ `
  mutation disableMusicLibraryTrackConfig($trackId: ID!) {
    disableMusicLibraryTrackConfig(trackId: $trackId) {
      id
    }
  }
`

export const editMusicLibraryConfigSetting = /* GraphQL */ `
  mutation editMusicLibraryConfigSetting($productId: ID!, $input: EditMusicLibraryConfigSettingInput!) {
    editMusicLibraryConfigSetting(productId: $productId, input: $input) {
      id
    }
  }
`

export const createSyncLicenseOrder = /* GraphQL */ `
  mutation createSyncLicenseOrder($input: CreateSyncLicenseOrderInput) {
    createSyncLicenseOrder(input: $input) {
      ...SyncLicenseOrderInfo
    }
  }

  fragment SyncLicenseOrderInfo on CreateSyncLicenseOrderResponse {
    paypalOrderId
    price
    totalOrderAmount
    vatAmount
    syncLicenseId
  }
`

export const capturePaypalOrder = /* GraphQL */ `
  mutation capturePaypalOrder($orderId: String) {
    orderCaptured: capturePaypalOrder(paypalOrderId: $orderId)
  }
`

export const getSyncLicense = /* GraphQL */ `
  query GetSyncLicense($id: ID!) {
    getSyncLicense(syncLicenseId: $id) {
      trackAudio {
        expiresAt
        url
        path
      }
    }
  }
`

export const musicLibraryTrackFragment = /* GraphQL */ `
  fragment MusicLibraryTrack on PublicTrack {
    id
    name
    version
    trackArtists {
      primary
      position
      artist {
        name
      }
    }
    trackCode {
      code
    }
    alternateGenre
    subgenre
    genre
    alternateSubgenre
    cyaniteMetaData {
      moods
      keyPredictionValue
      predominantVoiceGender
      subgenres
      genres
      instruments
      bpmRangeAdjusted
    }
    bpm
    audioLength
    product {
      label
      artwork {
        small {
          url
          expiresAt
          expiresIn
        }
        medium {
          url
          expiresAt
          expiresIn
        }
      }
    }
    contributors {
      role
      name
      publisher
    }
    previewAudio {
      url
      expiresAt
    }
    compressedAudio {
      url
      expiresAt
    }
    musicLibraryTrackConfig {
      socialMediaBranded
      advertisementAllMedia
      advertisementAudioOnly
      advertisementOnlineOnly
      corporateNonProfitCharity
      corporateNonProfitCompanyPresentation
      corporateNonProfitEducational
      filmLargeOnlineOnly
      filmLargeAllMedia
      filmLargeTrailer
      filmSmallAllMedia
      filmSmallOnlineOnly
      filmSmallTrailer
      gamesAppsInAppGame
      gamesAppsTrailerPromo
      podcastPrivateBranded
      podcastPrivateNonBranded
      radioTvAudioOnly
      radioTvTrailer
      radioTvTvShow
      socialMediaNonBranded
      enabled
      submittedAt
    }
  }
`

const musicLibraryStatementItemFragment = /* GraphQL */ `
  fragment MusicLibraryStatementItem on SyncLicenseStatement {
    createdAt
    payoutAmount
    syncLicense {
      group {
        name
      }
      createdAt
    }
    track {
      ...MusicLibraryTrack
    }
  }
`

export const getPublicTrack = /* GraphQL */ `
  query GetPublicTrack($trackId: ID!) {
    getPublicTrack(id: $trackId) {
      id
      name
      version
      musicLibraryTrackConfig {
        socialMediaBranded
        advertisementAllMedia
        advertisementAudioOnly
        advertisementOnlineOnly
        corporateNonProfitCharity
        corporateNonProfitCompanyPresentation
        corporateNonProfitEducational
        filmLargeOnlineOnly
        filmLargeAllMedia
        filmLargeTrailer
        filmSmallAllMedia
        filmSmallOnlineOnly
        filmSmallTrailer
        gamesAppsInAppGame
        gamesAppsTrailerPromo
        podcastPrivateBranded
        podcastPrivateNonBranded
        radioTvAudioOnly
        radioTvTrailer
        radioTvTvShow
        socialMediaNonBranded
        enabled
      }
      product {
        label
        artwork {
          small {
            url
            expiresAt
            expiresIn
          }
          medium {
            url
            expiresAt
            expiresIn
          }
        }
      }
      bpm
      genre
      subgenre
      alternateGenre
      alternateSubgenre
      trackCode {
        code
      }
      contributors {
        name
        role
      }
      trackArtists {
        primary
        position
        artist {
          name
        }
      }
      audioLength
      previewAudio {
        url
        expiresAt
      }
      compressedAudio {
        url
        expiresAt
      }
      cyaniteMetaData {
        moods
        keyPredictionValue
        predominantVoiceGender
        subgenres
        genres
        instruments
        bpmRangeAdjusted
      }
    }
  }
`

export const getGroupSyncLicenseStatements = /* GraphQL */ `
  query GetGroupSyncLicenseStatements($groupId: ID!, $filters: getSyncLicenseStatementsFilters!) {
    getGroupSyncLicenseStatements(groupId: $groupId, filters: $filters) {
      ...MusicLibraryStatementItem
    }
  }

  ${musicLibraryStatementItemFragment}
  ${musicLibraryTrackFragment}
`

export const getUserSyncLicenseStatements = /* GraphQL */ `
  query getUserSyncLicenseStatements($filters: getSyncLicenseStatementsFilters!) {
    getUserSyncLicenseStatements(filters: $filters) {
      ...MusicLibraryStatementItem
    }
  }

  ${musicLibraryStatementItemFragment}
  ${musicLibraryTrackFragment}
`

export const getMyMLStatementsExport = /* GraphQL */ `
  query getMyMLStatementsExport($filters: getSyncLicenseStatementsFilters!) {
    getMyMLStatementsExport(filters: $filters) {
      url
      expiresAt
    }
  }
`

export const getGroupMLStatementsExport = /* GraphQL */ `
  query getGroupMLStatementsExport($filters: getSyncLicenseStatementsFilters!, $groupId: ID!) {
    getGroupMLStatementsExport(filters: $filters, groupId: $groupId) {
      url
      expiresAt
    }
  }
`

export const getMySyncLicenses = /* GraphQL */ `
  query GetMySyncLicenses($groupId: ID!) {
    getMySyncLicenses(groupId: $groupId) {
      ...MusicLibraryLicenseItem
    }
  }

  fragment MusicLibraryLicenseItem on SyncLicense {
    licenseCategory
    projectName
    receipt {
      url
      path
    }
    invoice {
      url
      path
    }
    order {
      createdAt
      totalAmount
      vatAmount
    }
    trackAudio {
      url
      path
    }
    track {
      ...MusicLibraryTrack
    }
  }
  ${musicLibraryTrackFragment}
`

export const filterMusicLibraryTracks = /* GraphQL */ `
  query FilterMusicLibraryTracks(
    $filters: MusicLibraryTrackFilters!
    $page: Int
    $size: Int
    $sort: MusicLibrarySortOption
    $limit: Int
  ) {
    results: filterMusicLibraryTracks(filters: $filters, page: $page, size: $size, sort: $sort, limit: $limit) {
      totalCount
      tracks {
        ...MusicLibraryTrack
      }
    }
    basePrices: getMusicLibraryLicenseBasePrices {
      ...AllMusicLibraryLicenseBasePrices
    }
  }
  ${musicLibraryTrackFragment}
  ${allMusicLibraryLicenseBasePricesFragment}
`

export const getMusicLibraryLicensePrice = /* GraphQL */ `
  query getMusicLibraryLicensePrice(
    $licenseCategory: SyncLicenseCategory!
    $licenseDuration: SyncLicenseDurations!
    $licenseSetting: String!
    $licenseTrackLength: SyncLicenseTrackLengths!
  ) {
    getMusicLibraryLicensePrice(
      licenseCategory: $licenseCategory
      licenseDuration: $licenseDuration
      licenseSetting: $licenseSetting
      licenseTrackLength: $licenseTrackLength
    )
  }
`

export const getMusicLibraryFilterSettings = /* GraphQL */ `
  query GetMusicLibraryFilterSettings {
    getMusicLibraryFilterSettings {
      genres
      subgenres
      moods
      instruments
      keywords {
        id
        keyword
        weight
      }
      keys
    }
  }
`

export const searchTrackKeywords = /* GraphQL */ `
  query SearchTrackKeywords($filter: SearchTrackKeywordFilter!) {
    searchTrackKeywords(filter: $filter) {
      id
      keyword
      weight
    }
  }
`
