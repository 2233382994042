import React, { type FunctionComponent, useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import { v4 as uuid } from 'uuid'

import { MediaQueries } from '../../tokens/mediaQueries'
import { rem } from '../../utils/rem'
import FileInput, { type FileInputHTMLAttributes } from '../file-input/FileInput'
import FormField, { type FormFieldProps } from '../form-field/FormField'
import Image from '../image/Image'
import Row from '../row/Row'
import SizedBox from '../sized-box/SizedBox'
import imagePlaceholder from './imagePlaceholder.svg'

export interface ImageFieldProps extends Omit<FormFieldProps, 'htmlFor'> {
  alt?: string
  id?: string
  image?: string
  input?: FileInputHTMLAttributes
  placeholderImage?: string
}

const ImageField: FunctionComponent<React.PropsWithChildren<ImageFieldProps>> = ({
  id,
  image: url,
  placeholderImage = imagePlaceholder,
  input,
  alt,
  ...props
}) => {
  const idRef = useRef(id ?? uuid())
  const [file, setFile] = useState<File>()
  const imageProps = {
    alt,
    height: '100%',
    width: '100%',
  }

  useEffect(() => {
    const inputFile = input?.files?.[0]
    if (inputFile && inputFile !== file) {
      setFile(inputFile)
    }
  }, [input, file])

  const source = useMemo(() => {
    if (file) return URL.createObjectURL(file)

    if (url) return url

    return placeholderImage
  }, [file, url, placeholderImage])

  return (
    <FormField {...props} htmlFor={idRef.current}>
      <Row crossAxisAlignment="center">
        <ImageWrapper>
          <Image src={source} {...imageProps} />
        </ImageWrapper>
        <ResponsiveSizedBox width={rem(16)} />
        <FileInput {...input} disabled={!!props.disabled} label="Choose image" accept=".png, .jpeg, .jpg, .bmp" />
      </Row>
    </FormField>
  )
}

const ImageWrapper = styled.div`
  position: relative;
  display: block;
  overflow: hidden;
  width: ${rem(120)};
  height: ${rem(120)};

  ${MediaQueries.desktop} {
    width: ${rem(160)};
    height: ${rem(160)};
  }
`

const ResponsiveSizedBox = styled(SizedBox)`
  ${MediaQueries.desktop} {
    width: ${rem(32)};
  }
`

export default ImageField
