import { type AddEthereumChainParameter } from '@web3-react/types'

export const ETH: AddEthereumChainParameter['nativeCurrency'] = {
  decimals: 18,
  name: 'Ether',
  symbol: 'ETH',
}

const MATIC: AddEthereumChainParameter['nativeCurrency'] = {
  decimals: 18,
  name: 'Matic',
  symbol: 'MATIC',
}

interface BasicChainInformation {
  name: string
  urls: (string | undefined)[]
}

interface ExtendedChainInformation extends BasicChainInformation {
  blockExplorerUrls: AddEthereumChainParameter['blockExplorerUrls']
  nativeCurrency: AddEthereumChainParameter['nativeCurrency']
}

export const chains: {
  [chainId: number]: BasicChainInformation | ExtendedChainInformation
} = {
  1: {
    name: 'Mainnet',
    urls: [
      process.env.infuraKey ? `https://mainnet.infura.io/v3/${process.env.infuraKey}` : undefined,
      process.env.alchemyKey ? `https://eth-mainnet.alchemyapi.io/v2/${process.env.alchemyKey}` : undefined,
      'https://cloudflare-eth.com',
    ],
  },
  // Optimism
  10: {
    blockExplorerUrls: ['https://optimistic.etherscan.io'],
    name: 'Optimistic Ethereum',
    nativeCurrency: ETH,
    urls: [
      process.env.infuraKey ? `https://optimism-mainnet.infura.io/v3/${process.env.infuraKey}` : undefined,
      'https://mainnet.optimism.io',
    ],
  },

  // Polygon
  137: {
    blockExplorerUrls: ['https://polygonscan.com'],
    name: 'Polygon Mainnet',
    nativeCurrency: MATIC,
    urls: ['https://polygon-rpc.com'],
  },

  3: {
    name: 'Ropsten',
    urls: [process.env.infuraKey ? `https://ropsten.infura.io/v3/${process.env.infuraKey}` : undefined],
  },

  4: {
    name: 'Rinkeby',
    urls: [process.env.infuraKey ? `https://rinkeby.infura.io/v3/${process.env.infuraKey}` : undefined],
  },

  42: {
    name: 'Kovan',
    urls: [process.env.infuraKey ? `https://kovan.infura.io/v3/${process.env.infuraKey}` : undefined],
  },

  // Arbitrum
  42161: {
    blockExplorerUrls: ['https://arbiscan.io'],
    name: 'Arbitrum One',
    nativeCurrency: ETH,
    urls: [
      process.env.infuraKey ? `https://arbitrum-mainnet.infura.io/v3/${process.env.infuraKey}` : undefined,
      'https://arb1.arbitrum.io/rpc',
    ],
  },

  421611: {
    blockExplorerUrls: ['https://testnet.arbiscan.io'],
    name: 'Arbitrum Testnet',
    nativeCurrency: ETH,
    urls: [
      process.env.infuraKey ? `https://arbitrum-rinkeby.infura.io/v3/${process.env.infuraKey}` : undefined,
      'https://rinkeby.arbitrum.io/rpc',
    ],
  },

  5: {
    name: 'Görli',
    urls: [process.env.infuraKey ? `https://goerli.infura.io/v3/${process.env.infuraKey}` : undefined],
  },

  69: {
    blockExplorerUrls: ['https://kovan-optimistic.etherscan.io'],
    name: 'Optimistic Kovan',
    nativeCurrency: ETH,
    urls: [
      process.env.infuraKey ? `https://optimism-kovan.infura.io/v3/${process.env.infuraKey}` : undefined,
      'https://kovan.optimism.io',
    ],
  },
  80001: {
    blockExplorerUrls: ['https://mumbai.polygonscan.com'],
    name: 'Polygon Mumbai',
    nativeCurrency: MATIC,
    urls: ['https://polygon-mumbai.g.alchemy.com/v2/TfYxph9fhlpIRalgUVLNpZdqLxtlDH1W'],
  },
}
