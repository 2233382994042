import React, { type FunctionComponent } from 'react'
import styled from 'styled-components'

import { BrandColors } from '../../tokens/colors'
import { Opacities } from '../../tokens/opacities'
import { Transitions } from '../../tokens/transitions'
import { rem } from '../../utils/rem'
import { type InputProps } from '../input/Input'
import Row from '../row/Row'

export interface RangeInputProps extends Omit<InputProps, 'type' | 'value'> {
  max?: number
  min?: number
  value: number
}

const RangeInput: FunctionComponent<React.PropsWithChildren<RangeInputProps>> = ({
  min = 0,
  max = 100,
  value = 0,
  ...props
}) => {
  const progress = ((value - min) / (max - min)) * 100

  return (
    <Wrapper crossAxisAlignment="center" mainAxisAlignment="center">
      <Input type="range" {...props} value={value} min={min} max={max} />
      <Track>
        <Progress style={{ width: `${progress}%` }} />
        <HandleWrapper>
          <Handle style={{ left: `${progress}%` }} />
        </HandleWrapper>
      </Track>
    </Wrapper>
  )
}

export default RangeInput

const trackSize = rem(2)
const handleSize = rem(6)

const HandleWrapper = styled.div`
  position: relative;
  // Make sure the handle stay's inside the track and is aligned in the middle.
  height: calc(${trackSize} / 2);
  width: ${`calc(100% - ${handleSize})`};
  margin: calc(${trackSize} / 2) auto 0;
`

const Handle = styled.div`
  position: absolute;
  background: ${BrandColors.white.toString()};
  height: ${handleSize};
  width: ${handleSize};
  transform: translate(-50%, -50%);
  transition: opacity ${Transitions.micro};
  border-radius: 50%;
  opacity: 0;
`

const Track = styled.div`
  position: absolute;
  background: ${BrandColors.white.alpha(Opacities.thirtyfive).toString()};
  height: ${trackSize};
  width: 100%;
  left: 0;
  pointer-events: none;
`

const Progress = styled(Track)`
  position: absolute;
  background: ${BrandColors.white.toString()};
`

const Wrapper = styled(Row)`
  position: relative;
  height: 100%;
  width: 100%;

  input:hover ~ ${Track} {
    ${Handle} {
      opacity: ${Opacities.full};
    }
  }

  input:disabled ~ ${Track} {
    opacity: ${Opacities.thirtyfive};

    ${Handle} {
      opacity: 0;
    }
  }
`

const Input = styled.input`
  position: relative;
  padding: 0;
  margin: 0;
  width: 100%;
  height: ${handleSize};
  background: transparent;
  -webkit-appearance: none;
  opacity: 0;

  :focus {
    outline: none;
  }
`
