import React, { FunctionComponent, ReactChild } from 'react'
import styled, { css } from 'styled-components'

import { SystemColors } from '../../tokens/colors'
import { Opacities } from '../../tokens/opacities'
import { Transitions } from '../../tokens/transitions'
import { rem } from '../../utils/rem'
import Caption from '../caption/Caption'
import Column from '../column/Column'
import ConstrainedBox from '../constrained-box/ConstrainedBox'
import InfoDialog, { InfoDialogProps } from '../info-dialog/InfoDialog'
import Row from '../row/Row'
import SizedBox from '../sized-box/SizedBox'

export interface FormFieldProps {
  disabled?: boolean
  error?: ReactChild | boolean
  hideHint?: boolean
  hint?: ReactChild
  htmlFor?: string
  indicator?: ReactChild
  infoDialog?: InfoDialogProps
  label?: ReactChild
  reservedHintSpace?: string
}

const FormField: FunctionComponent<React.PropsWithChildren<FormFieldProps>> = ({
  children,
  disabled = false,
  error,
  hint,
  htmlFor,
  indicator,
  label,
  infoDialog,
  hideHint,
  reservedHintSpace = rem(28),
}) => (
  <Wrapper disabled={disabled} error={!!error}>
    {(label || indicator) && (
      <>
        <Row mainAxisAlignment="space-between" crossAxisAlignment="baseline">
          <Row crossAxisAlignment="center">
            <Label as="label" htmlFor={htmlFor} noWrap data-gtm-name="form-field-label">
              {label}
            </Label>
            <SizedBox width={rem(8)} />
            {infoDialog && <InfoDialog {...infoDialog} />}
          </Row>
          <SizedBox width={rem(32)} />
          <Indicator noWrap>{indicator}</Indicator>
        </Row>
        <SizedBox height={rem(infoDialog ? 8 : 12)} />
      </>
    )}
    {children}
    {!hideHint && (
      <ConstrainedBox minHeight={reservedHintSpace}>
        {((error && typeof error !== 'boolean') || hint) && <SizedBox height={rem(8)} />}
        {error && typeof error !== 'boolean' ? (
          <Error as="div" data-gtm-name="form-field-error-message">
            {error}
          </Error>
        ) : (
          hint && <Hint as="div">{hint}</Hint>
        )}
      </ConstrainedBox>
    )}
  </Wrapper>
)

export default FormField

interface WrapperProps {
  disabled: boolean
  error: boolean
}

const Wrapper = styled(Column)<WrapperProps>(
  ({ disabled, error }) => css`
    transition: ${Transitions.micro};
    position: relative;

    ${error &&
    css`
      color: ${SystemColors.danger.toString()};
    `}
    ${disabled &&
    css`
      pointer-events: none;

      ${Label},
      ${Indicator},
      ${Hint},
      ${Error} {
        opacity: ${Opacities.thirtyfive};
      }
    `};
  `,
)

const Label = styled(Caption)`
  font-weight: bold;
`

const Indicator = styled(Caption)`
  opacity: ${Opacities.fiftyfive};
`

const Error = styled(Caption)`
  color: ${SystemColors.danger.toString()};
`

const Hint = styled(Caption)`
  opacity: ${Opacities.fiftyfive};
`
