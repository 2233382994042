/**
 *
 * TODO: Mostly copied from ledo-web - should be re-used via @ledo-react
 *
 */

import { rem } from 'ledo-react'
import React, { FunctionComponent, ReactChild } from 'react'
import styled, { css } from 'styled-components'

export type IndentationLevels = 'default' | 'one'

interface BulletListProps {
  indentationLevel?: IndentationLevels
  listItems: ReactChild[]
  listStyleType?: string
}

const BulletList: FunctionComponent<React.PropsWithChildren<BulletListProps>> = ({
  indentationLevel = 'default',
  listItems,
  listStyleType = 'disc',
}) => (
  <StyledBulletList indentationLevel={indentationLevel} listStyleType={listStyleType}>
    {listItems.map((item, key) => (
      <ListItem key={key}>
        <ItemWrapper>{item}</ItemWrapper>
      </ListItem>
    ))}
  </StyledBulletList>
)

interface StyledBulletListProps {
  indentationLevel: IndentationLevels
  listStyleType: string
}

const StyledBulletList = styled.ul<StyledBulletListProps>`
  ${({ indentationLevel, listStyleType }) => css`
    list-style-type: ${listStyleType};

    ${indentationLevel === 'default' &&
    css`
      padding-left: ${rem(18)};
    `}

    ${indentationLevel === 'one' &&
    css`
      padding-left: ${rem(36)};
    `}
  `}
`

const ListItem = styled.li`
  :not(:first-child) {
    margin-top: ${rem(8)};
  }
`

const ItemWrapper = styled.span`
  position: relative;
  left: -${rem(2)};
`

export default BulletList
