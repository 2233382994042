import React, { type FunctionComponent, useRef } from 'react'
import styled from 'styled-components'
import { v4 as uuid } from 'uuid'

import { BrandColors, SystemColors } from '../../tokens/colors'
import { Opacities } from '../../tokens/opacities'
import { Transitions } from '../../tokens/transitions'
import { rem } from '../../utils/rem'
import { type InputProps } from '../input/Input'

export interface ToggleProps extends Omit<InputProps, 'type' | 'value'> {
  disabled?: boolean
  error?: boolean
  label?: string
}

const Toggle: FunctionComponent<React.PropsWithChildren<ToggleProps>> = (props) => {
  const idRef = useRef(props.id ?? uuid())

  return (
    <>
      <NativeCheckbox id={idRef.current} aria-label={props.label} {...props} />
      <Checkbox
        aria-label={props.label}
        checked={props.checked}
        error={props.error}
        disabled={props.disabled}
        htmlFor={idRef.current}
      />
    </>
  )
}

export default Toggle

const Checkbox = styled.label<ToggleProps>`
  position: relative;
  cursor: pointer;
  width: ${rem(56)};
  height: ${rem(32)};
  border-radius: ${rem(16)};
  flex-shrink: 0;
  border: ${rem(1)} solid ${BrandColors.white.alpha(Opacities.thirtyfive).toString()};

  :hover {
    background-color: ${BrandColors.white.alpha(Opacities.eight).toString()};
  }

  input:focus + & {
    ::before {
      content: '';
      border: ${rem(1)} solid ${SystemColors.info.toString()};
      position: absolute;
      width: ${rem(62)};
      height: ${rem(38)};
      z-index: -1;
      top: ${rem(-4)};
      right: ${rem(-4)};
      border-radius: ${rem(100)};
    }
  }

  input:disabled + & {
    opacity: ${Opacities.thirtyfive};
    pointer-events: none;
    cursor: auto;
  }

  &::after {
    content: '';
    display: block;
    border-radius: 50%;
    width: ${rem(26)};
    height: ${rem(26)};
    margin-top: ${rem(2)};
    margin-left: ${rem(2)};
    background-color: ${BrandColors.white.toString()};
    transition: ${Transitions.micro};
  }

  ${({ error }) =>
    error &&
    `
    border:${rem(1)} solid
    ${SystemColors.danger.toString()};
  `}
`
const NativeCheckbox = styled.input.attrs({ type: 'checkbox' })<ToggleProps>`
  position: absolute;
  z-index: -999;
  opacity: 0;

  &:checked + ${Checkbox} {
    background-color: ${BrandColors.springGreen.toString()};

    :hover {
      background-color: ${BrandColors.springGreen.lighten(Opacities.eight).toString()};
    }

    input:focus + & {
      background-color: ${BrandColors.white.alpha(Opacities.eight).toString()};
      outline-color: ${SystemColors.info.alpha(Opacities.fiftyfive).toString()};
    }

    input:disabled + & {
      opacity: ${Opacities.thirtyfive};
    }

    ${({ error }) =>
      error &&
      `
    background-color: ${SystemColors.danger.toString()};

    :hover {
      background-color: ${SystemColors.danger.lighten(Opacities.eight).toString()};
    }
  `}

    &::after {
      content: '';
      display: block;
      border-radius: 50%;
      width: ${rem(26)};
      height: ${rem(26)};

      margin-left: ${rem(27)};
      background-color: ${BrandColors.white.toString()};
      transition: ${Transitions.micro};
    }
  }
`
