import { useLocation } from '@gatsbyjs/reach-router'
import { BrandColors, Icon, Icons, MediaQueries, rem } from 'ledo-react'
import React, { type FC, useEffect, useRef, useState } from 'react'
import { useClickAway } from 'react-use'
import styled, { css } from 'styled-components'

import NavigationList from '@/components/navigation-list/NavigationList'
import NavigationListItem from '@/components/navigation-list-tem/NavigationListItem'
import DropdownItem from '@/components/top-bar/dropdown-item/DropdownItem'
import { type NavigationItem } from '@/utils/buildNavLinks'
import matchesActiveOnArray from '@/utils/matchesActiveOnArray'

interface DropdownProps {
  activeOn?: string | string[]
  children: NavigationItem[]
  featured?: boolean
  title: string
}

const NavDropdown: FC<DropdownProps> = ({ children, title, activeOn, featured = false }) => {
  const ref = useRef<HTMLDivElement | null>(null)
  const [open, setOpen] = useState(false)
  useClickAway(ref, () => {
    setOpen(false)
  })

  const { pathname } = useLocation()

  useEffect(() => {
    setOpen(false)
  }, [pathname])

  return (
    <div style={{ position: 'relative' }} ref={ref}>
      <StyledDropdown ref={ref} open={open}>
        {children?.length > 0 && (
          <NavigationList separator={null}>
            {children
              .filter(({ hidden }) => !hidden)
              .map(({ to, activeOn, title }) => (
                <DropdownItem
                  data-gtm-name="main-navigation-select-group"
                  data-gtm-value={title}
                  isActive={matchesActiveOnArray(pathname, activeOn)}
                  key={title}
                  size="small"
                  to={to}
                >
                  {title}
                </DropdownItem>
              ))}
          </NavigationList>
        )}
      </StyledDropdown>
      <NavigationListItem
        active={matchesActiveOnArray(pathname, activeOn)}
        direction="row"
        onClick={() => setOpen((open) => !open)}
        featured={featured}
      >
        <span>{title}</span>
        {open ? <Icon as="div" icon={Icons.chevronUp} /> : <Icon as="div" icon={Icons.chevronDown} />}
      </NavigationListItem>
    </div>
  )
}

NavDropdown.displayName = 'NavDropdown'

export default NavDropdown

interface StyledDropdownProps {
  open: boolean
}

const StyledDropdown = styled.div<StyledDropdownProps>`
  ${({ open = false }) => css`
    position: absolute;
    width: ${rem(320)};
    height: auto;
    max-height: 70vh;
    overflow-y: auto;
    top: ${rem(64)};
    background: ${BrandColors.darkBlue.toString()};
    display: ${open ? 'initial' : 'none'};
    left: 0;

    ${MediaQueries.desktop} {
      top: ${rem(88)};
    }
  `}
`
