import React, {
  cloneElement,
  type ComponentType,
  createContext,
  type FunctionComponent,
  type PropsWithChildren,
  type ReactElement,
  useState,
} from 'react'
import { v4 as uuid } from 'uuid'

import SnackbarContainer from '../components/snackbar-container/SnackbarContainer'
import Timer from '../utils/Timer'

type SnackbarElement = ReactElement<SnackbarComponentProps>

export interface SnackbarComponentProps {
  id?: string
  timer?: Timer
}

export interface SnackbarEntry {
  id: string
  snackbar: SnackbarElement
  timer?: Timer
}

export type AddSnackbarFn = (
  snackbar: SnackbarElement | ((parameters: { id: string }) => SnackbarElement),
  options?: {
    autoDismiss?: number | false
    id?: string
  },
) => string

export type RemoveSnackbarFn = (id: string) => void

export interface SnackbarContextInterface {
  addSnackbar: AddSnackbarFn
  removeSnackbar: RemoveSnackbarFn
}

export const SnackbarContext = createContext<SnackbarContextInterface>({
  addSnackbar: () => {
    console.warn('addSnackbar was called without a <SnackbarProvider>.')

    return 'undefined'
  },
  removeSnackbar: () => {
    console.warn('removeSnackbar was called without a <SnackbarProvider>.')

    return 'undefined'
  },
})

export interface SnackbarProviderProps {
  autoDismiss?: number | false
  container?: ComponentType<React.PropsWithChildren<unknown>>
}

const SnackbarProvider: FunctionComponent<React.PropsWithChildren<PropsWithChildren<SnackbarProviderProps>>> = ({
  autoDismiss = 7000,
  container: Container = SnackbarContainer,
  ...props
}) => {
  const [snackbars, setSnackbars] = useState<SnackbarEntry[]>([])

  const addSnackbar: AddSnackbarFn = (snackbar, options) => {
    const id = options?.id ?? uuid()
    const entryAutoDismiss = options?.autoDismiss ?? autoDismiss

    const timer =
      entryAutoDismiss !== false
        ? new Timer(() => {
            removeSnackbar(id)
          }, entryAutoDismiss)
        : undefined

    setSnackbars((snackbars) => [
      {
        id,
        snackbar: typeof snackbar === 'function' ? snackbar({ id }) : snackbar,
        timer,
      },
      ...snackbars,
    ])

    return id
  }

  const removeSnackbar: RemoveSnackbarFn = (id) => {
    setSnackbars((snackbars) =>
      snackbars.filter((entry) => {
        if (entry.id === id && entry.timer) {
          entry.timer.stop()
        }

        return entry.id !== id
      }),
    )
  }

  return (
    <SnackbarContext.Provider value={{ addSnackbar, removeSnackbar }}>
      <Container>
        {snackbars.map((entry) =>
          cloneElement(entry.snackbar, {
            id: entry.id,
            key: entry.id,
            timer: entry.timer,
          }),
        )}
      </Container>
      {props.children}
    </SnackbarContext.Provider>
  )
}

export default SnackbarProvider

export const SnackbarConsumer = SnackbarContext.Consumer
