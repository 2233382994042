import styled, { css } from 'styled-components'

import { MediaQueries } from '../../tokens/mediaQueries'
import { FontFamily } from '../../tokens/typography'
import { rem } from '../../utils/rem'
import Text, { TextProps } from '../text/Text'

export type LabelSize = 'small' | 'medium' | 'large'

export interface LabelProps extends TextProps {
  color?: string
  colored?: boolean
  size?: LabelSize
}

const Label = styled(Text).attrs({
  as: 'label',
})<LabelProps>`
  font-family: ${FontFamily.regular};
  font-weight: 500;

  color: ${({ color, colored, theme }) => (color ? color : theme[colored ? 'secondary' : 'foreground'].toString())};

  ${({ size = 'large' }) => {
    switch (size) {
      case 'large':
        return css`
          font-size: ${rem(18)};
          line-height: ${rem(20)};

          ${MediaQueries.desktop} {
            font-size: ${rem(20)};
            line-height: ${rem(24)};
          }
        `

      case 'medium':
        return css`
          font-size: ${rem(16)};
          line-height: ${rem(20)};

          ${MediaQueries.desktop} {
            font-size: ${rem(18)};
            line-height: ${rem(24)};
          }
        `

      case 'small':
        return css`
          font-size: ${rem(14)};
          line-height: ${rem(16)};

          ${MediaQueries.desktop} {
            font-size: ${rem(16)};
            line-height: ${rem(20)};
          }
        `
    }
  }};
`

export default Label
