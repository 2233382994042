import { InputHTMLAttributes } from 'react'
import styled, { css } from 'styled-components'

import { BrandColors, SystemColors } from '../../tokens/colors'
import { MediaQueries } from '../../tokens/mediaQueries'
import { Opacities } from '../../tokens/opacities'
import { Transitions } from '../../tokens/transitions'
import { FontFamily } from '../../tokens/typography'
import { rem } from '../../utils/rem'

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  dataCy?: string
  error?: boolean
  light?: boolean
  transparent?: boolean
}

const Input = styled.input<InputProps>`
  appearance: none;
  border-radius: 0;
  width: 100%;
  background: ${BrandColors.blackcurrant.toString()};
  height: ${rem(48)};
  border: 0 solid transparent;
  padding: ${rem(12)} ${rem(16)};
  outline: 1px solid transparent;
  outline-offset: ${rem(2)};
  font-family: ${FontFamily.regular};
  font-size: ${rem(16)};
  color: ${BrandColors.white.toString()};
  line-height: ${rem(24)};
  letter-spacing: ${rem(0)};
  transition: ${Transitions.micro};

  ${MediaQueries.desktop} {
    height: ${rem(56)};
    font-size: ${rem(18)};
  }

  :hover,
  :autofill,
  :-webkit-autofill {
    background: ${BrandColors.white.alpha(Opacities.eight).toString()} !important;
  }

  :focus {
    background: ${BrandColors.white.alpha(Opacities.eight).toString()};
    border: 1px solid rgba(255, 255, 255, 0.35)
    outline-color: transparent;
  }

  :active {
    background: ${BrandColors.white.alpha(Opacities.twelve).toString()};
  }

  :disabled {
    pointer-events: none;
    border-color: ${BrandColors.white.toString()};
    opacity: ${Opacities.thirtyfive};
  }

  ::placeholder {
    color: ${BrandColors.white.alpha(Opacities.thirtyfive).toString()};
  }

  ${({ error }) =>
    !!error &&
    css`
      border-color: ${SystemColors.danger.toString()};
    `}

  ${({ light }) =>
    !!light &&
    css`
      background: ${BrandColors.white.alpha(Opacities.eight).toString()};

      :hover,
      :focus,
      :active {
        background: ${BrandColors.white.alpha(Opacities.twelve).toString()};
      }
    `}

    ${({ transparent }) =>
      !!transparent &&
      css`
        background: transparent;
        border: 1px solid rgba(255, 255, 255, 0.35);
      `}
`

export default Input
