/* eslint-disable @typescript-eslint/no-var-requires */
import Amplify, { Auth } from 'aws-amplify'
import { GatsbyBrowser } from 'gatsby'
import React from 'react'
import TagManager from 'react-gtm-module'

import Layout from './src/components/layout/Layout'

Amplify.configure({
  // Cognito
  Auth: {
    identityPoolId: process.env.AWS_AUTH_IDENTITY_POOL_ID,
    userPoolId: process.env.AWS_AUTH_USER_POOL_ID,
    userPoolWebClientId: process.env.AWS_AUTH_USER_POOL_CLIENT_ID,
  },

  // S3
  Storage: {
    AWSS3: {
      bucket: process.env.AWS_S3_BUCKET,
      region: process.env.AWS_REGION,
    },
  },

  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',

  // AppSync
  aws_appsync_graphqlEndpoint: process.env.API_URL,

  aws_appsync_region: process.env.AWS_REGION,

  graphql_headers: async () => {
    const session = await Auth.currentSession()
    return {
      Authorization: session.getIdToken().getJwtToken(),
    }
  },

  // General
  region: process.env.AWS_REGION,
})

// Logs when the client route changes
export const onRouteUpdate = ({ location, prevLocation }) => {
  const groupStorage = typeof window !== 'undefined' ? localStorage.getItem('selectedGroupRoles') : undefined
  if (groupStorage) {
    const roles = JSON.parse(groupStorage)
    if (roles && roles.length > 0) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'navigation',
          navigation: { current: location, prev: prevLocation },
          roles: roles,
        },
      })
    }
  }
}

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({ element, ...args }) => {
  const props = args.props
  return <Layout layout={(props.pageContext.layout as string) ?? undefined}>{element}</Layout>
}
