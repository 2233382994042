import { type ImgHTMLAttributes } from 'react'
import styled from 'styled-components'

import transparent from '../../assets/images/transparentAvatarPlaceholder.svg'

export interface ImageProps extends ImgHTMLAttributes<HTMLImageElement> {
  fallback?: string
}

const getFallback = (src?: string) => {
  if (src) {
    if (src.includes('.png') || src.includes('.svg')) {
      return transparent
    }
  } else {
    return transparent
  }
}

const Image = styled('img').attrs<ImageProps>((props) => ({
  src: props.src ?? props.fallback,
}))<ImageProps>`
  position: relative;
  object-position: center;
  object-fit: cover;
  background: url(${({ src }) => getFallback(src)}) no-repeat center;
  background-size: cover;
`

export default Image
