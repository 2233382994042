import React, { FunctionComponent } from 'react'
import styled from 'styled-components'

import { BrandColors } from '../../tokens/colors'
import { MediaQueries } from '../../tokens/mediaQueries'
import { rem } from '../../utils/rem'
import Button from '../button/Button'
import Padding from '../padding/Padding'
import ProgressIndicator from '../progress-indicator/ProgressIndicator'
import Title from '../title/Title'

interface Props {
  currentStep: number
  previousOnClick?: () => void
  title: string
  totalSteps: number
}

const FormTitleProgressIndicator: FunctionComponent<React.PropsWithChildren<Props>> = ({
  currentStep,
  totalSteps,
  title,
  previousOnClick,
}: Props) => (
  <Wrapper>
    <StyledProgressIndicator currentStep={currentStep} totalSteps={totalSteps} />
    <Padding vertical={{ desktop: '0px', mobile: rem(16) }} left={{ desktop: rem(16) }}>
      {previousOnClick && <StyledButton onClick={previousOnClick}>Previous step</StyledButton>}
      <Padding top={{ desktop: rem(12), mobile: rem(8) }}>
        <Title size="xSmall">{title}</Title>
      </Padding>
    </Padding>
  </Wrapper>
)

export default FormTitleProgressIndicator

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;

  ${MediaQueries.desktop} {
    flex-direction: row;
  }
`

const StyledProgressIndicator = styled(ProgressIndicator)`
  place-self: flex-start;
`

const StyledButton = styled(Button)`
  color: ${BrandColors.springGreen.toString()};
  padding: 0;
`
