import React, { type FunctionComponent, type SVGAttributes } from 'react'

import { BrandColors } from '../../tokens/colors'

export interface WordmarkProps extends SVGAttributes<SVGSVGElement> {
  color?: string
}

const Wordmark: FunctionComponent<React.PropsWithChildren<WordmarkProps>> = ({
  color = BrandColors.springGreen.toString(),
  ...props
}) => (
  <svg width="303" height="37" viewBox="0 0 303 37" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M40.8529 3.42451C31.7503 3.42451 25.1304 10.6713 25.1304 20.0727C25.1304 29.5654 31.708 36.7208 40.8107 36.7208C49.958 36.7208 56.5333 29.5209 56.5333 20.0727C56.5333 10.7158 49.9556 3.42451 40.8529 3.42451ZM48.6955 20.0702C48.6955 24.9129 44.9666 28.839 40.3672 28.839C35.7677 28.839 32.0389 24.9129 32.0389 20.0702C32.0389 15.2275 35.7677 11.3014 40.3672 11.3014C44.9666 11.3014 48.6955 15.2275 48.6955 20.0702Z"
      fill={color}
    />
    <path
      d="M298.682 0C301.351 0 302.998 1.95439 302.998 4.41282C302.998 6.80701 301.372 8.84788 298.743 8.84788C296.136 8.84788 294.468 6.91573 294.468 4.43506C294.47 1.97663 296.139 0 298.682 0ZM298.725 8.01523C300.893 8.01523 302.123 6.50063 302.123 4.41529C302.123 2.30771 300.872 0.835125 298.682 0.835125C296.596 0.835125 295.345 2.34972 295.345 4.43506C295.348 6.45616 296.599 8.01523 298.725 8.01523ZM298.267 4.9391V6.80454H297.413V2.06311H298.809C299.811 2.06311 300.353 2.61162 300.353 3.49122C300.353 4.10644 300.041 4.54624 299.666 4.72167L300.52 6.80701H299.623L298.936 4.94157H298.267V4.9391ZM298.267 2.87599V4.12868H298.894C299.248 4.12868 299.436 3.86431 299.436 3.49122C299.436 3.11813 299.227 2.87599 298.894 2.87599H298.267Z"
      fill={color}
    />
    <path
      d="M0 20.216C0 10.7652 6.65511 3.5258 16.2036 3.5258C20.0146 3.5258 22.9479 4.67719 25.1374 6.24614L23.0348 13.1619C21.4156 12.0105 19.0947 10.9036 16.4665 10.9036C10.8603 10.9036 7.79559 15.1904 7.79559 20.216C7.79559 25.2415 10.8603 29.6222 16.4665 29.6222C19.0947 29.6222 21.547 28.5153 23.1662 27.3639L25.2688 34.2797C23.0793 35.8461 20.0146 37 16.2036 37C6.30546 37 0 29.7161 0 20.216Z"
      fill={color}
    />
    <path d="M59.4736 36.3798V2.64127H66.9618V28.5944H76.1583V36.3798H59.4736Z" fill={color} />
    <path d="M79.4438 36.3798V2.64127H86.932V28.5944H96.1286V36.3798H79.4438Z" fill={color} />
    <path
      d="M107.892 29.1627L105.703 36.4021H98.1699L108.875 3.79512H116.54L127.245 36.4021H119.712L117.523 29.1627H107.892ZM112.707 12.47L109.819 22.7065H115.599L112.707 12.47Z"
      fill={color}
    />
    <path
      d="M141.437 36.3996H130.138V3.99031H141.085C147.304 3.99031 151.026 7.25916 151.026 12.7937C151.026 15.561 149.798 18.2344 147.698 19.4797C150.721 20.8164 151.99 23.4453 151.99 26.766C151.993 33.3111 147.306 36.3996 141.437 36.3996ZM137.584 10.8566V16.7124H140.78C142.489 16.7124 143.409 15.4671 143.409 13.7153C143.409 12.0105 142.533 10.8566 140.693 10.8566H137.584ZM137.584 23.0302V29.6222H141.219C143.19 29.6222 144.284 28.1472 144.284 26.3015C144.284 24.3669 143.233 23.0277 141.261 23.0277H137.584V23.0302Z"
      fill={color}
    />
    <path
      d="M180.995 36.3996H173.507V23.7665H162.559V36.3996H155.071V4.12621H162.559V16.6679H173.507V4.12621H180.995V36.3996Z"
      fill={color}
    />
    <path
      d="M221.137 23.9987V4.12621H228.625V23.9987C228.625 27.5023 230.638 29.6692 233.968 29.6692C237.34 29.6692 239.222 27.5023 239.222 23.9518V4.12621H246.711V23.9518C246.711 31.5124 242.024 36.953 233.924 36.953C225.692 36.953 221.137 31.6977 221.137 23.9987Z"
      fill={color}
    />
    <path
      d="M270.844 10.995L264.625 13.992C264.013 12.5639 263.093 10.8097 260.772 10.8097C259.327 10.8097 258.364 11.7313 258.364 12.9766C258.364 17.3573 271.151 16.8952 271.151 26.7635C271.151 32.7107 267.122 36.9975 260.422 36.9975C253.547 36.9975 250.789 31.7422 250.001 29.2985L256.264 26.0248C257.097 27.9149 258.761 29.6198 260.861 29.6198C262.656 29.6198 263.576 28.7896 263.576 27.3615C263.576 23.0746 250.876 23.4428 250.876 13.1149C250.876 7.30363 254.861 3.5258 260.948 3.5258C267.516 3.5258 270.055 8.55138 270.844 10.995Z"
      fill={color}
    />
    <path
      d="M275.514 36.3996V4.12621H291.849V11.1803H283.002V16.574H290.535V23.5812H283.002V29.3455H292.198V36.3996H275.514Z"
      fill={color}
    />
    <path
      d="M185.771 3.93349V36.37H216.577V3.93349H185.771ZM209.774 28.7229H193.496V11.583H209.774V28.7229Z"
      fill={color}
    />
  </svg>
)

export default Wordmark
