import { GraphQLResult } from '@aws-amplify/api-graphql'
import { API, graphqlOperation } from 'aws-amplify'

export const graphqlApi = async <T = object>(
  operation: string,
  variables?: object,
  operationKey?: string,
): Promise<GraphQLResult<T>> => {
  try {
    const result = (await API.graphql(graphqlOperation(operation, variables))) as GraphQLResult<T>

    return {
      ...result,
      data: result.data?.[operationKey] || result.data?.[Object.keys(result.data)?.[0]] || null,
    }
  } catch (err) {
    console.warn('graphQL error:', err)
    const { errors, data } = err
    return {
      data: data?.[operationKey] || data?.[Object.keys(data)[0]] || null,
      errors,
    }
  }
}
