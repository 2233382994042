import { ButtonHTMLAttributes, ReactChild } from 'react'
import styled from 'styled-components'

import { BrandColors, SystemColors } from '../../tokens/colors'
import { Opacities } from '../../tokens/opacities'
import BaseButton from '../base-button/BaseButton'

export interface SecondaryIconButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  icon: ReactChild
}
const SecondaryIconButton = styled(BaseButton).attrs<SecondaryIconButtonProps>(({ icon }) => ({
  children: icon,
}))<SecondaryIconButtonProps>`
  padding: 0;
  outline-offset: 2px;
  outline: 1px solid transparent;

  &:hover {
    color: ${BrandColors.white.alpha(Opacities.seventyfive).toString()};
  }

  &:focus {
    color: ${BrandColors.white.alpha(Opacities.seventyfive).toString()};
    outline-color: ${SystemColors.info.toString()};
  }

  &:active {
    color: ${BrandColors.white.alpha(Opacities.fiftyfive).toString()};
  }

  &:disabled {
    opacity: ${Opacities.thirtyfive};
  }
`

export default SecondaryIconButton
