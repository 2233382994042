import React, { FunctionComponent } from 'react'
import styled from 'styled-components'

import { Opacities } from '../../tokens/opacities'
import AbstractMark from '../abstract-mark/AbstractMark'
import Center from '../center/Center'
import Opacity from '../opacity/Opacity'
import Rotate from '../rotate/Rotate'
import SizedBox from '../sized-box/SizedBox'

// We use px instead of rem to avoid wrong center alignment
const size = { desktop: '82px', mobile: '40px' }
const abstractSizeWidth = { desktop: '50px', mobile: '25px' }
const abstractSizeHeight = { desktop: '26px', mobile: '23px' }

const IndeterminatePageProgress: FunctionComponent<React.PropsWithChildren<unknown>> = () => (
  <SizedBox width={size} height={size}>
    <Center>
      <Opacity opacity={Opacities.twenty}>
        <SizedBox width={abstractSizeWidth} height={abstractSizeHeight}>
          <AbstractMark />
        </SizedBox>
      </Opacity>
      <Rotate as={Absolute}>
        <SizedBox width={size} height={size}>
          <svg viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fill="currentColor"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M46.0384 5.81196C41.7206 3.49708 36.8975 2.28571 31.9983 2.28571V0C37.2743 0 42.4685 1.30454 47.1184 3.7975C51.7684 6.29045 55.7297 9.89446 58.6499 14.2887C61.5701 18.6829 63.3584 23.731 63.8558 28.9835C64.3531 34.2361 63.544 39.5301 61.5005 44.3943C59.457 49.2585 56.2424 53.542 52.143 56.8634C48.0437 60.1848 43.1866 62.441 38.0044 63.4313C32.8221 64.4216 27.4754 64.1151 22.4402 62.5392C17.405 60.9633 12.8376 58.1668 9.14453 54.3988L10.7769 52.7989C14.2062 56.2978 18.4474 58.8945 23.1229 60.3578C27.7984 61.8212 32.7632 62.1057 37.5753 61.1862C42.3875 60.2667 46.8976 58.1716 50.7041 55.0874C54.5107 52.0033 57.4956 48.0258 59.3932 43.509C61.2908 38.9922 62.0421 34.0764 61.5803 29.199C61.1184 24.3216 59.4578 19.6341 56.7462 15.5538C54.0346 11.4734 50.3562 8.12685 46.0384 5.81196Z"
            />
          </svg>
        </SizedBox>
      </Rotate>
    </Center>
  </SizedBox>
)

export default IndeterminatePageProgress

const Absolute = styled.div`
  position: absolute;
`
