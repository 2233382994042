import React, { FunctionComponent, ReactNode, useRef } from 'react'
import styled, { css } from 'styled-components'
import { v4 as uuid } from 'uuid'

import { BrandColors, SystemColors } from '../../tokens/colors'
import { MediaQueries } from '../../tokens/mediaQueries'
import { Opacities } from '../../tokens/opacities'
import { rem } from '../../utils/rem'
import Caption from '../caption/Caption'
import Circle from '../circle/Circle'
import Column from '../column/Column'
import Outline from '../outline/Outline'
import Padding from '../padding/Padding'
import { RadioInputProps } from '../radio-input/RadioInput'
import Row from '../row/Row'
import SizedBox from '../sized-box/SizedBox'

export interface BoxedRadioProps extends Omit<RadioInputProps, 'type'> {
  icon?: ReactNode
}

const BoxedRadio: FunctionComponent<React.PropsWithChildren<BoxedRadioProps>> = ({
  error,
  label,
  description,
  disabledDescription,
  hideRadioButton,
  icon,
  disabled,
  ...props
}) => {
  const idRef = useRef(uuid())
  return (
    <>
      <Input type="radio" id={idRef.current} {...props} />
      <Wrapper as="label" htmlFor={idRef.current} error={error} hideRadioButton={hideRadioButton}>
        <Padding padding={`${rem(23)}`} style={{ width: '100%' }}>
          <InnerWrapper mainAxisAlignment="space-between" crossAxisAlignment="baseline">
            {label && (
              <>
                <TextColumn disabled={disabled} mainAxisAlignment="center" crossAxisAlignment="flex-start">
                  {icon && icon}

                  <Label>{label}</Label>
                  {description && (
                    <Description
                      size="small"
                      color={disabled ? undefined : BrandColors.white.alpha(Opacities.fiftyfive).toString()}
                    >
                      {disabled && disabledDescription ? disabledDescription : description}
                    </Description>
                  )}
                </TextColumn>
              </>
            )}
            <Circle ghost size={{ desktop: '24px', mobile: '20px' }} color={BrandColors.white.toString()}>
              <Checkmark size={{ desktop: '12px', mobile: '10px' }} color="transparent" />
            </Circle>
          </InnerWrapper>
        </Padding>
      </Wrapper>
    </>
  )
}

export default BoxedRadio

const Input = styled.input<BoxedRadioProps>`
  position: fixed;
  appearance: none;
  opacity: 0;
`

const Label = styled(Caption)`
  font-size: ${rem(16)};
  line-height: ${rem(20)};
  font-weight: 700;
  margin: ${rem(12)} 0;

  ${MediaQueries.desktop} {
    font-size: ${rem(20)};
    line-height: ${rem(24)};
    margin: ${rem(16)} 0;
  }
`

const Description = styled(Caption)`
  margin-bottom: ${rem(16)};

  ${MediaQueries.desktop} {
    text-align: center;
  }
`

interface TextColumnProps {
  disabled?: boolean
}

const TextColumn = styled(Column)<TextColumnProps>`
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: ${Opacities.thirtyfive};
    `}

  ${MediaQueries.desktop} {
    align-items: center;
    padding-right:;
  }
`

const InnerWrapper = styled(Row)`
  ${MediaQueries.desktop} {
    flex-direction: column;
    align-items: center;
  }
`

const Checkmark = styled(Circle).attrs({ color: 'transparent' })``

interface WrapperProps {
  error?: boolean
  hideRadioButton?: boolean
}

const Wrapper = styled(Row)<WrapperProps>`
  position: relative;
  display: flex;
  border: 1px solid transparent;
  width: 100%;
  background: ${BrandColors.steelGray.toString()};
  cursor: pointer;

  input:not(:disabled) + &:hover {
    border-color: ${BrandColors.white.alpha(Opacities.fiftyfive).toString()};
  }

  input:not(:focus) + & {
    ${Outline} {
      opacity: 0;
    }
  }

  input:not(:checked) + & {
    ${Circle} {
      opacity: ${Opacities.thirtyfive.toString()};
    }
  }

  input:checked + & {
    border-color: rgba(255, 255, 255, 0.35);
    padding: 0;
    border-width: 1px;

    ${Checkmark} {
      background: ${BrandColors.white.toString()};
    }

    :hover {
      border-color: ${BrandColors.white.alpha(Opacities.seventyfive).toString()};
    }
  }

  ${({ error = false }) =>
    error &&
    css`
      border-color: ${SystemColors.danger.toString()}!important;

      ${Label} {
        color: ${BrandColors.white.toString()} !important;
      }
    `}

  input:disabled + & {
    pointer-events: none;
    border-color: ${BrandColors.white.alpha(Opacities.thirtyfive).toString()}!important;

    ${Circle} {
      border-color: ${BrandColors.white.alpha(Opacities.thirtyfive).toString()};
    }

    ${Checkmark} {
      background: ${BrandColors.white.alpha(Opacities.thirtyfive).toString()};
    }
  }

  ${({ hideRadioButton }) =>
    hideRadioButton &&
    `
    ${Circle}, ${Checkmark} {
      background-color: transparent;
      border-color: transparent;
      display: none;
    }`}
`
