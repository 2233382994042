import React, { FunctionComponent } from 'react'
import styled from 'styled-components'

import { BrandColors } from '../../tokens/colors'
import Center from '../center/Center'
import SizedBox, { SizedBoxProps } from '../sized-box/SizedBox'

type BoxShape = 'square' | 'circle' | 'stadium'

export interface PlaceholderProps extends SizedBoxProps {
  shape?: BoxShape
}

/**
 * This component displays a box to represent where other widgets will one day
 * be added.
 *
 * NB: This component is inspired by, but not identical to the Flutter
 * equivalent.
 * @see https://api.flutter.dev/flutter/widgets/Placeholder-class.html
 */
const Placeholder: FunctionComponent<React.PropsWithChildren<PlaceholderProps>> = ({
  shape = 'square',
  width = '100%',
  height = '100%',
  children = <SizedBox width="24px" height="24px" />,
  ...props
}) => (
  <SizedPlaceholder shape={shape} width={width} height={height} {...props}>
    <Center>{children}</Center>
  </SizedPlaceholder>
)

const SizedPlaceholder = styled(SizedBox)<PlaceholderProps>`
  background: ${BrandColors.white.alpha(0.02).toString()};
  border: 2px dashed ${BrandColors.white.alpha(0.1).toString()};
  color: ${BrandColors.white.alpha(0.1).toString()};
  border-radius: ${(props) => {
    switch (props.shape) {
      case 'square':
        return '4px'
      case 'circle':
        return '50%'
      case 'stadium':
        return `min(${props.width}, ${props.height})`
    }
  }};
  overflow: hidden;
`

export default Placeholder
