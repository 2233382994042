import React, { FunctionComponent, useRef } from 'react'
import styled from 'styled-components'
import { v4 as uuid } from 'uuid'

import FormField, { FormFieldProps } from '../form-field/FormField'
import { default as BaseInput, InputProps } from '../input/Input'

export interface TimeFieldProps extends Omit<FormFieldProps, 'htmlFor'> {
  id?: string
  input?: Omit<InputProps, 'disabled' | 'error'>
}

const TimeField: FunctionComponent<React.PropsWithChildren<TimeFieldProps>> = ({ id, input, ...props }) => {
  const idRef = useRef(id ?? uuid())

  return (
    <FormField {...props} htmlFor={idRef.current}>
      <Input
        placeholder="mm:ss"
        {...input}
        type="text"
        id={idRef.current}
        disabled={props.disabled}
        error={!!props.error}
      />
    </FormField>
  )
}

export default TimeField
const Input = styled(BaseInput)`
  position: relative;
  ::-webkit-calendar-picker-indicator {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    color: transparent;
    background: transparent;
  }
`
