import { type GraphQLResult } from '@aws-amplify/api-graphql'

import { graphqlApi } from '../utils/graphql'

export type GroupMemberInvitation = {
  email: string
  expiresAt: string
  groupId: string
  id: string
}

export const inviteGroupMember = /* GraphQL */ `
  mutation InviteGroupMember($groupId: ID!, $input: GroupMemberInvitationInput!) {
    inviteGroupMember(groupId: $groupId, input: $input) {
      id
      groupId
      email
      expiresAt
    }
  }
`

export const acceptGroupMemberInvitation = /* GraphQL */ `
  mutation AcceptGroupMemberInvitation($code: ID!, $email: String!) {
    acceptGroupMemberInvitation(code: $code, email: $email) {
      groupId
      userId
      role
    }
  }
`

export const getGroupMemberInvitations = async (variables: {
  groupId: string
}): Promise<GraphQLResult<GroupMemberInvitation[]>> =>
  graphqlApi<GroupMemberInvitation[]>(
    `
      query GetGroupMemberInvitations($groupId: ID!) {
        getMyGroupMemberInvitations(groupId: $groupId) {
          id
          groupId
          email
          expiresAt
        }
      }
  `,
    variables,
  )
