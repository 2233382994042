import React, { FunctionComponent, useRef } from 'react'
import styled, { css } from 'styled-components'
import { v4 as uuid } from 'uuid'

import { BrandColors, SystemColors } from '../../tokens/colors'
import { MediaQueries } from '../../tokens/mediaQueries'
import { Opacities } from '../../tokens/opacities'
import { Transitions } from '../../tokens/transitions'
import { rem } from '../../utils/rem'
import Caption from '../caption/Caption'
import FileInput, { FileInputHTMLAttributes } from '../file-input/FileInput'
import FormField, { FormFieldProps } from '../form-field/FormField'
import LinearDeterminateProgress from '../linear-determinate-progress/LinearDeterminateProgress'
import Opacity from '../opacity/Opacity'
import Row from '../row/Row'
import SizedBox from '../sized-box/SizedBox'

export interface FileFieldProps extends Omit<FormFieldProps, 'htmlFor'> {
  id?: string
  initialFilename?: string
  input?: FileInputHTMLAttributes & { label?: string }
  progress?: number
  trailing?: JSX.Element
}

const FileField: FunctionComponent<React.PropsWithChildren<FileFieldProps>> = ({
  id,
  initialFilename,
  input,
  trailing,
  progress = 0,
  ...props
}) => {
  const idRef = useRef(id ?? uuid())
  const fileName = input?.files?.[0]?.name || initialFilename
  const visible = progress > 0 && progress < 100

  return (
    <FormField {...props} htmlFor={idRef.current}>
      <Row>
        <FileInput {...input} disabled={!!props.disabled} label={input?.label || 'Choose file'} />
        <SizedBox width={rem(8)} />
        <Content>
          <Border
            crossAxisAlignment="center"
            mainAxisAlignment="space-between"
            disabled={props.disabled}
            error={Boolean(props.error)}
          >
            <Caption noWrap title={fileName}>
              {fileName}
            </Caption>
            {trailing && (
              <>
                <SizedBox width={rem(10)} />
                {trailing}
              </>
            )}
          </Border>
          <DelayedOpacity opacity={visible ? 1 : 0}>
            <ProgressBar progress={progress} />
          </DelayedOpacity>
        </Content>
      </Row>
    </FormField>
  )
}

const Content = styled(Row)`
  position: relative;
  overflow: hidden;
  width: 100%;
`

interface BorderProps {
  disabled?: boolean
  error?: boolean
}

const Border = styled(Row)<BorderProps>`
  border: 1px solid ${BrandColors.white.alpha(Opacities.thirtyfive).toString()};
  width: 100%;
  padding: ${rem(14)} ${rem(12)};
  height: ${rem(48)};
  color: ${BrandColors.white.toString()};

  ${MediaQueries.desktop} {
    height: ${rem(56)};
  }

  ${({ disabled = false }) =>
    disabled &&
    css`
      opacity: ${Opacities.thirtyfive};
    `}

  ${({ error = false }) =>
    error &&
    css`
      border-color: ${SystemColors.danger.toString()};
    `}
`

const DelayedOpacity = styled(Opacity)`
  transition: ${Transitions.micro};
  transition-delay: 0.2s;
`

const ProgressBar = styled(LinearDeterminateProgress)`
  position: absolute;
  left: 0;
  bottom: 0;
`

export default FileField
