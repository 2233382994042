import React from 'react'
import styled, { css } from 'styled-components'

import { BrandColors, SystemColors } from '../../tokens/colors'
import { MediaQueries } from '../../tokens/mediaQueries'
import { Opacities } from '../../tokens/opacities'
import { Transitions } from '../../tokens/transitions'
import { FontFamily } from '../../tokens/typography'
import { rem } from '../../utils/rem'
import Button, { ButtonProps } from '../button/Button'
import SizedBox from '../sized-box/SizedBox'

export type PrimaryButtonSize = 'small' | 'large'

export interface PrimaryButtonProps extends ButtonProps {
  size?: PrimaryButtonSize
}

const PrimaryButton = styled(Button).attrs<PrimaryButtonProps>(({ size = 'large' }) => ({
  separator: <SizedBox width={rem(size === 'small' ? 8 : 10)} />,
}))<PrimaryButtonProps>(
  ({ theme, size }) => css`
    transition: ${Transitions.micro};
    background: ${theme.primary.toString()};
    border: 1px solid ${theme.primary.toString()};
    outline: 1px solid transparent;
    outline-offset: ${rem(2)};
    color: ${theme.primary.isDark() ? BrandColors.white.toString() : BrandColors.blackcurrant.toString()};
    font-family: ${FontFamily.regular};
    font-weight: bold;

    ${() => {
      switch (size) {
        case 'small':
          return css`
            font-size: ${rem(14)};
            line-height: ${rem(16)};
            padding: 0 ${rem(12)};
            height: ${rem(32)};

            ${MediaQueries.desktop} {
              font-size: ${rem(16)};
              line-height: ${rem(20)};
              padding: 0 ${rem(20)};
              height: ${rem(40)};
            }
          `
        case 'large':
        default:
          return css`
            font-size: ${rem(16)};
            line-height: ${rem(20)};
            padding: 0 ${rem(24)};
            height: ${rem(48)};

            ${MediaQueries.desktop} {
              font-size: ${rem(18)};
              padding: 0 ${rem(32)};
              height: ${rem(56)};
            }
          `
      }
    }}

    &:hover,
    &:focus,
    &:active {
      background: ${theme.primary
        .mix(theme.primary.isDark() ? BrandColors.blackcurrant : BrandColors.white, Opacities.fiftyfive)
        .toString()};
      border-color: ${theme.primary
        .mix(theme.primary.isDark() ? BrandColors.blackcurrant : BrandColors.white, Opacities.fiftyfive)
        .toString()};
    }

    &:focus {
      outline-color: ${SystemColors.info.toString()};
    }

    &:disabled {
      background: ${theme.primary
        .mix(theme.primary.isDark() ? BrandColors.white : BrandColors.blackcurrant, 0.5)
        .toString()};
      border-color: ${theme.primary
        .mix(theme.primary.isDark() ? BrandColors.white : BrandColors.blackcurrant, 0.5)
        .toString()};
      color: ${BrandColors.blackcurrant.alpha(Opacities.thirtyfive).toString()};
    }
  `,
)

export default PrimaryButton
