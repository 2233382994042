import React, { type ButtonHTMLAttributes } from 'react'
import styled from 'styled-components'

import { SystemColors } from '../../tokens/colors'
import { Opacities } from '../../tokens/opacities'
import { rem } from '../../utils/rem'
import BaseButton from '../base-button/BaseButton'
import Icon from '../icon/Icon'

export type InternalIconButtonProps = ButtonHTMLAttributes<HTMLButtonElement>

const Outline = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  outline: 1px solid transparent;
  outline-offset: ${rem(2)};
  width: 100%;
  height: 100%;
`

const InternalIconButton = styled(Icon).attrs<InternalIconButtonProps>({
  as: BaseButton,
  children: <Outline />,
})<InternalIconButtonProps>`
  position: relative;
  padding: 0;
  outline: none;
  width: ${rem(24)};
  height: ${rem(24)};

  &:hover {
    opacity: ${Opacities.seventyfive};
  }

  &:focus {
    opacity: ${Opacities.seventyfive};

    ${Outline} {
      outline-color: ${SystemColors.info.toString()};
    }
  }

  &:active {
    opacity: ${Opacities.fiftyfive};
  }

  &:disabled {
    opacity: ${Opacities.thirtyfive};
  }
`

export default InternalIconButton
