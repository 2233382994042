import React, { type FunctionComponent } from 'react'
import styled from 'styled-components'

import { Icons } from '../../tokens/icons'
import { Transitions } from '../../tokens/transitions'
import { rem } from '../../utils/rem'
import Column from '../column/Column'
import Icon from '../icon/Icon'
import RangeInput, { type RangeInputProps } from '../range-input/RangeInput'
import Row from '../row/Row'
import SizedBox from '../sized-box/SizedBox'

export interface VolumeBarProps {
  input: RangeInputProps
}

const getIcon = (volume: number) => {
  if (volume === 0) {
    return Icons.volumeNone
  } else if (volume <= 0.5) {
    return Icons.volumeLow
  }
  return Icons.volumeHigh
}

const VolumeBar: FunctionComponent<React.PropsWithChildren<VolumeBarProps>> = ({ input }) => {
  const { value: volume } = input
  return (
    <VolumeBarContainer>
      <Icon icon={getIcon(volume)} />
      <SizedBox width={rem(16)} />
      <RangeContainer>
        <RangeInput {...input} min={0} max={1} step={0.01} />
      </RangeContainer>
    </VolumeBarContainer>
  )
}

export default VolumeBar

const RangeContainer = styled(Column)`
  display: flex;
  flex: 0 0 auto;
  width: 0px;
  transition: width ${Transitions.micro};
`

const VolumeBarContainer = styled(Row)`
  display: flex;
  flex: 0 0 0px;

  &:hover {
    ${RangeContainer} {
      width: ${rem(98)};
    }
  }
`
