import React, { ReactNode } from 'react'
import styled, { css } from 'styled-components'

import { Transitions } from '../../tokens/transitions'
import AspectRatio from '../aspect-ratio/AspectRatio'
import Center from '../center/Center'
import SizedBox, { ResponsiveSize } from '../sized-box/SizedBox'

export interface CircleProps {
  borderWidth?: number
  children?: ReactNode
  color?: string
  ghost?: boolean
  size?: string | number | ResponsiveSize
}

const Circle = styled(SizedBox).attrs<CircleProps>(({ size, children }) => ({
  children: (
    <AspectRatio aspectRatio={1}>
      <Center>{children}</Center>
    </AspectRatio>
  ),
  height: size,
  width: size,
}))<CircleProps>(
  ({ color, ghost, borderWidth = 1 }) => css`
    background-color: ${!ghost && color};
    border-radius: 50%;
    transition: ${Transitions.micro};
    transition-property: background-color, border;

    ${ghost &&
    css`
      border: ${borderWidth}px solid ${color};
    `}
  `,
)

export default Circle
