import styled from 'styled-components'

import Column, { ColumnProps } from '../column/Column'

export type CenterProps = ColumnProps

/**
 * This component fills any available space and places its children in the
 * center.
 *
 * NB: This component is inspired by, but not identical to the Flutter
 * equivalent.
 * @see https://api.flutter.dev/flutter/widgets/Center-class.html
 */
const Center = styled(Column).attrs<CenterProps>(({ crossAxisAlignment, mainAxisAlignment }) => ({
  crossAxisAlignment: crossAxisAlignment ?? 'center',
  mainAxisAlignment: mainAxisAlignment ?? 'center',
}))`
  flex-grow: 1;
  width: 100%;
  height: 100%;
`

export default Center
