import React, { type FunctionComponent, type MouseEvent, type ReactChild, useState } from 'react'
import styled from 'styled-components'

import { SystemColors } from '../../tokens/colors'
import { Icons } from '../../tokens/icons'
import { rem } from '../../utils/rem'
import Dialog, { type DialogProps } from '../dialog/Dialog'
import InternalIconButton from '../internal-icon-button/InternalIconButton'
import TextButton from '../text-button/TextButton'

export interface InfoDialogProps extends Omit<DialogProps, 'visible'> {
  children?: ReactChild
}

const InfoDialog: FunctionComponent<React.PropsWithChildren<InfoDialogProps>> = (props) => {
  const [isDialogVisible, setIsDialogVisible] = useState(false)

  return (
    <>
      <InfoIconButton
        size={{ desktop: rem(20), mobile: rem(16) }}
        icon={Icons.info}
        type="button"
        data-gtm-name="cta-info-dialog"
        data-gtm-value={props?.title}
        onClick={(event: MouseEvent<HTMLButtonElement>) => {
          event.stopPropagation()
          setIsDialogVisible(true)
        }}
      />
      <Dialog
        size="small"
        visible={isDialogVisible}
        onClose={() => {
          setIsDialogVisible(false)
        }}
        actions={<TextButton onClick={() => setIsDialogVisible(false)}>Close</TextButton>}
        {...props}
      />
    </>
  )
}

export default InfoDialog

const InfoIconButton = styled(InternalIconButton)`
  color: ${SystemColors.info.toString()};
  pointer-events: auto;
  flex-shrink: 0;
`
