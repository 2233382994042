import {
  Grid as BaseGrid,
  GridItem as BaseGridItem,
  type GridItemProps as BaseGridItemProps,
  type GridProps as BaseGridProps,
  MediaQueries,
  rem,
} from 'ledo-react'
import React, { type FC, type HTMLProps, type PropsWithChildren } from 'react'
import styled, { css } from 'styled-components'

export interface GridProps extends BaseGridProps {
  columns?: number
}

const Grid: FC<PropsWithChildren<GridProps> & Pick<HTMLProps<HTMLDivElement>, 'style'>> = (props) => (
  <BaseGrid gutter={{ desktop: `${rem(12)} ${rem(32)}`, mobile: `${rem(12)} ${rem(16)}` }} {...props} />
)

export default Grid

interface GridColumn {
  end?: number
  span?: number
  start?: number
}

export interface GridItemProps extends Omit<BaseGridItemProps, 'span'> {
  desktop?: number | GridColumn
  mobile: number | GridColumn
}

export const GridItem = styled(BaseGridItem).attrs<GridItemProps>((props) => ({
  span: typeof props.mobile === 'number' ? props.mobile : undefined,
}))<GridItemProps>(
  ({ mobile, desktop }) => css`
    width: 100%;

    ${typeof mobile === 'number' &&
    css`
      grid-column-start: span ${mobile};
    `}

    ${typeof mobile !== 'number' &&
    css`
      grid-column-start: ${mobile.start};
      grid-column-end: ${mobile.end};
    `}

     ${desktop &&
    css`
      ${MediaQueries.desktop} {
        ${typeof desktop === 'number' &&
        css`
          grid-column-start: span ${desktop};
        `}

        ${typeof desktop !== 'number' &&
        css`
          grid-column-start: ${desktop.start};
          grid-column-end: ${desktop.end};
        `}
      }
    `}
  `,
)
