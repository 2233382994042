import { BrandColors } from '../tokens/colors'
import { type LedoTheme } from './LedoTheme'

const greenTheme: LedoTheme = {
  background: BrandColors.springGreen,
  foreground: BrandColors.blackcurrant,
  primary: BrandColors.darkBlue,
  secondary: BrandColors.darkBlue,
}

export default greenTheme
