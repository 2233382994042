import { type MutableRefObject, useEffect, useRef, useState } from 'react'
import ResizeObserver from 'resize-observer-polyfill'

interface Bounds {
  height: number
  left: number
  top: number
  width: number
}

export default function useMeasure<T extends HTMLElement>(): [MutableRefObject<T | null>, Bounds] {
  const ref = useRef<T | null>(null)

  const [bounds, setBounds] = useState<Bounds>({
    height: 0,
    left: 0,
    top: 0,
    width: 0,
  })

  // @TODO can't we just use useMemo here?
  const [observer] = useState(
    // @TODO when we update TypeScript the line below throws an error
    () => new ResizeObserver(([entry]) => setBounds(entry.contentRect)),
  )

  useEffect(() => {
    if (ref.current) {
      observer.observe(ref.current)
    }

    return () => {
      observer.disconnect()
    }
  }, [observer])

  return [ref, bounds]
}
