import { type ErrorMessageFn } from '@/utils/validation'

export function executeValidations<T>(
  value: T,
  validationsToRun: ((value: T) => ErrorMessageFn)[],
  args: { valueIfValidationFailed: boolean },
): boolean | undefined

export function executeValidations<T>(
  value: T,
  validationsToRun: ((value: T) => ErrorMessageFn)[],
  args: { params: Parameters<ErrorMessageFn> },
): string | boolean | undefined

export function executeValidations<T>(
  value: T,
  validationsToRun: ((value: T) => ErrorMessageFn)[],
  args: { valueIfValidationFailed: boolean } | { params: Parameters<ErrorMessageFn> },
): string | boolean | undefined {
  let errorFn: ErrorMessageFn | boolean
  for (const validation of validationsToRun) {
    errorFn = validation(value)
    if (errorFn) {
      break
    }
  }

  const hasError = !!errorFn
  if (!hasError) {
    return undefined
  }

  if (!('params' in args)) {
    return args.valueIfValidationFailed
  }

  if (typeof errorFn === 'function') {
    return errorFn(args.params[0], args.params[1])
  }

  // No error
  return undefined
}
