import React, { FunctionComponent, ReactNode, useRef } from 'react'
import styled, { css } from 'styled-components'
import { v4 as uuid } from 'uuid'

import { BrandColors, SystemColors } from '../../tokens/colors'
import { Opacities } from '../../tokens/opacities'
import { rem } from '../../utils/rem'
import Caption from '../caption/Caption'
import { CheckboxInputProps } from '../checkbox-input/CheckboxInput'
import Column from '../column/Column'
import Flex from '../flex/Flex'
import Padding from '../padding/Padding'
import Row from '../row/Row'
import SizedBox from '../sized-box/SizedBox'
import Square from '../square/Square'
import Title from '../title/Title'

export interface BoxedCheckboxInputProps extends CheckboxInputProps {
  borderless?: boolean
  description?: React.ReactChild
  direction?: 'horizontal' | 'vertical'
  hideCheckbox?: boolean
  icon?: ReactNode
  label?: React.ReactChild
  large?: boolean
}

const ContentWrapper: FunctionComponent<
  React.PropsWithChildren<{
    children: ReactNode
    direction?: 'horizontal' | 'vertical'
  }>
> = ({ children, direction }) =>
  direction === 'vertical' ? <Row crossAxisAlignment="flex-start">{children}</Row> : <Column>{children}</Column>

const BoxedCheckboxInput: FunctionComponent<React.PropsWithChildren<BoxedCheckboxInputProps>> = ({
  error,
  label,
  description,
  large = false,
  borderless = false,
  direction = 'vertical',
  hideCheckbox,
  icon,
  ...props
}) => {
  const idRef = useRef(uuid())

  return (
    <>
      <Input type="checkbox" id={idRef.current} {...props} />

      <Wrapper borderless={borderless} as="label" htmlFor={idRef.current} error={error} hideCheckbox={hideCheckbox}>
        <Padding padding={`${rem(15)} ${rem(15)}`}>
          <ContentWrapper direction={direction}>
            <Square ghost size={{ desktop: '24px', mobile: '20px' }} color={BrandColors.white.toString()}>
              <div style={{ color: 'transparent' }}>
                <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2 6L5 9L12 2" stroke="currentColor" strokeWidth="2" strokeLinecap="square" />
                </svg>
              </div>
            </Square>
            {icon && direction === 'horizontal' && (
              <Flex crossAxisAlignment="center" mainAxisAlignment="center">
                <Padding vertical={rem(20)}>{icon}</Padding>
              </Flex>
            )}

            {label && (
              <>
                {!hideCheckbox && (
                  <>
                    {!icon && direction === 'vertical' && <SizedBox height={rem(32)} width={rem(12)} />}
                    {!icon && direction === 'horizontal' && <SizedBox height={rem(12)} width={rem(32)} />}
                  </>
                )}

                <TextColumn disabled={props.disabled}>
                  {large ? (
                    <Title size="xxSmall">{label}</Title>
                  ) : (
                    <Label>
                      <strong>{label}</strong>
                    </Label>
                  )}
                  {large && <SizedBox height={rem(8)} />}
                  {description && (
                    <Caption
                      size="small"
                      color={props.disabled ? undefined : BrandColors.white.alpha(Opacities.seventyfive).toString()}
                    >
                      {description}
                    </Caption>
                  )}
                </TextColumn>
              </>
            )}
          </ContentWrapper>
        </Padding>
      </Wrapper>
    </>
  )
}

export default BoxedCheckboxInput

const Input = styled.input<BoxedCheckboxInputProps>`
  position: absolute;
  appearance: none;
  opacity: 0;
`

const Label = styled(Caption)``

interface TextColumnProps {
  disabled?: boolean
}

const TextColumn = styled(Column)<TextColumnProps>`
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: ${Opacities.thirtyfive};
    `}
`

interface WrapperProps {
  borderless?: boolean
  error?: boolean
  hideCheckbox?: boolean
}

const Wrapper = styled(Row)<WrapperProps>`
  position: relative;
  display: flex;
  border: 1px solid ${BrandColors.white.alpha(Opacities.thirtyfive).toString()};
  border: ${({ borderless }) =>
    borderless ? `1px solid transparent` : `1px solid ${BrandColors.white.alpha(Opacities.thirtyfive).toString()}`};
  padding: 2px;
  width: 100%;
  background: ${BrandColors.haiti.toString()};

  input:not(:disabled) + &:hover {
    border-color: ${BrandColors.white.alpha(Opacities.fiftyfive).toString()};
  }

  input:checked + & {
    border-color: ${BrandColors.white.toString()};
    border-width: 2px;
    padding: 1px;
    ${Square} {
      background: ${BrandColors.white.toString()};
      & svg {
        color: ${BrandColors.blackcurrant.toString()};
      }
    }

    :hover {
      border-color: ${BrandColors.white.alpha(Opacities.seventyfive).toString()};
    }
  }

  input:not(:checked) + & {
    ${Square} {
      opacity: ${Opacities.thirtyfive.toString()};
    }
  }

  ${({ error = false }) =>
    error &&
    css`
      border-color: ${SystemColors.danger.toString()}!important;

      ${Label} {
        color: ${BrandColors.white.toString()} !important;
      }
    `}

  input:disabled + & {
    pointer-events: none;
    border-color: ${BrandColors.white.alpha(Opacities.thirtyfive).toString()}!important;
    ${Square} {
      background: ${BrandColors.white.alpha(Opacities.thirtyfive).toString()};
      border-color: ${BrandColors.white.alpha(Opacities.thirtyfive).toString()};
    }
  }

  ${({ hideCheckbox }) =>
    hideCheckbox &&
    `
  ${Square} {
      background-color: transparent;
      border-color: transparent;
      display: none;
    }`}
`
