import { type ReactChild } from 'react'
import styled from 'styled-components'

import Flexible, { type FlexibleProps } from '../flexible/Flexible'

export interface ExpandedProps extends FlexibleProps {
  children?: ReactChild
}

/**
 * This component can grow and shrink inside a FlexBox, Row or Column just like
 * a Flexible. Unlike a Flexible, however, this component also forces its child
 * to fill the available space.
 *
 * NB: This component is inspired by, but not identical to the Flutter
 * equivalent.
 * @see https://api.flutter.dev/flutter/widgets/Expanded-class.html
 */
const Expanded = styled(Flexible)<ExpandedProps>`
  width: 100%;
  height: 100%;

  & > *:not(button) {
    height: 100%;
  }

  & > * {
    width: 100%;
  }
`

export default Expanded
