import React, { type FunctionComponent, type ReactElement, useState } from 'react'
import { animated, useTransition, type UseTransitionProps } from 'react-spring'
import styled from 'styled-components'

import { MediaQueries } from '../../tokens/mediaQueries'
import { rem } from '../../utils/rem'
import Column, { type ColumnProps } from '../column/Column'
import Portal from '../portal/Portal'

type SnackbarRefs = WeakMap<ReactElement, HTMLElement | null>

export interface SnackbarContainerProps extends ColumnProps {
  children: ReactElement[]
  portal?: string
}

const SnackbarContainer: FunctionComponent<React.PropsWithChildren<SnackbarContainerProps>> = ({
  children,
  ...props
}) => {
  const [refs] = useState<SnackbarRefs>(() => new WeakMap())

  const transitions = useTransition(
    children as ReactElement[],
    (item: ReactElement) => item.key || '',
    defaultTransition(refs),
  )

  return (
    <BaseSnackbarContainer {...props}>
      {transitions.map(({ item, props, key }) => (
        <animated.div key={key} style={props} ref={(ref) => refs.set(item, ref)}>
          {item}
        </animated.div>
      ))}
    </BaseSnackbarContainer>
  )
}

export default SnackbarContainer

const defaultTransition = (
  refs: SnackbarRefs,
): UseTransitionProps<
  ReactElement,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any
> => ({
  enter: (item) => async (next: (props: { height: number | undefined; transform: string }) => void) =>
    await next({
      height: refs.get(item)?.offsetHeight,
      transform: 'translate3d(0, 0, 0)',
    }),
  from: {
    opacity: 1,
    transform: 'translate3d(100%, 0, 0)',
  },
  leave: { height: 0, opacity: 0 },
})

const BaseSnackbarContainer: FunctionComponent<React.PropsWithChildren<SnackbarContainerProps>> = ({
  portal,
  ...props
}) => (
  <Portal portal={portal}>
    <SnackbarColumn {...props} />
  </Portal>
)

const SnackbarColumn = styled(Column).attrs({
  crossAxisAlignment: 'flex-end',
  mainAxisAlignment: 'flex-end',
})<SnackbarContainerProps>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  padding: ${rem(32)} ${rem(10)};
  z-index: 2;

  ${MediaQueries.desktop} {
    padding: ${rem(64)};
  }

  > * {
    pointer-events: initial;
    max-width: 100%;
  }
`
