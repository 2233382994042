import React, { FunctionComponent } from 'react'
import styled from 'styled-components'

import { MediaQueries } from '../../tokens/mediaQueries'
import { rem } from '../../utils/rem'
import BoxedRadioInput, { BoxedRadioInputProps } from '../boxed-radio-input/BoxedRadioInput'
import FormField, { FormFieldProps } from '../form-field/FormField'
import Grid, { GridItem } from '../grid/Grid'

export interface BoxedRadioFieldProps extends Omit<FormFieldProps, 'htmlFor'> {
  columns?: number
  inputs: BoxedRadioInputProps[]
}

const BoxedRadioField: FunctionComponent<React.PropsWithChildren<BoxedRadioFieldProps>> = ({
  inputs,
  columns = 1,
  ...props
}) => (
  <FormField {...props}>
    <InputGrid>
      {inputs.map((input, index) => (
        <GridItem span={12 / columns} key={index} className={'grid-item'}>
          <BoxedRadioInput {...input} disabled={props.disabled || input.disabled} error={!!props.error} />
        </GridItem>
      ))}
    </InputGrid>
  </FormField>
)

export default BoxedRadioField

const InputGrid = styled(Grid)`
  column-gap: ${rem(8)};
  row-gap: ${rem(8)};

  ${MediaQueries.desktop} {
    column-gap: ${rem(16)};
    row-gap: ${rem(12)};
  }

  & > * {
    display: flex;
  }
`
