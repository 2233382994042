import Color from 'color'
import React, { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'

import { MediaQueries } from '../../tokens/mediaQueries'
import { ResponsiveSize } from '../icon/Icon'
import Rotate from '../rotate/Rotate'

export interface IndeterminateProgressProps {
  color?: Color
  size?: string | number | ResponsiveSize
}

const IndeterminateProgress: FunctionComponent<React.PropsWithChildren<IndeterminateProgressProps>> = ({
  size = 24,
  color,
  ...props
}) => (
  // Don't remove this span, it will break the loader!
  <span>
    <Rotate {...props}>
      <SvgWrapper size={size}>
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fill={color ? color.toString() : 'currentColor'}
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.8958 5.01272C14.7047 4.34861 13.3636 4 11.9999 4V2C13.7046 2 15.3809 2.43576 16.8698 3.2659C18.3586 4.09604 19.6106 5.29299 20.5067 6.74309C21.4028 8.1932 21.9133 9.8483 21.9898 11.5512C22.0663 13.2542 21.7063 14.9484 20.9438 16.473C20.1813 17.9976 19.0417 19.302 17.6333 20.2623C16.2249 21.2226 14.5943 21.8069 12.8966 21.9597C11.1988 22.1126 9.4901 21.8289 7.93281 21.1356C6.37552 20.4423 5.02131 19.3624 3.99878 17.9985L5.59901 16.7988C6.41703 17.8899 7.5004 18.7538 8.74623 19.3085C9.99207 19.8631 11.359 20.0901 12.7172 19.9678C14.0755 19.8455 15.3799 19.3781 16.5066 18.6098C17.6334 17.8416 18.545 16.7981 19.155 15.5784C19.765 14.3587 20.0531 13.0033 19.9919 11.641C19.9307 10.2786 19.5222 8.95456 18.8053 7.79448C18.0884 6.63439 17.0869 5.67683 15.8958 5.01272Z"
          />
        </svg>
      </SvgWrapper>
    </Rotate>
  </span>
)

export default IndeterminateProgress

interface LoaderWrapperProps {
  size: string | number | ResponsiveSize
}

const SvgWrapper = styled.div<LoaderWrapperProps>`
  ${({ size }) => css`
    ${typeof size === 'string' &&
    css`
      width: ${size};
      height: ${size};
    `}

    ${typeof size === 'number' &&
    css`
      width: ${size}px;
      height: ${size}px;
    `}

    ${typeof size === 'object' &&
    css`
      width: ${size.mobile};
      height: ${size.mobile};

      ${MediaQueries.desktop} {
        width: ${size.desktop};
        height: ${size.desktop};
      }
    `}
  `}
`
