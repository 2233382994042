import React, { type FunctionComponent, useCallback, useEffect, useRef } from 'react'

export interface AbsorbScrollProps {
  absorbing?: boolean
}

const AbsorbScroll: FunctionComponent<React.PropsWithChildren<AbsorbScrollProps>> = ({
  absorbing = true,
  ...props
}) => {
  const wrapper = useRef<HTMLDivElement | null>(null)

  const absorbScroll = useCallback((event: MouseEvent | TouchEvent) => {
    event.preventDefault()
  }, [])

  useEffect(() => {
    if (absorbing && wrapper.current) {
      const { current } = wrapper

      current.addEventListener('wheel', absorbScroll)
      current.addEventListener('touchmove', absorbScroll)

      return () => {
        current.removeEventListener('wheel', absorbScroll)
        current.removeEventListener('touchmove', absorbScroll)
      }
    }
  }, [absorbScroll, wrapper, absorbing])

  return <div ref={wrapper}>{props.children}</div>
}

export default AbsorbScroll
