import React, { FunctionComponent, SVGAttributes } from 'react'

import { BrandColors } from '../../tokens/colors'

export interface AbstractMarkProps extends SVGAttributes<SVGSVGElement> {
  colored?: boolean
}

const AbstractMark: FunctionComponent<React.PropsWithChildren<AbstractMarkProps>> = ({ colored, ...props }) => (
  <svg viewBox="0 0 61 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M30.6358 0.41278V12.6281C29.236 5.31633 23.3127 0 15.4982 0C6.52547 0 0 6.9641 0 15.9998C0 25.124 6.48266 31.9995 15.4554 31.9995C23.3074 31.9995 29.2355 26.7498 30.6358 19.3968V31.5867H61V0.41278H30.6358Z"
      fill={colored ? BrandColors.springGreen.toString() : 'white'}
    />
  </svg>
)

AbstractMark.displayName = 'AbstractMark'

export default AbstractMark
