import React, { FunctionComponent, ReactChild, useRef } from 'react'
import styled, { css } from 'styled-components'
import { v4 as uuid } from 'uuid'

import { BrandColors, SystemColors } from '../../tokens/colors'
import { MediaQueries } from '../../tokens/mediaQueries'
import { Opacities } from '../../tokens/opacities'
import { Transitions } from '../../tokens/transitions'
import { rem } from '../../utils/rem'
import Caption from '../caption/Caption'
import Column from '../column/Column'
import InfoDialog, { InfoDialogProps } from '../info-dialog/InfoDialog'
import { InputProps } from '../input/Input'
import Row from '../row/Row'
import SizedBox from '../sized-box/SizedBox'

export interface RadioInputProps extends Omit<InputProps, 'type'> {
  checked?: boolean
  description?: ReactChild
  disabledDescription?: ReactChild
  error?: boolean
  hideRadioButton?: boolean
  infoDialog?: InfoDialogProps
  label?: ReactChild
  value: string
}

const RadioInput: FunctionComponent<React.PropsWithChildren<RadioInputProps>> = ({
  id,
  error,
  label,
  description,
  infoDialog,
  ...props
}) => {
  const idRef = useRef(id || uuid())

  return (
    <Row crossAxisAlignment="center">
      <Input type="radio" id={idRef.current} {...props} error={error} />
      {label && (
        <>
          <SizedBox width={rem(12)} />
          <TextColumn disabled={props.disabled}>
            <Label as="label" htmlFor={idRef.current} error={error}>
              {label}
            </Label>
            {description && (
              <Caption
                size="small"
                color={props.disabled ? undefined : BrandColors.white.alpha(Opacities.fiftyfive).toString()}
              >
                {description}
              </Caption>
            )}
          </TextColumn>
          {infoDialog && (
            <>
              <SizedBox width={rem(8)} />
              <InfoDialog {...infoDialog} />
            </>
          )}
        </>
      )}
    </Row>
  )
}

export default RadioInput

interface LabelProps {
  error?: boolean
}

const Label = styled(Caption)<LabelProps>`
  ${({ error = false }) =>
    error &&
    css`
      color: ${BrandColors.white.toString()} !important;
    `}
`

const Input = styled.input<Pick<RadioInputProps, 'error'>>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  appearance: none;
  width: 20px;
  height: 20px;
  border: 1px solid ${BrandColors.white.alpha(Opacities.thirtyfive).toString()};
  border-radius: 50%;
  outline: none;
  transition: ${Transitions.micro};
  flex-shrink: 0;

  ${MediaQueries.desktop} {
    width: 24px;
    height: 24px;
  }

  // Outline doesn't support rounded corners
  :focus:before {
    content: '';
    position: absolute;
    top: -3px;
    left: -3px;
    width: calc(100% + 3px * 2);
    height: calc(100% + 3px * 2);
    border: 1px solid ${SystemColors.info.alpha(Opacities.fiftyfive).toString()};
    border-radius: 50%;
  }

  &::after {
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: transparent;
    transition: ${Transitions.micro};

    ${MediaQueries.desktop} {
      width: 12px;
      height: 12px;
    }
  }

  :checked {
    :after {
      background: ${BrandColors.white.toString()};
    }
  }

  :hover:not(:disabled) {
    background-color: ${BrandColors.white.alpha(Opacities.eight).toString()};

    :checked:after {
      background: ${BrandColors.white.alpha(Opacities.seventyfive).toString()};
    }
  }

  :disabled {
    pointer-events: none;
    border-color: ${BrandColors.white.alpha(Opacities.thirtyfive).toString()};

    :checked:after {
      background: ${BrandColors.white.alpha(Opacities.thirtyfive).toString()};
    }
  }

  ${({ error }) =>
    error &&
    css`
      border-color: ${SystemColors.danger.toString()};

      :checked:after {
        background: ${SystemColors.danger.toString()}!important;
      }
    `}
`

interface TextColumnProps {
  disabled?: boolean
}

const TextColumn = styled(Column)<TextColumnProps>`
  ${({ disabled = false }) =>
    disabled &&
    css`
      opacity: ${Opacities.thirtyfive};
    `}
`
