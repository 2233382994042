import React, { FunctionComponent, useCallback, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { v4 as uuid } from 'uuid'

import { Icons } from '../../tokens/icons'
import { Opacities } from '../../tokens/opacities'
import { rem } from '../../utils/rem'
import FormField, { FormFieldProps } from '../form-field/FormField'
import IconButton from '../icon-button/IconButton'
import Input, { InputProps } from '../input/Input'

export interface PasswordFieldProps extends Omit<FormFieldProps, 'htmlFor'> {
  hasPasswordVisibilityToggle?: boolean
  id?: string
  input?: Omit<InputProps, 'disabled' | 'error'>
  light?: boolean
  placeholder?: string
}

const PasswordField: FunctionComponent<React.PropsWithChildren<PasswordFieldProps>> = ({
  id,
  input,
  hasPasswordVisibilityToggle = false,
  light = false,
  placeholder,
  ...props
}) => {
  const idRef = useRef(id ?? uuid())
  const [passwordIsVisible, setPasswordIsVisible] = useState(false)

  const togglePasswordIsVisible = useCallback(() => {
    setPasswordIsVisible((prevPasswordIsVisible) => !prevPasswordIsVisible)
  }, [])

  return (
    <FormField {...props} htmlFor={idRef.current}>
      <PasswordInputWrapper>
        <PasswordInput
          {...input}
          light={light}
          type={passwordIsVisible ? 'text' : 'password'}
          id={idRef.current}
          disabled={props.disabled}
          error={!!props.error}
          placeholder={placeholder}
        />
        {hasPasswordVisibilityToggle && (
          <PasswordVisibilityToggle
            disabled={props.disabled}
            icon={passwordIsVisible ? Icons.show : Icons.hide}
            onClick={togglePasswordIsVisible}
            type="button"
          />
        )}
      </PasswordInputWrapper>
    </FormField>
  )
}

export default PasswordField

const PasswordInputWrapper = styled.div`
  position: relative;
`

const PasswordInput = styled(Input)`
  letter-spacing: ${rem(0.2)};
`

interface PasswordVisibilityToggleProps {
  disabled?: boolean
}

const PasswordVisibilityToggle = styled(IconButton)<PasswordVisibilityToggleProps>`
  position: absolute;
  right: ${rem(8)};
  top: ${rem(8)};

  ${({ disabled = false }) =>
    disabled &&
    css`
      opacity: ${Opacities.thirtyfive};
    `}
`
