import { BigTitle, Column, PrimaryButton, rem, Row, SecondaryButton, SizedBox, Title } from 'ledo-react'
import React, { type FC } from 'react'

import { routes } from '../../utils/routes'
import CenteredPageTemplate from '../centered-page-template/CenteredPageTemplate'

const ErrorMessage: FC<{ error: Error }> = ({ error }) => (
  <CenteredPageTemplate>
    <Column crossAxisAlignment="center">
      <BigTitle size="xSmall">Oops</BigTitle>
      <SizedBox height={rem(24)} />
      <Title size="xxSmall" style={{ textAlign: 'center' }}>
        Something went wrong, please try again later.
      </Title>
      <SizedBox height={rem(40)} />
      <Row>
        <PrimaryButton
          onClick={() => {
            window.location.href = routes.home.url
          }}
        >
          Take me home
        </PrimaryButton>
        <SizedBox width={rem(24)} />
        <SecondaryButton
          onClick={() => {
            window.location.reload()
          }}
        >
          Try again
        </SecondaryButton>
      </Row>
    </Column>
  </CenteredPageTemplate>
)

export default ErrorMessage
