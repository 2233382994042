import { NavigateOptions } from '@gatsbyjs/reach-router'
import { navigate } from 'gatsby'
import { Anchor, AnchorProps } from 'ledo-react'
import React, { FunctionComponent, MouseEvent, useCallback } from 'react'

interface InternalLinkProps extends Omit<AnchorProps, 'href' | 'onClick'> {
  options?: NavigateOptions<Record<string, unknown>>
  to: string
}

const InternalLink: FunctionComponent<React.PropsWithChildren<InternalLinkProps>> = ({ to, options, ...props }) => {
  const onClick = useCallback(
    (event: MouseEvent) => {
      event.preventDefault()

      void navigate(to, options)
    },
    [to, options],
  )

  return <Anchor {...props} href={to} onClick={onClick} />
}

export default InternalLink
