import { BrandColors } from '../tokens/colors'
import { LedoTheme } from './LedoTheme'

const blackcurrantTheme: LedoTheme = {
  background: BrandColors.blackcurrant,
  foreground: BrandColors.white,
  primary: BrandColors.springGreen,
  secondary: BrandColors.tickleMePink,
}

export default blackcurrantTheme
