import React, { FunctionComponent, PropsWithChildren } from 'react'
import styled, { css } from 'styled-components'

import { SnackbarComponentProps } from '../../providers/SnackbarProvider'
import { BrandColors, SystemColors } from '../../tokens/colors'
import { MediaQueries } from '../../tokens/mediaQueries'
import { Opacities } from '../../tokens/opacities'
import { rem } from '../../utils/rem'
import Caption from '../caption/Caption'
import Row, { RowProps } from '../row/Row'

export interface SnackbarProps extends SnackbarComponentProps, RowProps {
  variant?: 'success' | 'info' | 'warning' | 'danger'
}

const Snackbar: FunctionComponent<React.PropsWithChildren<PropsWithChildren<SnackbarProps>>> = ({
  children,
  ...props
}) => (
  <StyledSnackbar
    {...props}
    data-gtm-name={`portal-snackbar-${props.variant || 'default'}`}
    data-gtm-value={props.variant || 'default'}
  >
    <Caption size="small">{children}</Caption>
  </StyledSnackbar>
)

export default Snackbar

const StyledSnackbar = styled(Row).attrs<SnackbarProps>((props) => ({
  crossAxisAlignment: 'center',
  onMouseEnter: () => {
    props.timer?.pause()
  },
  onMouseLeave: () => {
    props.timer?.resume()
  },
}))<SnackbarProps>`
  color: ${BrandColors.white.toString()};
  background: ${BrandColors.blackcurrant.mix(BrandColors.white, Opacities.twelve).toString()};
  border: none;
  padding: ${rem(16)};
  margin: ${rem(12)} 0 0 0;
  width: ${rem(352)};
  max-width: 100%;
  outline: 1px solid ${BrandColors.white.alpha(Opacities.twelve).toString()};
  outline-offset: -1px;

  ${MediaQueries.desktop} {
    padding: ${rem(18)} ${rem(16)};
    margin-top: ${rem(12)};
  }

  ${(props) => {
    switch (props.variant) {
      case 'success':
        return css`
          border-left: 32px solid ${SystemColors.success.toString()};
        `
      case 'info':
        return css`
          border-left: 32px solid ${SystemColors.info.toString()};
        `
      case 'warning':
        return css`
          border-left: 32px solid ${SystemColors.warning.toString()};
        `
      case 'danger':
        return css`
          border-left: 32px solid ${SystemColors.danger.toString()};
        `
    }
  }}
`
