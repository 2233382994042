import React, { type FunctionComponent, useState } from 'react'

import placeholder from '../../assets/images/albumArtPlaceholder.svg'
import { rem } from '../../utils/rem'
import Image from '../image/Image'
import SizedBox, { type ResponsiveSize } from '../sized-box/SizedBox'

export interface AlbumArtProps {
  fallback?: string
  size?: string | number | ResponsiveSize
  src?: string
}

const AlbumArt: FunctionComponent<React.PropsWithChildren<AlbumArtProps>> = ({
  src,
  size = { desktop: rem(160), mobile: rem(120) } as ResponsiveSize,
  fallback = placeholder,
}) => {
  const [error, setError] = useState(false)

  return (
    <SizedBox width={size} height={size}>
      <Image
        src={error ? fallback : src}
        fallback={fallback}
        loading="lazy"
        width="100%"
        height="100%"
        onError={() => setError(true)}
      />
    </SizedBox>
  )
}

export default AlbumArt
