import React, { type FunctionComponent, type HTMLProps } from 'react'
import styled from 'styled-components'

export interface AspectRatioProps extends Omit<HTMLProps<HTMLDivElement>, 'as' | 'ref'> {
  aspectRatio: number
}

/**
 * The AspectRatio provides its children with a width and a height based on the
 * ratio prop.
 *
 * @example ```
 *   <AspectRatio aspectRatio={16 / 9}>
 *     ...
 *   </AspectRatio>
 * ```
 *
 * NB: This component uses the good old relative padding trick. This means that
 * the AspectRatio component will only work correctly if the parent has a
 * calculable width.
 * @see https://css-tricks.com/aspect-ratio-boxes/
 *
 * NB: This component is inspired by, but not identical to the Flutter
 * equivalent.
 * @see https://api.flutter.dev/flutter/widgets/AspectRatio-class.html
 */
const AspectRatio: FunctionComponent<React.PropsWithChildren<AspectRatioProps>> = ({ children, ...props }) => (
  <Outer {...props}>
    <Inner>{children}</Inner>
  </Outer>
)

export default AspectRatio

interface OuterProps {
  aspectRatio: number
}

export const Outer = styled.div<OuterProps>`
  position: relative;
  width: 100%;
  height: 0;
  padding-top: ${(props) => 100 / props.aspectRatio}%;
`

export const Inner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`
