import React, { type FC } from 'react'

interface Props {
  color?: string
}

const SignOutIcon: FC<React.PropsWithChildren<Props>> = ({ color, ...props }: Props) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M10.6665 11.3337L13.9998 8.00033L10.6665 4.66699"
      stroke={color ?? 'white'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M14 8H6" stroke={color ?? 'white'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M6 14H3.33333C2.97971 14 2.64057 13.8595 2.39052 13.6095C2.14048 13.3594 2 13.0203 2 12.6667V3.33333C2 2.97971 2.14048 2.64057 2.39052 2.39052C2.64057 2.14048 2.97971 2 3.33333 2H6"
      stroke={color ?? 'white'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default SignOutIcon
