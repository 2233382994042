import { API, graphqlOperation } from 'aws-amplify'
import { navigate } from 'gatsby'
import { SecondaryButton, SignOutIcon, Snackbar, useSnackbars } from 'ledo-react'
import React, { type FunctionComponent, useCallback } from 'react'
import { useQueryClient } from 'react-query'

import { disconnectWallet } from '../../../graphql/wallet'
import { useUser } from '../../../hooks/useUser'
import { useDisconnect } from '../../../utils/connectors'
import { routes } from '../../../utils/routes'

const SignOutButton: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
  const { user, setUser, selectGroup } = useUser()
  const queryClient = useQueryClient()
  const { addSnackbar } = useSnackbars()
  const disconnect = useDisconnect()

  const handleSignOut = useCallback(() => {
    void (async () => {
      if (!user) {
        return
      }

      await API.graphql(graphqlOperation(disconnectWallet))
      disconnect()

      user.signOut()
      setUser(undefined)
      selectGroup('')
      typeof window !== 'undefined' && localStorage.clear()
      queryClient.removeQueries()
      queryClient.clear()
      void navigate(routes.login.url)
      addSnackbar(<Snackbar variant="info">You&apos;ve been signed out.</Snackbar>)
    })()
  }, [user, disconnect, setUser, selectGroup, queryClient, addSnackbar])

  return (
    <SecondaryButton onClick={handleSignOut} size="small" trailing={<SignOutIcon />} style={{ width: '100%' }}>
      Sign Out
    </SecondaryButton>
  )
}

export default SignOutButton
