import { type WindowLocation } from '@gatsbyjs/reach-router'
import { navigate } from 'gatsby'
import qs, { type ParsedQs } from 'qs'

import { isObject } from './types'

export function getQueryParameter(location: WindowLocation, parameter: string): string | undefined {
  const parameters: ParsedQs = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  })

  if (isObject(parameters[parameter])) {
    return undefined
  }

  return parameters[parameter]?.toString() ?? undefined
}

export function constructSearchParams(params: { [key: string]: string }): string {
  return qs.stringify(params, {
    addQueryPrefix: false,
    arrayFormat: 'comma',
    skipNulls: true,
  })
}

export function navigateWithSearchParams(
  to: string,
  params: {
    [key: string]: string
  },
): void {
  const urlParams = constructSearchParams(params)
  void navigate(`${to}?${urlParams}`)
}
