import React, { type FunctionComponent, useRef } from 'react'
import { v4 as uuid } from 'uuid'

import FormField, { type FormFieldProps } from '../form-field/FormField'
import Input, { type InputProps } from '../input/Input'

export interface EmailFieldProps extends Omit<FormFieldProps, 'htmlFor'> {
  id?: string
  input?: Omit<InputProps, 'disabled' | 'error'>
  light?: boolean
  placeholder?: string
}

const EmailField: FunctionComponent<React.PropsWithChildren<EmailFieldProps>> = ({
  id,
  input,
  light,
  placeholder,
  ...props
}) => {
  const idRef = useRef(id ?? uuid())

  return (
    <FormField {...props} htmlFor={idRef.current}>
      <Input
        {...input}
        light={light}
        type="email"
        id={idRef.current}
        disabled={props.disabled}
        error={!!props.error}
        placeholder={placeholder}
      />
    </FormField>
  )
}

export default EmailField
