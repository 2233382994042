import { Connector } from '@web3-react/types'
import { API, graphqlOperation } from 'aws-amplify'
import { createConnectWalletMessage } from 'ledo-api/src/shared/nft'
import { Dialog, DialogProps, List, ListItem, LoadingDialog, SizedBox, Snackbar, useSnackbars } from 'ledo-react'
import React, { ComponentType, FC, SVGAttributes, useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'

import { ConnectWalletMutationVariables } from '../../graphql/generated'
import { connectWallet } from '../../graphql/wallet'
import { useDataLayer } from '../../hooks/useDataLayer'
import { useUser } from '../../hooks/useUser'
import { getConnector, metaMask, useConnect, walletConnectV2, walletLink } from '../../utils/connectors'
import CoinbaseIcon from './CoinbaseIcon'
import MetaMaskIcon from './MetaMaskIcon'
import WalletConnectIcon from './WalletConnectIcon'

interface Props extends DialogProps {
  afterSucces?: () => void
}

const ConnectWalletDialog: FC<Props> = (props) => {
  const [loadingState, setLoadingState] = useState<'CONNECTING' | 'SIGNING' | null>(null)

  const { addSnackbar } = useSnackbars()
  const { usePriorityProvider, usePriorityAccount } = getConnector()
  const provider = usePriorityProvider()
  const account = usePriorityAccount()
  const { userProfile, hydrate } = useUser()

  const onConnecting = useCallback(() => {
    setLoadingState('CONNECTING')
  }, [setLoadingState])

  const onConnected = useCallback(async () => {
    setLoadingState('SIGNING')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provider, account, userProfile])
  const onAbort = useCallback(() => {
    setLoadingState(null)
  }, [setLoadingState])

  const { update: updateDataLayer } = useDataLayer()

  const onSign = useCallback(async () => {
    try {
      const signature = await provider
        ?.getSigner()
        .signMessage(createConnectWalletMessage(userProfile.userId, userProfile.email))

      const vars: ConnectWalletMutationVariables = {
        signature,
        walletAddress: account,
      }
      await API.graphql(graphqlOperation(connectWallet, vars))
      await hydrate()
      updateDataLayer({
        event: 'wallet_connected',
      })
      props.afterSucces && props.afterSucces()
    } catch (ex) {
      console.error(ex)
      addSnackbar(<Snackbar variant="danger">Unable to verify that you own this wallet.</Snackbar>)
    } finally {
      setLoadingState(null)
      props.onClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provider, account, loadingState])

  useEffect(() => {
    if (loadingState === 'SIGNING') {
      if (provider && account) {
        void onSign()
      } else {
        // we're signing, but no account was given, so probably rejected connection, abort.
        onAbort()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingState, provider, account])

  const eventProps = { onAbort, onConnected, onConnecting }

  return !loadingState ? (
    <Dialog title={props.title ?? 'Connect your wallet to Collabhouse'} {...props} actions={<></>}>
      <SizedBox height={32} />
      <List separator={<SizedBox height={16} />}>
        <ConnectItem icon={MetaMaskIcon} title="MetaMask" connector={metaMask as Connector} {...eventProps} />
        <ConnectItem icon={CoinbaseIcon} title="Coinbase Wallet" connector={walletLink} {...eventProps} />
        <ConnectItem
          icon={WalletConnectIcon}
          title="Other wallets"
          subtitle="WalletConnect"
          connector={walletConnectV2}
          {...eventProps}
        />
      </List>
    </Dialog>
  ) : (
    <LoadingDialog
      title={`Adding your wallet to Collabhouse. ${loadingState === 'CONNECTING' ? '1' : '2'}/2`}
      description={loadingState === 'CONNECTING' ? 'Awaiting approval...' : 'Signing...'}
      visible
    />
  )
}

const ConnectItem: FC<{
  connector: Connector
  icon: ComponentType<SVGAttributes<SVGElement>>
  onAbort: () => void
  onConnected: () => void
  onConnecting: () => void
  subtitle?: string
  title: string
}> = ({ connector, icon: Icon, onConnecting, onConnected, onAbort, ...rest }) => {
  const connect = useConnect(connector)
  return (
    <ConnectItemWrapper
      onClick={() => {
        void (async () => {
          try {
            onConnecting()
            await connect()
            onConnected()
          } catch (ex) {
            console.error(ex)
            onAbort()
          }
        })()
      }}
    >
      <ListItem leading={<Icon width={30} height={30} />} {...rest} />
    </ConnectItemWrapper>
  )
}

const ConnectItemWrapper = styled.div`
  cursor: pointer;

  * {
    pointer-events: none;
  }
`

export default ConnectWalletDialog
