import React, { type FunctionComponent, useRef } from 'react'
import styled, { css } from 'styled-components'
import { v4 as uuid } from 'uuid'

import { BrandColors, SystemColors } from '../../tokens/colors'
import { Opacities } from '../../tokens/opacities'
import { type InputProps } from '../input/Input'
import PrimaryButton from '../primary-button/PrimaryButton'

export interface FileInputHTMLAttributes extends InputProps {
  files?: FileList | null
}

export interface FileInputProps extends Omit<FileInputHTMLAttributes, 'type' | 'value'> {
  id?: string
  label?: string
}

const FileInput: FunctionComponent<React.PropsWithChildren<FileInputProps>> = ({ id, label, ...props }) => {
  const idRef = useRef(id ?? uuid())

  return (
    <Label htmlFor={idRef.current}>
      <Input {...props} id={idRef.current} type="file" aria-label={label} />
      <Button as="div" disabled={props.disabled}>
        {label}
      </Button>
    </Label>
  )
}

const Label = styled.label`
  flex-shrink: 0;
  input:focus ~ div {
    background: ${BrandColors.springGreen.mix(BrandColors.white, Opacities.fiftyfive).toString()};
    border-color: ${BrandColors.springGreen.mix(BrandColors.white, Opacities.fiftyfive).toString()};
    outline-color: ${SystemColors.info.toString()};
  }
`

const Input = styled.input`
  position: absolute;
  opacity: 0;
`

interface ButtonProps {
  disabled?: boolean
}

const Button = styled(PrimaryButton)<ButtonProps>`
  display: inline-flex;
  align-items: center;

  ${({ disabled = false }) =>
    disabled &&
    css`
      pointerevents: 'none';
      background: BrandColors.springGreen .mix(BrandColors.blackcurrant, 0.5) .toString();
      bordercolor: BrandColors.springGreen .mix(BrandColors.blackcurrant, 0.5) .toString();
      color: BrandColors.blackcurrant.alpha(Opacities.thirtyfive) .toString();
    `}
`

export default FileInput
