import {
  type ChangeEventHandler,
  default as React,
  type FunctionComponent,
  type MouseEvent,
  type TouchEvent,
  useCallback,
  useEffect,
  useState,
} from 'react'
import styled from 'styled-components'

import { useAudioPlayer } from '../../hooks/useAudioPlayer'
import { useAudioProgress } from '../../hooks/useAudioProgress'
import { BrandColors } from '../../tokens/colors'
import { MediaQueries } from '../../tokens/mediaQueries'
import { Opacities } from '../../tokens/opacities'
import { rem } from '../../utils/rem'
import Caption from '../caption/Caption'
import Column from '../column/Column'
import DesktopOnly from '../desktop-only/DesktopOnly'
import MobileOnly from '../mobile-only/MobileOnly'
import RangeInput from '../range-input/RangeInput'
import Row from '../row/Row'
import SizedBox from '../sized-box/SizedBox'

const SeekBar: FunctionComponent<React.PropsWithChildren<unknown>> = ({ children }) => {
  const { item, duration } = useAudioPlayer()
  const { currentTime, setCurrentTime } = useAudioProgress()
  const [progress, setProgress] = useState<number>(0)
  const [dragging, setDragging] = useState(false)

  const onSeekBarChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      setDragging(true)
      setProgress(parseInt(event.target.value))
    },
    [setDragging, setProgress],
  )

  // @TODO not sure on how to type this since it's being used to both touch and mouse
  const onDragEnded = useCallback(
    (event: MouseEvent | TouchEvent) => {
      setCurrentTime(parseFloat((event.target as HTMLInputElement).value))
      setDragging(false)
    },
    [setCurrentTime, setDragging],
  )

  useEffect(() => {
    if (!dragging) {
      setProgress(currentTime ?? 0)
    }
  }, [dragging, currentTime, setProgress])

  return (
    <Wrapper mainAxisAlignment="center">
      <Row crossAxisAlignment="center">
        <DesktopOnly>
          <Row>
            <Time>{secondsToMinutes(progress)}</Time>
            <SizedBox width={rem(8)} />
          </Row>
        </DesktopOnly>
        <RangeInput
          disabled={!item}
          value={progress}
          min={0}
          max={duration}
          onChange={onSeekBarChange}
          onMouseUp={onDragEnded}
          onTouchEnd={onDragEnded}
        />
        <DesktopOnly>
          <Row>
            <SizedBox width={rem(8)} />
            <Time>{secondsToMinutes(duration ?? 0)}</Time>
          </Row>
        </DesktopOnly>
      </Row>
      {children && <SizedBox height={rem(16)} />}
      <MobileOnly>
        <Row mainAxisAlignment="space-between">
          <Time>{secondsToMinutes(progress)}</Time>
          <SizedBox width={rem(8)} />
          {children}
          <SizedBox width={rem(8)} />
          <Time style={{ textAlign: 'right' }}>{secondsToMinutes(duration ?? 0)}</Time>
        </Row>
      </MobileOnly>
    </Wrapper>
  )
}

export const secondsToMinutes = (seconds: number): string => {
  if (!seconds) {
    return '0:00'
  }

  const m = Math.floor(seconds / 60)
  const s = Math.floor((seconds % 3600) % 60)

  return `${m}:${`0${s}`.slice(-2)}`
}

export default SeekBar

const Wrapper = styled(Column)`
  flex-grow: 1;
`

const Time = styled(Caption).attrs({ size: 'small' })`
  text-align: left;
  color: ${BrandColors.white.alpha(Opacities.fiftyfive).toString()};
  min-width: 15%;

  ${MediaQueries.desktop} {
    color: ${BrandColors.white.toString()};
    min-width: ${rem(32)};
    text-align: center;
  }
`
