import styled from 'styled-components'

import Flex, { FlexProps } from '../flex/Flex'

export type ColumnProps = Omit<FlexProps, 'direction'> & {
  gap?: string
  grow?: string | number
  shrink?: string | number
}

/**
 * A Flex component laid out in a vertical orientation.
 *
 * NB: This component is inspired by, but not identical to the Flutter
 * equivalent.
 * @see https://api.flutter.dev/flutter/widgets/Column-class.html
 */
const Column = styled(Flex).attrs<ColumnProps>({
  direction: 'column',
})`
  ${(props: ColumnProps) => (props.grow ? `flex-grow: ${props.grow};` : '')}

  ${(props: ColumnProps) => (props.shrink ? `flex-shrink: ${props.shrink};` : '')}

  ${(props) => (props.gap ? `gap: ${props.gap};` : '')}
`

export default Column
