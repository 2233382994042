import styled, { css } from 'styled-components'

import { MediaQueries } from '../../tokens/mediaQueries'
import { FontFamily } from '../../tokens/typography'
import { rem } from '../../utils/rem'
import Text, { TextProps } from '../text/Text'

export type TitleSize = 'xxSmall' | 'xSmall' | 'small' | 'medium' | 'large' | 'xLarge' | 'xxLarge'

export interface ResponsiveSize {
  desktop: TitleSize
  mobile: TitleSize
}

export interface TitleProps extends TextProps {
  colored?: boolean
  size?: TitleSize | ResponsiveSize
}

const Title = styled(Text).attrs({
  as: 'h1',
})<TitleProps>(
  ({ size = 'medium', ...props }) => css`
    font-family: ${FontFamily.regular};
    font-weight: 700;
    color: ${props.colored ? props.theme.secondary.toString() : props.theme.foreground.toString()};
    letter-spacing: ${rem(0)};

    ${() => css`
      ${typeof size === 'string' ? getSizeStyles(size).mobile : getSizeStyles(size.mobile).mobile}
      ${MediaQueries.desktop} {
        ${typeof size === 'string' ? getSizeStyles(size).desktop : getSizeStyles(size.desktop).desktop}
      }
    `}
  `,
)

export default Title

const getSizeStyles = (size: TitleSize) => {
  switch (size) {
    case 'xxSmall':
      return {
        desktop: css`
          font-size: ${rem(22)};
          line-height: ${rem(24)};
        `,
        mobile: css`
          font-size: ${rem(18)};
          line-height: ${rem(24)};
        `,
      }

    case 'xSmall':
      return {
        desktop: css`
          font-size: ${rem(28)};
          line-height: ${rem(32)};
        `,
        mobile: css`
          font-size: ${rem(24)};
          line-height: ${rem(24)};
        `,
      }

    case 'small':
      return {
        desktop: css`
          font-size: ${rem(32)};
          line-height: ${rem(32)};
        `,
        mobile: css`
          font-size: ${rem(28)};
          line-height: ${rem(28)};
        `,
      }

    case 'medium':
      return {
        desktop: css`
          font-size: ${rem(40)};
          line-height: ${rem(40)};
        `,
        mobile: css`
          font-size: ${rem(32)};
          line-height: ${rem(32)};
        `,
      }

    case 'large':
      return {
        desktop: css`
          font-size: ${rem(48)};
          line-height: ${rem(48)};
        `,
        mobile: css`
          font-size: ${rem(40)};
          line-height: ${rem(40)};
        `,
      }

    case 'xLarge':
      return {
        desktop: css`
          font-size: ${rem(64)};
          line-height: ${rem(64)};
        `,
        mobile: css`
          font-size: ${rem(48)};
          line-height: ${rem(48)};
        `,
      }

    case 'xxLarge':
      return {
        desktop: css`
          font-size: ${rem(80)};
          line-height: ${rem(80)};
        `,
        mobile: css`
          font-size: ${rem(52)};
          line-height: ${rem(52)};
        `,
      }
  }
}
