import { IframeHTMLAttributes } from 'react'
import styled from 'styled-components'

export interface VimeoVideoProps extends IframeHTMLAttributes<never> {
  vimeoId: string
}

const VimeoVideo = styled.iframe.attrs<VimeoVideoProps>(({ vimeoId, ...props }) => ({
  allow: 'autoplay; fullscreen; picture-in-picture',
  allowFullScreen: true,
  frameBorder: 0,
  src: `https://player.vimeo.com/video/${vimeoId}`,
  ...props,
}))<VimeoVideoProps>`
  width: 100%;
  height: 100%;
`

export default VimeoVideo
