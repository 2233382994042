import styled from 'styled-components'

import Flex, { type FlexProps } from '../flex/Flex'

export type RowProps = Omit<FlexProps, 'direction'> & {
  gap?: string
}

/**
 * A Flex component laid out in a horizontal orientation.
 *
 * NB: This component is inspired by, but not identical to the Flutter
 * equivalent.
 * @see https://api.flutter.dev/flutter/widgets/Row-class.html
 */
const Row = styled(Flex).attrs<RowProps>({
  direction: 'row',
})<RowProps>`
  ${(props) => (props.gap ? `gap: ${props.gap};` : '')}
`

export default Row
