import React, { type FunctionComponent, type MouseEventHandler, type ReactChild, useCallback } from 'react'
import { animated, useSpring } from 'react-spring'
import styled from 'styled-components'

import useMeasure from '../../hooks/useMeasure'
import { type IconData, Icons } from '../../tokens/icons'
import { Opacities } from '../../tokens/opacities'
import { Transitions } from '../../tokens/transitions'
import { rem } from '../../utils/rem'
import BaseButton from '../base-button/BaseButton'
import Caption from '../caption/Caption'
import Column from '../column/Column'
import Divider from '../divider/Divider'
import { type MainAxisAlignment } from '../flex/Flex'
import Icon from '../icon/Icon'
import Padding from '../padding/Padding'
import Row from '../row/Row'
import Title from '../title/Title'

export type ExpandableSize = 'xsmall' | 'small' | 'medium'

export interface ExpandableProps {
  customIcons?: {
    closeIcon: IconData
    openIcon: IconData
  }
  expanded: boolean
  hasDivider?: boolean
  mainAxisAlignment?: MainAxisAlignment
  onHeaderClick?: MouseEventHandler
  size?: ExpandableSize
  title: ReactChild
}

const Expandable: FunctionComponent<React.PropsWithChildren<ExpandableProps>> = ({
  expanded,
  title,
  onHeaderClick,
  size = 'medium',
  children,
  hasDivider,
  customIcons,
  mainAxisAlignment = 'space-between',
  ...rest
}) => {
  const [ref, { height }] = useMeasure<HTMLDivElement>()

  const style = useSpring({
    height: expanded ? height : 0,
  })

  const header = useCallback(() => {
    switch (size) {
      case 'xsmall':
        return (
          <Padding vertical={rem(12)} horizontal={rem(16)}>
            <Caption size="small">{title}</Caption>
          </Padding>
        )
      case 'small':
        return (
          <Padding vertical={rem(12)} right={rem(20)}>
            <Caption size="medium">{title}</Caption>
          </Padding>
        )

      case 'medium':
      default:
        return (
          <Padding vertical={{ desktop: rem(24), mobile: rem(20) }} right={rem(20)}>
            <Title size="xSmall">{title}</Title>
          </Padding>
        )
    }
  }, [size, title])

  return (
    <Column>
      <Header
        mainAxisAlignment={mainAxisAlignment}
        crossAxisAlignment="center"
        onClick={onHeaderClick}
        expanded={expanded}
        customIcons={!!customIcons}
        {...rest}
      >
        {header()}
        {customIcons ? (
          <Padding right={rem(12)}>
            <Icon icon={expanded ? customIcons.closeIcon : customIcons.openIcon} size={20} />
          </Padding>
        ) : (
          <Icon icon={expanded ? Icons.chevronUp : Icons.chevronDown} />
        )}
      </Header>
      {hasDivider && <Divider />}

      <Panel style={style}>
        <div ref={ref}>
          <Padding top={rem(8)} bottom={{ desktop: rem(32), mobile: rem(24) }}>
            {children}
          </Padding>
        </div>
      </Panel>
    </Column>
  )
}

export default Expandable

interface HeaderProps {
  customIcons?: boolean
  expanded: boolean
}

const Header = styled(Row).attrs({ as: BaseButton })<HeaderProps>`
  width: 100%;
  padding: 0;
  opacity: ${({ expanded, customIcons }) =>
    customIcons ? (expanded ? Opacities.fiftyfive : Opacities.fiftyfive) : Opacities.full};
  transition: opacity ${Transitions.micro};
  text-align: left;
  position: relative;

  :hover,
  :focus {
    cursor: pointer;
    opacity: ${Opacities.seventyfive};
  }
`

const Panel = animated(styled(Column)`
  overflow: hidden;
`)
