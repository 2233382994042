import styled, { css } from 'styled-components'

import { MediaQueries } from '../../tokens/mediaQueries'
import { FontFamily } from '../../tokens/typography'
import { rem } from '../../utils/rem'
import Text, { TextProps } from '../text/Text'

export type CaptionSize = 'small' | 'medium'

export interface CaptionProps extends TextProps {
  bold?: boolean
  color?: string
  size?: CaptionSize
}

const Caption = styled(Text)<CaptionProps>(
  ({ size = 'small', color, bold }) => css`
    ${size === 'small' &&
    css`
      font-family: ${FontFamily.regular};
      letter-spacing: ${rem(0)};
    `}
    ${color &&
    css`
      color: ${color};
    `}

    ${bold &&
    css`
      font-weight: bold;
    `}

    ${() => {
      switch (size) {
        case 'medium':
          return css`
            font-size: ${rem(16)};
            line-height: ${rem(20)};

            ${MediaQueries.desktop} {
              font-size: ${rem(18)};
              line-height: ${rem(24)};
            }
          `

        case 'small':
          return css`
            font-size: ${rem(14)};
            line-height: ${rem(16)};

            ${MediaQueries.desktop} {
              font-size: ${rem(16)};
              line-height: ${rem(20)};
            }
          `
      }
    }}
  `,
)

export default Caption
