import React, { FunctionComponent, useRef } from 'react'
import styled from 'styled-components'

import { BrandColors } from '../../tokens/colors'
import { Icons } from '../../tokens/icons'
import { rem } from '../../utils/rem'
import Avatar from '../avatar/Avatar'
import Icon from '../icon/Icon'
import Row from '../row/Row'

export interface AvatarUploaderProps {
  image?: string | File
  onFileSelect?: (file: File) => void
}

const AvatarUploader: FunctionComponent<AvatarUploaderProps> = ({ image: urlOrFile, onFileSelect }) => {
  const fileInputRef = useRef<HTMLInputElement>(null)

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()

    const selectedFile = event.target.files?.[0]

    if (selectedFile) {
      onFileSelect?.(selectedFile)
    }
  }

  const handleButtonClick = () => {
    fileInputRef.current?.click()
  }

  return (
    <RowWrapper>
      <AvatarContainer>
        <Avatar
          src={urlOrFile instanceof File ? URL.createObjectURL(urlOrFile) : urlOrFile}
          fallback={'/images/transparentAvatarPlaceholder.svg'}
          size={rem(120)}
        />

        <CustomIconButton onClick={handleButtonClick} type="button">
          <Icon icon={!urlOrFile ? Icons.plus : Icons.pencil} color={BrandColors.blackcurrant.toString()} size={20} />
        </CustomIconButton>
      </AvatarContainer>
      <HiddenFileInput ref={fileInputRef} type="file" accept=".png, .jpeg, .jpg" onChange={handleFileSelect} />
    </RowWrapper>
  )
}

const RowWrapper = styled(Row)`
  position: relative;
`

const AvatarContainer = styled.div`
  position: relative;
`

const HiddenFileInput = styled.input`
  display: none;
`

const CustomIconButton = styled.button`
  background-color: ${BrandColors.springGreen.toString()};
  position: absolute;
  bottom: 0;
  right: 0;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  width: ${rem(32)};
  height: ${rem(32)};
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
`

export default AvatarUploader
