import React, { FunctionComponent, useRef } from 'react'
import styled from 'styled-components'
import { v4 as uuid } from 'uuid'

import { MediaQueries } from '../../tokens/mediaQueries'
import FormField, { FormFieldProps } from '../form-field/FormField'
import IndeterminateProgress from '../indeterminate-progress/IndeterminateProgress'
import Input, { InputProps } from '../input/Input'

export interface TextFieldProps extends Omit<FormFieldProps, 'htmlFor'> {
  id?: string
  input?: Omit<InputProps, 'disabled' | 'error'>
  isLoading?: boolean
  light?: boolean
  placeholder?: string
}

const TextField: FunctionComponent<React.PropsWithChildren<TextFieldProps>> = ({
  id,
  input,
  light,
  placeholder,
  isLoading,
  ...props
}) => {
  const idRef = useRef(id ?? uuid())

  return (
    <FormField {...props} htmlFor={idRef.current}>
      <Input
        {...input}
        light={light}
        type="text"
        id={idRef.current}
        disabled={props.disabled}
        error={!!props.error}
        data-cy={input?.dataCy}
        placeholder={placeholder ?? input?.placeholder}
      />
      {isLoading && (
        <IconContainer>
          <IndeterminateProgress />
        </IconContainer>
      )}
    </FormField>
  )
}

const IconContainer = styled.div`
  position: absolute;
  right: 8px;
  top: 44px;

  ${MediaQueries.desktop} {
    right: 10px;
    top: 50px;
  }
`

export default TextField
