import {
  AlbumArt,
  AudioPlayerContainer,
  BrandColors,
  Caption,
  Column,
  Divider,
  Expanded,
  Icon,
  IconButton,
  Icons,
  MediaQueries,
  Opacities,
  PlayPauseButton,
  rem,
  Row,
  SecondaryIconButton,
  SeekBar,
  SizedBox,
  Title,
  useAudioPlayer,
} from 'ledo-react'
import React, { FunctionComponent, useRef } from 'react'
import styled from 'styled-components'

import { useWindow } from '../../hooks/useWindow'

export interface ExpandedAudioPlayerProps {}

const ExpandedAudioPlayer: FunctionComponent<React.PropsWithChildren<ExpandedAudioPlayerProps>> = () => {
  const content = useRef<HTMLDivElement>()
  const { item, playing, togglePlaying, expanded, setExpanded } = useAudioPlayer()
  const { height } = useWindow()

  return (
    <AudioPlayerContainer visible={expanded}>
      <Panel style={{ height: `${height}px` }}>
        <Header mainAxisAlignment="center" crossAxisAlignment="center">
          <Text>Now Playing</Text>
          <CloseButton
            icon={Icons.chevronDown}
            onClick={() => setExpanded(false)}
            data-gtm-name="cta-expanded-audio-player-close"
          />
        </Header>
        <Divider />
        <ScrollView>
          <Main
            mainAxisAlignment={height * 0.9 > content.current?.clientHeight ? 'center' : 'flex-start'}
            crossAxisAlignment="stretch"
          >
            <Column mainAxisAlignment="flex-start" crossAxisAlignment="stretch" ref={content}>
              <SizedBox height={rem(24)} />
              <AlbumWrapper mainAxisAlignment="center">
                <AlbumArt size={rem(264)} src={item?.artworkUrl} />
              </AlbumWrapper>
              <SizedBox height={rem(16)} />
              {item?.subtitle && <Text>{item?.subtitle}</Text>}
              {(item?.subtitle || item?.title) && <SizedBox height={rem(6)} />}
              {item?.title && <Title size="xSmall">{item?.title}</Title>}
              <SizedBox height={rem(24)} />
              <SeekBar>{item?.genres && <Text>{item?.genres}</Text>}</SeekBar>
              <SizedBox height={rem(4)} />
              <Text>{item?.bpm} BPM</Text>
              <SizedBox height={rem(24)} />
              <Row mainAxisAlignment="center" crossAxisAlignment="center">
                {(item?.next || item?.prev) && (
                  <SecondaryIconButton
                    icon={<Icon icon={Icons.skipBackward} />}
                    disabled={!item.next}
                    data-gtm-name="cta-expanded-audio-player-prev"
                  />
                )}
                <SizedBox width={rem(40)} />
                <PlayPauseButton
                  size="small"
                  playing={playing}
                  onClick={togglePlaying}
                  disabled={!item}
                  data-gtm-name={playing ? 'cta-expanded-audio-player-pause' : 'cta-expanded-audio-player-play'}
                  data-gtm-value={item?.id}
                />
                <SizedBox width={rem(40)} />
                {(item?.next || item?.prev) && (
                  <SecondaryIconButton
                    icon={<Icon icon={Icons.skipForward} />}
                    disabled={!item.prev}
                    data-gtm-name="cta-expanded-audio-player-next"
                  />
                )}
              </Row>
              <SizedBox height={rem(28)} />
            </Column>
          </Main>
        </ScrollView>
      </Panel>
    </AudioPlayerContainer>
  )
}

export default ExpandedAudioPlayer

const Panel = styled(Column)`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: ${BrandColors.darkBlue.toString()};
  overflow: hidden;

  hr {
    flex-shrink: 0;
  }
`

const Header = styled(Row).attrs({ as: 'header' })`
  position: relative;
  min-height: ${rem(63)};
  padding: ${rem(24)} ${rem(64)};
`

const ScrollView = styled(Expanded)`
  overflow-y: scroll;
`

const AlbumWrapper = styled(Row)`
  flex-shrink: 0;
`

const CloseButton = styled(IconButton)`
  position: absolute;
  top: ${rem(12)};
  right: ${rem(12)};
`

const Main = styled(Column).attrs({ as: 'main' })`
  padding: 0 ${rem(20)};
  text-align: center;

  ${MediaQueries.desktop} {
    max-width: ${rem(500)};
    margin: 0 auto;
  }
`

const Text = styled(Caption).attrs({ size: 'small' })`
  color: ${BrandColors.white.alpha(Opacities.fiftyfive).toString()};
`
