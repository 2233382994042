import { type GraphQLResult } from '@aws-amplify/api-graphql'

import {
  type ChatInput,
  type GroupChannel,
  type MutationRefreshSendbirdSessionArgs,
  type SendbirdSession,
} from '@/graphql/generated'
import { graphqlApi } from '@/utils/graphql'

export const startSendbirdChat = /* GraphQL */ `
  mutation StartSendbirdChat($initiatingGroupId: ID!, $targetGroupId: ID!) {
    startChat(input: { initiatingGroupId: $initiatingGroupId, targetGroupId: $targetGroupId }) {
      channelUrl
    }
  }
`

export const refreshSendbirdSession = /* GraphQL */ `
  mutation RefreshSendbirdSessionForGroup($input: RefreshSendbirdSessionInput!) {
    refreshSendbirdSession(input: $input) {
      userId
      token
      expiresAt
    }
  }
`

export const fetchChannel = async (chatInput: ChatInput): Promise<GraphQLResult<GroupChannel>> => {
  const variables = {
    initiatingGroupId: chatInput.initiatingGroupId,
    targetGroupId: chatInput.targetGroupId,
  }

  return await graphqlApi<GroupChannel>(startSendbirdChat, variables)
}

export const fetchRefreshSendbirdSession = async (
  variables: MutationRefreshSendbirdSessionArgs,
): Promise<GraphQLResult<SendbirdSession>> => await graphqlApi<SendbirdSession>(refreshSendbirdSession, variables)
