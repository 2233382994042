import { MediaQueries } from 'ledo-react'
import styled from 'styled-components'

const DesktopOnly = styled.div`
  display: none;

  ${MediaQueries.desktop} {
    display: initial;
  }
`
export default DesktopOnly
