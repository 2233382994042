import React, { createContext, type FunctionComponent, type ReactNode, useCallback, useEffect, useState } from 'react'

import { useAudioPlayer } from '../hooks/useAudioPlayer'

export interface AudioProgressContext {
  currentTime?: number
  setCurrentTime: (value: number) => void
}

export const audioProgressContext = createContext<AudioProgressContext>({
  currentTime: undefined,
  setCurrentTime: () => undefined,
})

interface AudioProgressProviderProps {
  children: ReactNode
}

const AudioProgressProvider: FunctionComponent<React.PropsWithChildren<AudioProgressProviderProps>> = ({
  children,
}) => {
  const { audio } = useAudioPlayer()
  const [currentTime, setCurrentTimeState] = useState(0)

  /**
   * Set audio current time.
   */
  const setCurrentTime = useCallback(
    (value: number) => {
      setCurrentTimeState(value)
      if (audio?.current) {
        audio.current.currentTime = value
      }
    },
    [audio],
  )

  /**
   * Update current time state on audio timeupdate.
   */
  const handleTimeUpdate = useCallback(() => {
    if (audio?.current) {
      setCurrentTimeState(audio.current.currentTime)
    }
  }, [audio, setCurrentTimeState])

  /**
   * Handle event listeners.
   */
  useEffect(() => {
    const audioRef = audio?.current

    audioRef?.addEventListener('timeupdate', handleTimeUpdate)

    return () => {
      audioRef?.removeEventListener('timeupdate', handleTimeUpdate)
    }
  }, [audio, handleTimeUpdate])

  return (
    <audioProgressContext.Provider
      value={{
        currentTime,
        setCurrentTime,
      }}
    >
      {children}
    </audioProgressContext.Provider>
  )
}

export default AudioProgressProvider

export const AudioProgressConsumer = audioProgressContext.Consumer
