import React, { type AudioHTMLAttributes, type FunctionComponent, useCallback } from 'react'
import styled, { css } from 'styled-components'

import { useAudioPlayer } from '../../hooks/useAudioPlayer'
import { useAudioProgress } from '../../hooks/useAudioProgress'
import { BrandColors } from '../../tokens/colors'
import { Icons } from '../../tokens/icons'
import { MediaQueries } from '../../tokens/mediaQueries'
import { Opacities } from '../../tokens/opacities'
import { Transitions } from '../../tokens/transitions'
import { rem } from '../../utils/rem'
import AlbumArt from '../album-art/AlbumArt'
import AudioPlayerContainer from '../audio-player-container/AudioPlayerContainer'
import Column from '../column/Column'
import Expanded from '../expanded/Expanded'
import Icon from '../icon/Icon'
import IconButton from '../icon-button/IconButton'
import LinearDeterminateProgress from '../linear-determinate-progress/LinearDeterminateProgress'
import PlayPauseButton from '../play-pause-button/PlayPauseButton'
import { type RangeInputProps } from '../range-input/RangeInput'
import { default as BaseRow, type RowProps as BaseRowProps } from '../row/Row'
import SecondaryIconButton from '../secondary-icon-button/SecondaryIconButton'
import SeekBar from '../seek-bar/SeekBar'
import SizedBox from '../sized-box/SizedBox'
import TrackCard from '../track-card/TrackCard'
import VerticalDivider from '../vertical-divider/VerticalDivider'
import VolumeBar from '../volume-bar/VolumeBar'

export type AudioPlayerProps = AudioHTMLAttributes<HTMLAudioElement>

const AudioPlayer: FunctionComponent<React.PropsWithChildren<AudioPlayerProps>> = () => {
  const { item, playing, togglePlaying, repeat, setRepeat, volume, setVolume, duration, setExpanded, reset } =
    useAudioPlayer()
  const visible = !!item
  const { currentTime } = useAudioProgress()

  const onVolumeChange: RangeInputProps['onChange'] = (event) => {
    setVolume?.(parseFloat(event?.target?.value ?? '1'))
  }

  const onRepeatClick = useCallback(() => {
    setRepeat?.(!repeat)
  }, [setRepeat, repeat])

  return (
    <AudioPlayerContainer visible={visible || !!item}>
      <Bar mainAxisAlignment="center">
        <ProgressBar>
          <Progress progress={currentTime} min={0} max={duration} />
        </ProgressBar>
        <Expanded>
          <Row crossAxisAlignment="center" mainAxisAlignment="space-between">
            <Row desktopOnly crossAxisAlignment="center">
              {(item?.next || item?.prev) && (
                <>
                  <SecondaryIconButton
                    icon={<Icon icon={Icons.skipBackward} />}
                    disabled={!item.next}
                    data-gtm-name="cta-audio-player-prev"
                  />
                  <SizedBox width={rem(24)} />
                </>
              )}
              <PlayPauseButton
                size="small"
                playing={playing}
                onClick={togglePlaying}
                disabled={!item}
                data-gtm-name={playing ? 'cta-audio-player-pause' : 'cta-audio-player-play'}
                data-gtm-value={item?.id}
              />
              {!item?.next && !item?.prev && <SizedBox width={rem(8)} />}
              {(item?.next || item?.prev) && (
                <>
                  <SizedBox width={rem(24)} />
                  <SecondaryIconButton
                    icon={<Icon icon={Icons.skipForward} />}
                    disabled={!item.prev}
                    data-gtm-name="cta-audio-player-next"
                  />
                </>
              )}
            </Row>
            <Row expanded desktopOnly>
              <SizedBox width={rem(40)} />
              <VerticalDivider />
              <SizedBox width={rem(40)} />
              <SeekBar />
              <SizedBox width={rem(32)} />
              <ActiveIndicator mainAxisAlignment="center" active={repeat}>
                <SecondaryIconButton
                  icon={<Icon icon={Icons.repeat} />}
                  onClick={onRepeatClick}
                  data-gtm-name="cta-audio-player-repeat"
                />
              </ActiveIndicator>
              <SizedBox width={rem(24)} />
              <VolumeBar
                input={{
                  onChange: onVolumeChange,
                  value: volume ?? 1,
                }}
              />
              {(item?.artworkUrl || item?.title || item?.subtitle) && (
                <>
                  <SizedBox width={rem(32)} />
                  <VerticalDivider />
                  <SizedBox width={rem(32)} />
                </>
              )}
            </Row>
            {(item?.artworkUrl || item?.title || item?.subtitle) && (
              <SizedBox width={rem(254)}>
                <StyledTrackCard
                  thumbnail={<AlbumArt src={item?.artworkUrl} size="100%" />}
                  title={item?.title}
                  subtitle={item?.subtitle}
                  onClick={() => {
                    setExpanded(true)
                  }}
                  isPlaying={playing}
                  reduceOpacityWhenPlaying={false}
                  data-gtm-name="cta-expanded-audio-player-open"
                />
              </SizedBox>
            )}
            <Row desktopOnly>
              {item?.onDownloadClick && (
                <>
                  <SizedBox width={rem(16)} />
                  <IconButton
                    icon={Icons.download}
                    onClick={item.onDownloadClick}
                    data-gtm-name="cta-audio-player-download"
                  />
                </>
              )}
              <SizedBox width={rem(16)} />
              <CloseButton
                icon={Icons.cross}
                onClick={() => reset?.()}
                data-gtm-name="cta-expanded-audio-player-close"
              />
            </Row>
            <Row mobileOnly>
              <SizedBox width={rem(16)} />
              <PlayPauseButton
                size="small"
                playing={playing}
                onClick={togglePlaying}
                disabled={!item}
                data-gtm-name={playing ? 'cta-audio-player-pause' : 'cta-audio-player-play'}
                data-gtm-value={item?.id}
              />
              <SizedBox width={rem(8)} />
              <CloseButton
                icon={Icons.cross}
                onClick={() => reset?.()}
                data-gtm-name="cta-expanded-audio-player-close"
              />
            </Row>
          </Row>
        </Expanded>
      </Bar>
    </AudioPlayerContainer>
  )
}

export default AudioPlayer

export interface RowProps extends BaseRowProps {
  desktopOnly?: boolean
  expanded?: boolean
  mobileOnly?: boolean
}

const Row = styled(BaseRow)<RowProps>(
  ({ expanded, mobileOnly, desktopOnly }) => css`
    ${expanded &&
    css`
      width: 100%;
      height: 100%;
    `}

    ${mobileOnly &&
    css`
      display: flex;

      ${MediaQueries.desktop} {
        display: none;
      }
    `}

    ${desktopOnly &&
    css`
      display: none;

      ${MediaQueries.desktop} {
        display: flex;
      }
    `}
  `,
)

const ProgressBar = styled(Row)`
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  background: ${BrandColors.white.alpha(Opacities.thirtyfive).toString()};

  ${MediaQueries.desktop} {
    display: none;
  }
`
const Progress = styled(LinearDeterminateProgress)`
  transition: width linear 0.2s;
`

const Bar = styled(Column)`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${BrandColors.darkBlue.toString()};
  padding: ${rem(16)} ${rem(20)};

  ${MediaQueries.desktop} {
    padding: ${rem(16)} ${rem(48)};
  }
`

const ActiveIndicator = styled(Column)<{ active?: boolean }>(
  ({ active = false }) => css`
    position: relative;
    align-self: stretch;

    :before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: ${rem(4)};
      background: ${BrandColors.white.toString()};
      transform: scaleY(0);
      transform-origin: left bottom;
      transition: transform ${Transitions.micro};
    }

    ${active &&
    css`
      :before {
        transform: scaleY(1);
      }
    `}
  `,
)
const StyledTrackCard = styled(TrackCard)`
  ${MediaQueries.desktop} {
    pointer-events: none;
  }
`

const CloseButton = styled(IconButton)`
  margin-right: 1rem;
`
