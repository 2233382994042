import { Dispatch, SetStateAction, useState } from 'react'

import { inBrowser } from '../utils/inBrowser'

export const useLocalStorage = <T>(key: string, initialValue: T): [T, Dispatch<SetStateAction<T>>] => {
  /**
   * Store the value in state, wrapped inside a function that retrieves the
   * value from local storage and JSON-decodes it.
   */
  const [storedValue, setStoredValue] = useState((): T => {
    if (!inBrowser()) {
      return initialValue
    }

    const item = window.localStorage.getItem(key)

    return item ? (JSON.parse(item) as T) : initialValue
  })

  /**
   * This function wraps the useState setter to persist the value into local
   * storage.
   */
  const setValue = (value: T | ((storedValue: T) => T)): void => {
    const valueToStore: T = value instanceof Function ? value(storedValue) : value

    setStoredValue(valueToStore)

    if (inBrowser()) {
      window.localStorage.setItem(key, JSON.stringify(valueToStore))
    }
  }

  return [storedValue, setValue]
}
