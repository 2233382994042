import styled, { css, keyframes } from 'styled-components'

import Text from '../text/Text'

export interface RotateProps {
  duration?: string
  timingFunction?: string
}

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const Rotate = styled(Text)<RotateProps>(
  ({ duration = '1s', timingFunction = 'linear' }) => css`
    display: inline-block;
    animation: ${rotate} ${duration} ${timingFunction} infinite;
  `,
)

export default Rotate
