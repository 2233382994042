import React, { type FunctionComponent, type ReactChild, useRef } from 'react'
import styled from 'styled-components'
import { v4 as uuid } from 'uuid'

import { type InputProps } from '../input/Input'

export interface BaseCheckboxInputProps extends Omit<InputProps, 'type'> {
  checkmark?: ReactChild
  disabled?: boolean
  error?: boolean
  small?: boolean
  transparent?: boolean
}

/**
 * This custom checkbox makes use of a hidden native HTML input element to
 * support accessibility features. The custom checkbox is a label of the native
 * input, and will therefore pass through HTML events.
 *
 * @example When you style pseudo-selectors, make sure to apply these selectors
 * to the native input element, and use the adajacent and self selectors to
 * apply the style to the custom checkbox:
 *
 *   ```css
 *   input:disabled + & {
 *     opacity: 0.5;
 *   }
 *   ```
 *
 * @see https://medium.com/claritydesignsystem/pure-css-accessible-checkboxes-and-radios-buttons-54063e759bb3
 */
const BaseCheckboxInput: FunctionComponent<React.PropsWithChildren<BaseCheckboxInputProps>> = ({
  id,
  className,
  style,
  checkmark = '✔',
  error,
  small,
  ...props
}) => {
  const idRef = useRef(id ?? uuid())

  return (
    <>
      <NativeCheckbox id={idRef.current} {...props} />
      <Checkbox
        className={className}
        style={style}
        disabled={props.disabled}
        checked={props.checked}
        error={error}
        htmlFor={idRef.current}
        small={small}
      >
        {props.checked && checkmark}
      </Checkbox>
    </>
  )
}

export default BaseCheckboxInput

interface CheckboxProps {
  checked?: boolean
  disabled?: boolean
  error?: boolean
  small?: boolean
}

const NativeCheckbox = styled.input.attrs({ type: 'checkbox' })`
  position: absolute;
  left: -9999px;
  opacity: 0;
`

const Checkbox = styled.label<CheckboxProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid white;
  font-size: 0.8rem;
  width: 1rem;
  height: 1rem;
  text-align: center;
  flex-shrink: 0;
  user-select: none;
  outline: 5px auto transparent;

  :hover {
    cursor: pointer;
  }

  input:disabled + & {
    pointer-events: none;
  }

  input:focus + & {
    outline-color: rgb(59, 153, 252);
  }
`
