import React, {
  FC,
  forwardRef,
  FunctionComponent,
  OptionHTMLAttributes,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import ReactSelect, {
  components,
  createFilter,
  GroupTypeBase,
  IndicatorProps,
  MenuProps,
  OptionProps,
  OptionsType,
  OptionTypeBase,
  SingleValueProps,
  Styles,
} from 'react-select'
import AsyncReactSelect, { Props as AsyncReactSelectProps } from 'react-select/async'
import { SelectComponentsConfig } from 'react-select/src/components'
import { NoticeProps } from 'react-select/src/components/Menu'
import { MultiValueRemoveProps } from 'react-select/src/components/MultiValue'
import { Config } from 'react-select/src/filters'
import styled from 'styled-components'

import { BrandColors, SystemColors } from '../../tokens/colors'
import { Icons } from '../../tokens/icons'
import { MediaQueries } from '../../tokens/mediaQueries'
import { Opacities } from '../../tokens/opacities'
import { Transitions } from '../../tokens/transitions'
import { FontFamily } from '../../tokens/typography'
import { rem } from '../../utils/rem'
import Icon from '../icon/Icon'

type Value = string

export interface Option extends Omit<OptionHTMLAttributes<HTMLOptionElement>, 'selected'> {
  image?: string
  label: string
  value: Value
}

export type Group = GroupTypeBase<Option>

export interface SelectProps<T extends OptionTypeBase = Option>
  extends Omit<AsyncReactSelectProps<T, false>, 'value' | 'onChange'> {
  async?: boolean
  dataCy?: string
  disabled?: boolean
  error?: boolean
  light?: boolean
  limit?: number
  matchFromStart?: boolean
  matchPerWord?: boolean
  onChange?: (value: Value | Value[]) => void
  onInputChange?: AsyncReactSelectProps<T, false>['onInputChange']
  onOptionChange?: AsyncReactSelectProps<T, false>['onChange']
  selectedOption?: AsyncReactSelectProps<T, false>['value']
  shouldCreateConfig?: boolean
  value?: Value | Value[]
}

const OptionWithImage: FunctionComponent<OptionProps<Option, false>> = (props) => (
  <components.Option {...props}>
    <div style={{ alignItems: 'center', display: 'flex', verticalAlign: 'middle' }}>
      {props.data.image && <img src={props.data.image} style={StyledOptionImage} alt="" />}
      {props.label}
    </div>
  </components.Option>
)

// eslint-disable-next-line react/display-name
const Select = forwardRef<typeof AsyncReactSelect, SelectProps>(
  ({ value, options = [], isMulti, onChange, onOptionChange, async, selectedOption, light, ...props }, ref) => {
    const selectWrapperRef = useRef<HTMLDivElement | null>(null)
    const [selected, setSelected] = useState<Option[]>([])
    const SelectComponent = (async ? AsyncReactSelect : ReactSelect) as unknown as FC<
      React.PropsWithChildren<AsyncReactSelectProps<Option, false>>
    >
    const memoizedOptions = useMemo(() => options, [options])
    const memoizedSelectedOption = useMemo(() => selectedOption, [selectedOption])
    const memoizedValue = useMemo(() => value, [value])

    useEffect(() => {
      //console.log(selectedOption, value, memoizedOptions, isMulti)
      // if selectedOption is provided, we opt out of automatic value setting
      if (memoizedSelectedOption || memoizedOptions.length === 0) {
        return
      }

      setSelected(
        memoizedOptions
          .flatMap((option: Option | Group) => ('options' in option ? option.options : option))
          .filter((option: Option) =>
            isMulti
              ? (memoizedValue as Value[]).some((value) => option.value === value)
              : option.value === memoizedValue,
          ),
      )
    }, [memoizedSelectedOption, memoizedValue, memoizedOptions, isMulti])

    const handleChange = useCallback(
      (option: Option | OptionsType<Option> | null) => {
        if (isMulti) {
          return (onChange as (values: Value[]) => void)?.(((option || []) as Option[]).map((option) => option.value))
        } else {
          return (onChange as (value: Value) => void)?.((option as Option)?.value ?? null)
        }
      },
      [isMulti, onChange],
    )

    const abstractComponents: SelectComponentsConfig<Option, false> = {
      ClearIndicator,
      DropdownIndicator,
      Menu,
      MultiValueRemove,
      NoOptionsMessage,
      Option: OptionWithImage,
      SingleValue,
      ...props.components,
    }

    // Default select configuration
    const filterConfig: Config = {
      ignoreAccents: true,
      ignoreCase: true,
      matchFrom: props.matchFromStart ? 'start' : 'any',
      trim: true,
    }

    const filterPerWord = (country: Option, rawInput: string) => {
      if (rawInput) {
        const countryLabel = country.label.trim().toLowerCase()
        const userInput = rawInput.trim().toLowerCase()
        const userInputList = userInput.split(' ')
        if (userInputList.length > 1) {
          return countryLabel.includes(userInput)
        }
        return countryLabel.split(' ').some((item) => item.startsWith(userInput))
      }
      return true
    }

    return props.shouldCreateConfig ? (
      <div ref={selectWrapperRef} data-cy={props.dataCy}>
        <SelectComponent
          ref={ref}
          menuPosition="absolute"
          menuPlacement="auto"
          placeholder={false}
          {...props}
          value={selectedOption ?? selected}
          options={options}
          isMulti={isMulti}
          onChange={(value, action) => {
            onOptionChange?.(value, action)
            handleChange(value)
          }}
          isDisabled={props.disabled}
          components={abstractComponents}
          isSearchable={props.isSearchable}
          filterOption={props.matchPerWord ? createFilter(filterConfig) : filterPerWord}
          string
          styles={
            customStyles(props.error || false, light || false, selected.length > 0) as Partial<
              Styles<Option, false, GroupTypeBase<Option>>
            >
          }
          {...(props.menuPosition === 'fixed' && {
            closeMenuOnScroll: (event) =>
              props.menuPosition === 'fixed' &&
              event.target !== selectWrapperRef.current &&
              !selectWrapperRef.current?.contains(event.target as Node),
          })}
        />
      </div>
    ) : (
      <div ref={selectWrapperRef} data-cy={props.dataCy}>
        <SelectComponent
          ref={ref}
          menuPosition="absolute"
          menuPlacement="auto"
          placeholder={false}
          {...props}
          value={selectedOption ?? selected}
          options={options}
          isMulti={isMulti}
          onChange={(value, action) => {
            onOptionChange?.(value, action)
            handleChange(value)
          }}
          isDisabled={props.disabled}
          components={abstractComponents}
          isSearchable={props.isSearchable}
          styles={
            customStyles(props.error || false, light || false, selected.length > 0) as Partial<
              Styles<Option, false, GroupTypeBase<Option>>
            >
          }
          {...(props.menuPosition === 'fixed' && {
            closeMenuOnScroll: (event) =>
              props.menuPosition === 'fixed' &&
              event.target !== selectWrapperRef.current &&
              !selectWrapperRef.current?.contains(event.target as Node),
          })}
        />
      </div>
    )
  },
)

const Menu: FunctionComponent<React.PropsWithChildren<MenuProps<Option, boolean>>> = (props) => {
  const hasLimit = props.isMulti && typeof props.selectProps.limit === 'number'
  const isLimitReached = hasLimit && props.getValue().length >= props.selectProps.limit

  return (
    <components.Menu {...props}>
      {isLimitReached ? <Message>You&apos;ve reached the limit of options.</Message> : props.children}
    </components.Menu>
  )
}

const DropdownIndicator: FunctionComponent<React.PropsWithChildren<IndicatorProps<Option, boolean>>> = (props) =>
  components.DropdownIndicator && (
    <components.DropdownIndicator {...props}>
      <Icon icon={props.selectProps.menuIsOpen ? Icons.chevronUp : Icons.chevronDown} />
    </components.DropdownIndicator>
  )

const ClearIndicator: FunctionComponent<React.PropsWithChildren<IndicatorProps<Option, boolean>>> = (props) =>
  components.ClearIndicator && (
    <components.ClearIndicator {...props}>
      <Icon icon={Icons.cross} />
    </components.ClearIndicator>
  )

const MultiValueRemove: FunctionComponent<React.PropsWithChildren<MultiValueRemoveProps<Option>>> = (props) =>
  components.MultiValueRemove && (
    <components.MultiValueRemove {...props}>
      <Icon size={rem(20)} icon={Icons.cross} />
    </components.MultiValueRemove>
  )

const NoOptionsMessage: FunctionComponent<React.PropsWithChildren<NoticeProps<Option, boolean>>> = (props) =>
  components.NoOptionsMessage && (
    <components.NoOptionsMessage {...props}>It looks like you&apos;re out of options</components.NoOptionsMessage>
  )

const SingleValue: FC<React.PropsWithChildren<SingleValueProps<Option>>> = (props) => {
  const [{ value, label }] = props.getValue()
  const group = props.options.find((opt: Option | Group) =>
    'options' in opt ? opt.options.some((child) => child.value === value) : null,
  )

  return (
    <components.SingleValue {...props}>
      {group ? `${group.label} - ` : ''}
      {props.data.image && <img src={props.data.image} style={StyledOptionImage} alt="" />}
      {label}
    </components.SingleValue>
  )
}

const Message = styled.div`
  color: ${BrandColors.white.alpha(Opacities.fiftyfive).toString()};
  text-align: center;
  font-size: ${rem(14)};
  padding: ${rem(8)};

  ${MediaQueries.desktop} {
    font-size: ${rem(16)};
  }
`

const getBorder = (error: boolean): string =>
  `1px solid ${error ? SystemColors.danger.toString() : BrandColors.white.alpha(Opacities.thirtyfive).toString()}`

const customStyles = (error: boolean, light: boolean, hasSelectedOption: boolean): Styles<Option, boolean> => ({
  clearIndicator: (defaultStyles, { isFocused }) => ({
    ...defaultStyles,
    ':active': {
      color: BrandColors.white.alpha(Opacities.fiftyfive).toString(),
    },

    ':hover': {
      color: BrandColors.white.alpha(Opacities.seventyfive).toString(),
    },

    color: isFocused
      ? BrandColors.white.alpha(Opacities.fiftyfive).toString()
      : BrandColors.white.alpha(Opacities.full).toString(),
  }),

  control: (defaultStyles, { isDisabled, isFocused }) => ({
    ...defaultStyles,
    ':active': {
      backgroundColor: BrandColors.white.alpha(Opacities.twelve).toString(),
    },
    ':hover': {
      backgroundColor: BrandColors.white.alpha(Opacities.eight).toString(),
      border: getBorder(error),
    },
    background: `${
      isFocused || light ? BrandColors.white.alpha(Opacities.eight).toString() : BrandColors.blackcurrant.toString()
    }`,
    border: '0 solid transparent',

    borderRadius: 0,
    boxShadow: 'none',
    color: BrandColors.white.toString(),
    cursor: isDisabled ? 'none' : 'pointer',
    fontFamily: FontFamily.regular,
    fontSize: rem(16),
    letterSpacing: rem(0),
    lineHeight: rem(24),
    opacity: isDisabled ? Opacities.thirtyfive : Opacities.full,
    outline: `1px solid ${isFocused ? 'transparent' : 'transparent'}`,
    outlineOffset: '2px',
    padding: rem(4),

    [MediaQueries.desktop]: {
      fontSize: rem(18),
      minHeight: rem(56),
    },

    transition: Transitions.micro,

    width: '100%',
  }),

  dropdownIndicator: (defaultStyles, { isFocused }) => ({
    ...defaultStyles,
    ':active': {
      color: BrandColors.white.alpha(Opacities.fiftyfive).toString(),
    },

    ':hover': {
      color: BrandColors.white.alpha(Opacities.seventyfive).toString(),
    },

    color: isFocused
      ? BrandColors.white.alpha(Opacities.fiftyfive).toString()
      : BrandColors.white.alpha(Opacities.full).toString(),
  }),

  indicatorSeparator: (defaultStyles, { isMulti }) => ({
    ...defaultStyles,
    backgroundColor: BrandColors.white.alpha(Opacities.twelve).toString(),
    display: !isMulti ? 'none' : 'flex',
  }),

  input: (defaultStyles) => ({
    ...defaultStyles,
    caretColor: hasSelectedOption ? 'transparent' : 'unset',
    color: BrandColors.white.toString(),
    padding: rem(8),
  }),

  menu: (defaultStyles) => ({
    ...defaultStyles,
    background: BrandColors.blackcurrant.toString(),
    border: getBorder(error),
    borderRadius: 0,
    transition: Transitions.micro,
  }),

  multiValue: (defaultStyles) => ({
    ...defaultStyles,
    alignContent: 'center',
    alignItems: 'center',
    backgroundColor: BrandColors.white.alpha(Opacities.twelve).toString(),
    border: `1px solid ${BrandColors.white.alpha(Opacities.twelve).toString()}`,
    borderRadius: 0,
    justifyContent: 'center',

    maxHeight: rem(32),
    [MediaQueries.desktop]: {
      maxHeight: rem(40),
    },
  }),

  multiValueLabel: (defaultStyles) => ({
    ...defaultStyles,
    color: BrandColors.white.toString(),
    fontSize: rem(14),
    paddingBottom: rem(6),
    paddingLeft: rem(8),
    paddingRight: rem(8),
    paddingTop: rem(6),
    [MediaQueries.desktop]: {
      fontSize: rem(16),
      paddingBottom: rem(8),
      paddingTop: rem(8),
    },
  }),

  multiValueRemove: (defaultStyles) => ({
    ...defaultStyles,
    ':active': {
      backgroundColor: BrandColors.white.alpha(Opacities.eight).toString(),
    },
    ':hover': {
      backgroundColor: BrandColors.white.alpha(Opacities.eight).toString(),
    },
    alignContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
    borderRadius: 0,
    color: BrandColors.white.toString(),
    height: rem(32),

    [MediaQueries.desktop]: { height: rem(40), width: rem(40) },

    justifyContent: 'center',

    width: rem(32),
  }),

  noOptionsMessage: (defaultStyles) => ({
    ...defaultStyles,
    fontSize: rem(14),
    padding: rem(4),

    [MediaQueries.desktop]: {
      fontSize: rem(16),
    },
  }),

  option: (defaultStyles, { isSelected, isFocused }) => ({
    ...defaultStyles,
    ':hover': {
      background: BrandColors.white.alpha(Opacities.eight).toString(),
    },
    background:
      isSelected || isFocused
        ? BrandColors.white.alpha(Opacities.eight).toString()
        : BrandColors.blackcurrant.toString(),
    color: BrandColors.white.toString(),
    cursor: 'pointer',

    [MediaQueries.desktop]: {
      fontSize: rem(16),
    },

    fontSize: rem(14),
  }),

  placeholder: (defaultStyles) => ({
    ...defaultStyles,
    color: BrandColors.white.alpha(Opacities.fiftyfive).toString(),
    padding: rem(8),
  }),

  singleValue: (defaultStyles) => ({
    ...defaultStyles,
    color: BrandColors.white.toString(),
    fontSize: rem(14),
    paddingLeft: rem(8),

    [MediaQueries.desktop]: {
      fontSize: rem(16),
    },
  }),

  valueContainer: (defaultStyles) => ({
    ...defaultStyles,
    padding: 0,
  }),
})

const StyledOptionImage = {
  borderRadius: '50%',
  height: rem(40),
  marginRight: rem(12),
  objectFit: 'cover',
  verticalAlign: 'middle',
  width: rem(40),
} as const

export default Select
