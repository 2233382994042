import { BrandColors, Divider as BaseDivider, List, Opacities, Padding, rem } from 'ledo-react'
import React, { FunctionComponent, ReactChild } from 'react'
import styled from 'styled-components'

export interface NavigationListProps {
  featured?: boolean
  padding?: number
  separator?: ReactChild
}

const NavigationList: FunctionComponent<React.PropsWithChildren<NavigationListProps>> = ({
  children,
  separator = (
    <Padding vertical={rem(12)}>
      <Divider />
    </Padding>
  ),
  padding = 12,
  // The `featured` prop is not used in this component, but is used by a styled-component
  // that applies conditional styling based on the prop. We don't want it in our <div>,
  // so it's removed from the props object.
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  featured,
  ...props
}) => (
  <div {...props}>
    <Divider />
    <Padding vertical={rem(padding)}>
      <List separator={separator}>{children}</List>
    </Padding>
  </div>
)

export default NavigationList

const Divider = styled(BaseDivider)`
  background: ${BrandColors.white.alpha(Opacities.twenty).toString()};
`
