import React, { type FunctionComponent } from 'react'
import ReactSlider from 'react-slider'
import styled from 'styled-components'

import { BrandColors } from '../../tokens/colors'

export interface Props {
  maxValue: number
  minDistance?: number
  minValue?: number
  setFieldValue?: (valueFrom?: string, valueTo?: string) => void
  type?: string
  value?: [number, number]
}

const RangeSlider: FunctionComponent<Props> = ({
  maxValue,
  minValue = 0,
  minDistance = 15,
  value,
  setFieldValue,
  type,
}) => (
  <Wrapper>
    <StyledSlider
      value={value}
      min={minValue}
      max={maxValue}
      ariaLabel={['Lower thumb', 'Upper thumb']}
      ariaValuetext={(state) => `Thumb value ${state.valueNow}`}
      renderThumb={(props, state) => {
        const { children, as, ...rest } = props
        return (
          <>
            <Label className={`thumb-${state.index}`}>
              {state.valueNow}
              {type}
              {state.index === 1 && state.valueNow === maxValue && '+'}
            </Label>
            <SliderThumb {...rest} />
          </>
        )
      }}
      onChange={(value) => {
        if (Array.isArray(value)) {
          const low = value[0]
          const high = value[1]

          if (setFieldValue) {
            setFieldValue(low.toString(), high.toString())
          }
        }
      }}
      renderTrack={(props) => {
        const { children, as, ...rest } = props
        return <SliderTrack {...rest} />
      }}
      pearling
      minDistance={minDistance}
    />
  </Wrapper>
)

export default RangeSlider

const SliderThumb = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  background: ${BrandColors.springGreen.toString()};
  border-radius: 50%;
  cursor: grab;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;

  &.thumb-1 {
    border-radius: 0px;
  }

  &:focus-visible {
    outline: -webkit-focus-ring-color auto 0px;
  }
`

const StyledSlider = styled(ReactSlider)`
  width: 100%;
  height: 3px;
  display: flex;
  background: ${BrandColors.springGreen.toString()};
  align-items: center;
  margin-left: -1rem;
`

const SliderTrack = styled.div`
  background: ${BrandColors.springGreen.toString()};
  top: 0;
  bottom: 0;
  padding-left: 1rem;

  &:first-of-type {
    margin-left: -1rem;
    background-color: #0b5842;
  }
  &.track-2 {
    background-color: #0b5842;
  }
`

const Label = styled.span`
  margin-top: -4rem;
  font-weight: 500;
  position: absolute;

  &.thumb-0 {
    left: -0.8rem;
  }

  &.thumb-1 {
    right: 0;
  }
`

const Wrapper = styled.div`
  margin-left: 2rem;
  margin-top: 1rem;
  padding-top: 2rem;
`
