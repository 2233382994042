/**
 *
 * TODO: Mostly copied from ledo-web - should be re-used via @ledo-react
 *
 */

import { Redirect, type RouteComponentProps } from '@gatsbyjs/reach-router'
import { Body, rem, SizedBox, Title } from 'ledo-react'
import React, { type FC, type FunctionComponent } from 'react'
import { Helmet } from 'react-helmet'

import Grid, { GridItem } from '../../components/grid/Grid'
import Page from '../../components/page/Page'
import { useUser } from '../../hooks/useUser'
import { inBrowser } from '../../utils/inBrowser'
import { routes } from '../../utils/routes'
import Container from '../components/Container'
import InformationHero from '../components/InformationHero'
import Spacer from '../components/Spacer'
import TopBarSpacer from '../components/TopBarSpacer'

export const GeneralTermsCopy: FC<React.PropsWithChildren<unknown>> = () => (
  <>
    <Title as="h2" size="xxSmall">
      INTRODUCTION
    </Title>
    <SizedBox height={rem(8)} />
    <Body>
      Hello, welcome! Thank you for using Collabhouse.
      <br />
      Collabhouse is a platform offering various services that users may choose from provided to you by Collabhouse
      B.V., a Dutch company with its office at Koninginneweg 9 in Hilversum, The Netherlands and Chamber of Commerce
      registration number 76143120.
    </Body>
    <SizedBox height={rem(32)} />
    <Body>
      Please read the following general General Terms and Conditions (&ldquo;General Terms and Conditions&rdquo;)
      carefully as these, together with our privacy policy (&ldquo;Privacy Policy&rdquo;) and any relevant supplemental
      terms that may apply to your interaction with each specific Service apply to your access to Collabhouse&rsquo;s
      platform, services, websites and software applications. Throughout these General Terms and Conditions,
      &ldquo;us&rdquo; &ldquo;our&rdquo;, &ldquo;we&rdquo; and &ldquo;Collabhouse&rdquo; refers to Collabhouse B.V. and
      &ldquo;you&rdquo; or &ldquo;your&rdquo; refers to you.
    </Body>
    <SizedBox height={rem(32)} />
    <Title as="h2" size="xxSmall">
      1. DEFINITIONS
    </Title>
    <SizedBox height={rem(8)} />
    <Body>
      1.1. &ldquo;Collabhouse Documentation&rdquo; means any and all requirements, guidelines and/or specifications
      provided by us in connection with the Services.
      <br />
      1.2. &ldquo;Collaboration(s)&rdquo; means any and all influencer marketing campaigns entered into between Profiles
      using the Collab Market.
      <br />
      1.3. &ldquo;Collab Market&rdquo; means the online influencer marketing marketplace and matchmaking tool operated
      by us that can be used to search, propose, negotiate and enter into influencer marketing campaigns with other
      Profiles to promote Music Content.
      <br />
      1.4. &ldquo;Collab Royalty Split&rdquo; means the percentage of Distribution Royalties that is allocated to a
      particular Profile as part of a Collaboration.
      <br />
      1.5. &ldquo;Composition(s)&rdquo; means any and all lyrical and/or musical compositions as embodied in Master
      Recording(s).
      <br />
      1.6. &ldquo;Creator(s)&rdquo; means any or all users of the Services with a User Account.
      <br />
      1.7. &ldquo;Distribution Model&rdquo; has the meaning as described in section 6B.2.
      <br />
      1.8. &ldquo;Distribution Period&rdquo; means the period starting on the day you submit Your Master Recording(s) as
      part of a release to the Distribution Service and ending the moment as indicated when you selected your preferred
      Distribution Model for the relevant release.
      <br />
      1.9. &ldquo;Distribution Royalties&rdquo; means the royalties actually received by us in connection with the
      exploitation of Your Master Recording(s) through the Distribution Service after deduction of our commission (if
      any) depending on the Distribution Model selected by you for the relevant release, less VAT or any similar taxes,
      third parties&rsquo; commissions and costs as well as any payment, currency exchange and banking fees charged by
      the pay-out service and method.
      <br />
      1.10. &ldquo;Distribution Service&rdquo; means the digital music distribution service operated by us through which
      you can distribute and exploit Your Master Recording(s) to and on Music Services.
      <br />
      1.11. &ldquo;Master Recording(s)&rdquo; refers to audio only master recording(s).
      <br />
      1.12. &ldquo;Metadata&rdquo; means any information, including but not limited to artists&rsquo; names, publisher,
      composers, authors and performers information, track title, track length, genre information and ISRC and UPC
      codes, you are requested to provide relating to Your Master Recording(s) during the submission process.
      <br />
      1.13. &ldquo;Music Content&rdquo; means any and all Master Recordings including underlying Compositions as
      uploaded onto and made available on the Services by Creators.
      <br />
      1.14. &ldquo;Music Maker&rdquo; means any Creator and/or their Profile(s) that uploaded, made available and/or
      claimed and licensed any Music Content to Collabhouse.
      <br />
      1.15. &ldquo;Music Maker Services&rdquo; has the meaning as described in section 6A.1.
      <br />
      1.16. &ldquo;Music Library&rdquo; means the online marketplace operated by us through which you can offer, sell,
      obtain and buy free and/or paid worldwide non-exclusive synchronization licenses to Music Content.
      <br />
      1.17. &ldquo;Music Library Content&rdquo; means any Music Content available for sync licensing in the Music
      Library.
      <br />
      1.18. &ldquo;Music Library Royalties&rdquo; means all Recording Royalties and Publishing Royalties collected by us
      in connection with granting synchronization licenses to (Your) Music Content via the Music Library.
      <br />
      1.19. &ldquo;Music Library Settings&rdquo; has the meaning as described in section 6E.2.
      <br />
      1.20. &ldquo;Music Services&rdquo; means the third party B2C and B2B digital music-, lifestyle-, social media-
      and/or entertainment platforms in Collabhouse&rsquo;s Distribution Services&rsquo; network, including but not
      limited to streaming services, music download portals, social media platforms, mobile music and video platforms,
      digital (and terrestrial) radio stations, digital (and terrestrial) television, mobile networks, music metadata
      processing companies, audio fingerprinting and monitoring companies and companies otherwise exploiting music and
      videos as part of their services.
      <br />
      1.21. &ldquo;NFT Market&rdquo; means the online marketplace operated by us that can be used to sell and buy
      Royalty Split NFTs.
      <br />
      1.22. &ldquo;NFT Owner&rdquo; means a person or entity that owns and/or controls a crypto wallet that contains a
      Royalty Split NFT.
      <br />
      1.23. &ldquo;NFT Sales Royalties&rdquo; means all Recording Royalties collected by us in connection with the sale
      of Royalty Split NFTs to (Your) Master Recording(s) via the NFT Market.
      <br />
      1.24. &ldquo;NFT Royalty Split&rdquo; means the percentage of Distribution Royalties that is included in and
      attached to a particular Royalty Split NFT.
      <br />
      1.25. &ldquo;Other Materials&rdquo; means any promotional or other materials that you upload or otherwise provide
      to us in relation to Your Master Recording(s), including but not limited to any audio and audio-visual clips,
      artwork, image files, photographs, biographies and likenesses, as well as any trademarks and logos.
      <br />
      1.26. &ldquo;Profile(s)&rdquo; means any and all of the public profiles created by Creators on the Services.
      <br />
      1.27. &ldquo;Publishing Clearance Request&rdquo; has the meaning as described in section 6E.3.
      <br />
      1.28. &ldquo;Publishing Rights&rdquo; means any and all of the rights in Composition(s).
      <br />
      1.29. &ldquo;Publishing Rights Holder(s)&rdquo; is an individual or entity that owns and/or control a Publishing
      Right, but not limited to, any writer, composer, lyricist, or a music publisher.
      <br />
      1.30. &ldquo;Publishing Royalties&rdquo; means all royalties earned through the Music Library in connection with
      the use and exploitation of Publishing Rights.
      <br />
      1.31. &ldquo;Publishing Royalty Split(s)&rdquo; means the percentage of Publishing Royalties allocated to a
      certain Profile.
      <br />
      1.32. &ldquo;Recording Royalties&rdquo; means all royalties earned through the Music Maker Services in connection
      with the use and exploitation of Master Recordings.
      <br />
      1.33. &ldquo;Recording Royalty Split(s)&rdquo; has the meaning as described in section 7.3.
      <br />
      1.34. &ldquo;Royalty Split NFT&rdquo; has the meaning as described in section 6D.1.
      <br />
      1.35. &ldquo;Services&rdquo; means the Collabhouse platform together with all services, websites and software
      applications offered by Collabhouse, including but not limited to the Music Maker Services, Music Library, NFT
      Market and the Collab Market.
      <br />
      1.36. &ldquo;Team Members&rdquo; means those User Accounts, including Your User Account, that have access and are
      team members of a certain Profile.
      <br />
      1.37. &ldquo;Team Member Royalty Split(s)&rdquo; has the meaning as described in section 7.5.
      <br />
      1.38. &ldquo;Term&rdquo; means the period commencing on the date you first access and/or use the Services and
      continuing thereafter until terminated by you or by us.
      <br />
      1.39. &ldquo;User Account&rdquo; means your Collabhouse user account you registered and use to access the
      Services.
      <br />
      1.40. &ldquo;User Content&rdquo; means all text, messages, offers, images, artwork, photographs and other content,
      information, and materials uploaded by (a) Creator(s) directly to and on the Services, or otherwise provided to
      Collabhouse by Creators in connection with the Services, excluding any Music Content.
      <br />
      1.41. &ldquo;Your Master Recording(s)&rdquo; means any and all Master Recording(s) owned and/or controlled and
      uploaded by you and/or Your profile onto the Music Maker Services.
      <br />
      1.42. &ldquo;Your Music Content&rdquo; means Your Master Recording(s) together with related Metadata and Other
      Materials and/or any and all Your Publishing Rights.
      <br />
      1.43. &ldquo;Your Publishing Rights&rdquo; means any and all Publishing Rights owned and/or controlled by you
      and/or Your Profile in Compositions embodied in Music Content that you and/or Your Profile have claimed and
      licensed to us via accepting Publishing Clearance Request(s) for such Music Content.
      <br />
      1.44. &ldquo;Your Profile(s)&rdquo; means the Profile(s) you are a Team Member of.
      <br />
      1.45. &ldquo;Your User Content&rdquo; means any User Content uploaded or otherwise provided by you or via one of
      Your Profiles, excluding Your Music Content.
    </Body>
    <SizedBox height={rem(32)} />
    <Title as="h2" size="xxSmall">
      2. ACCEPTANCE OF AND CHANGES TO GENERAL TERMS AND CONDITIONS
    </Title>
    <SizedBox height={rem(8)} />
    <Body>
      2.1. By accessing the Services, you acknowledge that you have read, understood and accepted these General Terms
      and Conditions and agree to be bound by them.
      <br />
      2.2. To access and use the Services, you need to be at least eighteen (18) years old and have the power to enter a
      binding contract with us.
      <br />
      2.3. If you use or access the Services as an authorized representative for an artist, group or band or of a
      company, organization, or other entity, then &quot;you&quot; includes you and that artist, group, band, company,
      organization or other entity, and you represent and warrant that you are authorized to grant all permissions and
      licenses provided in these General Terms and Conditions and bind that artist, group, band, company, organization
      or other entity to these General Terms and Conditions, and that you agree to these General Terms and Conditions on
      that artist, group, band, company, organization or other entity&rsquo;s behalf. In case you represent any artist,
      band, group or company you shall be bound to the applicable local civil laws of legal representation, in which
      case you indemnify us against any claims or damages arising out of any conflict with the persons or parties you
      represent(ed).
      <br />
      2.4. We reserve the right, at our sole discretion, to change these General Terms and Conditions or change,
      suspend, discontinue or otherwise modify the Services at any time. For every material change in the General Terms
      and Conditions, we shall notify you upfront by displaying a message within the Services or by sending an email to
      your registered email address and you shall have the option to accept the new General Terms and Conditions or
      discontinue your usage of the Services. By continuing to use or access the Services after we make any changes, you
      agree to be legally bound by the revised General Terms and Conditions. You are not required to continue to use the
      Services after such notice, but if you do not terminate Your User Account in the manner and within the period
      specified in the notice and you continue to access or use the Services after the end of said period, you are
      deemed to have accepted the new General Terms and Conditions.
    </Body>
    <SizedBox height={rem(32)} />
    <Title as="h2" size="xxSmall">
      3. ACCESS TO THE SERVICES
    </Title>
    <SizedBox height={rem(8)} />
    <Body>
      3.1. Subject to these General Terms and Conditions, we grant you a limited, personal, non-exclusive, revocable and
      non-transferable right and license to: i) access and use the Services and to access, view and use any User Content
      made available and provided by other Creators thereon; ii) upload, store, share, publish, make available and post
      Your User Content through and on the Services, and share Your User Content with other Creators via the Services;
      iii) access and browse the Music Library, stream Music Library Content and, subject to accepting the Music Library
      License Agreement, download and obtain synchronization licenses to Music Library Content; iv) access and browse
      the NFT Market, stream Music Content related to NFTs available in the NFT Market and, subject to accepting the NFT
      Buyer Terms, buy NFTs and claim NFT Royalties on NFTs you own; v) access, use and participate in the Collab Market
      to search, propose, negotiate and enter into Collaborations with other Profiles to promote (Your) Music Content;
      vi) upload, make available and digitally distribute Your Master Recordings to Music Services via the Distribution
      Service; and/or vii) upload, offer and grant, subject to approval of all (other) Publishing Royalty Split Owners,
      (free or paid) synchronization licenses to Your Music Content to Creators via the Music Library ; and/or viii)
      sell a percentage of your Master Recording(s)&rsquo;s Distribution Royalties as Royalty Split NFTs via the NFT
      Market; ix) receive, collect and license Publishing Royalty Splits and receive, approve and reject Publishing
      Clearance Requests in relation to Music Content into which you and/or Your Profile controls and/or owns any
      Publishing Rights submitted by other Creators for inclusion in the Music Library; and allocate and receive Royalty
      Splits from other Profiles, your own Profile(s) and your Collaborator(s), in connection with the exploitation of
      (Your) Music Content through the Services.
      <br />
      3.2. To access the Services, you must first create your own personal User Account by providing certain information
      about yourself as prompted by the User Account registration form. Your User Account is private and not visible to
      any other Creators and Profiles. To be able to use most of the Services, you must create at least one Profile by
      providing the information as prompted during the Profile creation process. You can create multiple Profiles, one
      for each band, group, label, company or brand you wish to represent on Collabhouse.
      <br />
      3.3. You can invite others to have access to and join Your Profile as Team Members. Anyone you invite to join Your
      Profile as Team Member has to register and create a User Account of its own. You can also be invited to join other
      Profile(s) as Team Member. All Profile Team Members have the same level of authorization with regards to the
      Profile as well as any Music Content associated with such Profile, together with the Royalty Split, distribution,
      Music Library, NFT Market, Collab settings and/or Collaborations associated with such Music Content.
      <br />
      3.4. Any information provided through and on your User Account and Your Profile(s) must be true, accurate and
      complete at all times. If we find any of Your User Content or any other information provided on or through your
      User Account or Your Profile(s) to be incorrect, false or misleading, we reserve the right, in our sole
      discretion, to remove it. You are responsible for maintaining the confidentiality of your username and password of
      your User Account and you are solely responsible and liable for any usage or activity on your User Account and
      Your Profile(s). Your User Account may be used solely by you and may not be transferred or shared with any third-
      party. We are not liable for any loss or damage arising from unauthorized access to your User Account and/or Your
      Profile(s) or your failure to comply with the requirements set out herein, unless such loss or damage would be
      proven to be caused by our intent or gross negligence.
      <br />
      3.5. You can request removal of Your User Content, Your User Account and/or Your Profile(s) by sending an email to
      help@collabhouse.com.
      <br />
      3.6. We will use best efforts to provide you a good experience when using the Services, but the Services are in
      beta and are made available to you for the purpose of testing and collecting feedback from early users (including
      you) to optimize the functionalities and user experience of the Services. We cannot guarantee that the
      functionalities of the Services and your user experience are optimal.
    </Body>
    <SizedBox height={rem(32)} />
    <Title as="h2" size="xxSmall">
      4. GRANT OF LICENSE IN YOUR USER CONTENT
    </Title>
    <SizedBox height={rem(8)} />
    <Body>
      4.1. All Your User Content that you upload, store, distribute, publish, share, or otherwise make available on the
      Services must have been created by you and shall be your sole and exclusive (intellectual) property or provided by
      you with the express consents, permissions or licenses necessary from the original owner(s) or rightsholder(s) of
      such materials for use by you and us pursuant to these General Terms and Conditions. You may not upload,
      distribute, publish, share or otherwise make available any content (including Your User Content) on the Services
      without having all of the necessary rights to do so. Any unauthorized use of copyrighted or proprietary material
      in Your User Content is strictly prohibited. You are solely liable for any infringement on any right of a third
      party in or as any part of Your User Content that you upload on the Services and you agree to fully indemnify us
      against any and all costs (including legal fees and costs), claims and damages of any such third party at all
      times.
      <br />
      4.2. You acknowledge and agree that all Your User Content is and remains to be your sole responsibility and that
      we are only acting as a passive conduit for your online distribution, storage, publication and/or other
      exploitation of Your User Content. It is your sole responsibility to ensure that Your User Content complies with
      these General Terms and Conditions, any Collabhouse Documentation as well as any (copyright) law at all times.
      <br />
      4.3. By uploading, distributing, publishing or otherwise making available Your User Content to and on the Services
      you automatically grant, and you represent and warrant that you have the right to grant, to us in the context of
      us providing the Services to you and other Creators a non-exclusive, transferable, fully paid, worldwide license
      to store, host, use, copy, reproduce, publicly perform, publicly display, communicate to the public, stream, make
      available, reformat, excerpt (in whole or in part), transmit, re-post and distribute Your User Content for any
      purpose on or in connection with the Services according to these General Terms and Conditions. The licenses
      granted are granted separately for each item of Your User Content uploaded, stored, distributed, published,
      displayed or otherwise made available by you on and to the Services and will automatically terminate when we, in
      our own discretion or upon your request, delete such item of Your User Content from your User Account and/or Your
      Profile(s) or if we, in our own discretion, terminate your User Account and/or Your Profile(s).
    </Body>
    <SizedBox height={rem(32)} />
    <Title as="h2" size="xxSmall">
      5. COLLAB MARKET &amp; COLLABORATIONS
    </Title>
    <SizedBox height={rem(8)} />
    <Body>
      5.1. You can use the Collab Market to search, connect with, propose, negotiate and enter into Collaborations with
      other Profiles. Collaborations can consist of: i) Your Profile agreeing to promote Music Content of your
      Collaborator by including it in one or more of Your Profile&rsquo;s social media posts in exchange for your
      Collaborator: a) paying a flat fee; or b) allocating to Your Profile a Royalty Split of the (recording) royalties
      earned in connection with the distribution of the item of Music Content Your Profile is promoting via the
      Distribution Service; and/or ii) your Collaborator agreeing to promote Your Music Content by including it in one
      or more social media posts on your Collaborator&rsquo;s social media channels in exchange for you or Your Profile:
      a) paying your Collaborator a flat fee; or b) allocating to your Collaborator&rsquo;s Profile a Royalty Split of
      the (recording) royalties earned in connection with the distribution of the item of Your Music Content your
      Collaborator is promoting via the Distribution Service. For the avoidance of doubt, only Your Music Content
      actually distributed through the Distribution Service and/or available in the Music Library can be included in a
      Collaboration.
      <br />
      5.2. You can activate Your Profile&rsquo;s participation in the Collab Market by providing your Profile&rsquo;s
      &ldquo;Collab Settings&rdquo;.
      <br />
      5.3. Collaboration offers and counteroffers send or received through the Collab Market are not binding until
      accepted by both Collaborators. On all offers and agreements between Collaborators the applicable (local) laws
      between such parties are applicable. We are not a party to any such offer, contract or agreement, but merely a
      provider of the services as part of Collab Market to both parties. You must resolve any disputes with your
      Collaborator(s) yourself. You hereby indemnify us against any and all claims, costs and damages arising from any
      dispute between you and a Collaborator.
      <br />
      5.5. As soon as a Collaboration has been accepted by both Collaborators, your Collaborator agreeing to promote
      (Your) Music Content is granted the following rights for the sole purpose of fulfilling the agreed Collaboration:
      i) the right to permanently download a copy of the agreed item of (Your) Music Content from the Services, and ii)
      a non-exclusive, perpetual, worldwide license to use and synchronize the agreed item of (Your) Music Content (or a
      part thereof) in the agreed number of audio-visual social media posts and share, post and use those social media
      post(s) on the agreed social media channel(s).
      <br />
      5.6. The agreed flat fee payment or Collab Royalty Split allocation for a Collaboration will only become due after
      delivery of the agreed social media post(s).
      <br />
      5.7. You agree to fulfil any and all of the obligations and other commitments Your Profile agreed to as part of a
      Collaboration in a timely and diligent manner at all times.
      <br />
      5.8. We do not charge a commission on any transactions taking place between Collaborators.
    </Body>
    <SizedBox height={rem(32)} />
    <Title as="h2" size="xxSmall">
      6. SERVICES FOR MUSIC MAKERS
    </Title>
    <SizedBox height={rem(16)} />
    <Body>
      A. General
      <br />
      6A.1. Collabhouse provides the several music marketing, distribution and sync licensing services for Music Makers
      in relation to Music Content they own and/or control on a worldwide basis, including but not limited to
      Distribution Service, the Music Library, the Collab Market and the NFT Market (the &ldquo;Music Maker
      Services&rdquo;).
      <br />
      6A.2. To upload and include any of Your Master Recording(s) in the Music Maker Services, you must own and/or
      control all recording rights in Your Master Recording(s) worldwide and on an exclusive basis. You warrant and
      guarantee that you will only upload, make available Your Master Recording(s) to and through the Music Maker
      Services if you own and/or control and/or administer any and all rights into the sound recording(s) worldwide on
      an exclusive basis. The warranties of the General Terms and Conditions applicable between you and us apply on Your
      Master Recording delivery to us in which it is agreed you will be fully liable for any damages and claims of third
      parties and you shall fully indemnify Collabhouse against any of such claims.
      <br />
      6A.3. Your Master Recordings must be provided as sound recordings in a single-track, EP or album configuration in
      the format together with the artwork, Metadata and any Other Materials as requested during the submission process.
      <br />
      6A.4. At your request, we can allocate the required ISRC and EAN or UPC codes for each of Your Master
      Recording(s). You can also bring and use your own ISRC and EAN or UPC codes. We retain ownership over any and all
      ISRC, EAN or UPC codes allocated by us.
      <br />
      6A.5. To be eligible to participate, include, license and/or claim any Publishing Rights as included in Music
      Content designated for inclusion in the Music Library, you must own and/or control Your Publishing Rights
      worldwide and on an exclusive basis. You warrant and guarantee that you will only include, license, claim any
      Publishing Rights and/or approve any Publishing Clearance Requests in relation to Music Content if, and only to
      the extent to which, you own and/or control any Publishing Rights in such Music Content on a worldwide and
      exclusive basis. The warranties of the General Terms and Conditions applicable between you and Collabhouse in
      relation to Your Publishing Rights apply from the moment you approve a Publishing Clearance Request for a
      particular item of Music Content in which it is agreed you will be fully liable for any damages and claims of
      third parties and you shall fully indemnify Collabhouse against any of such claims.
      <br />
      6A.6 All Your Music Content must be cleared for worldwide usage and you cannot exclude certain territories from
      distribution, licensing and exploitation.
      <br />
      6A.7. You hereby expressly agree that we shall have the right to provide information relative to the performance
      of Your Music Content on Collabhouse hereunder to third parties, to aggregate such information in charts and other
      comparative informational materials, and to disseminate the same in any manner. We may use third party contractors
      to operate or facilitate all or any part of the Music Maker Services.
    </Body>
    <SizedBox height={rem(16)} />
    <Body>
      B. Distribution Service
      <br />
      6B.1. You can select on a release-by-release basis which of Your Master Recording(s) you wish to submit for
      digital distribution through the Distribution Service.
      <br />
      6B.2. For each release you submit to the Distribution Service, you must select your preferred distribution model
      (&ldquo;Distribution Model&rdquo;) from the options available on our website at the time of submission.
      <br />
      6B.3. Your Master Recording(s) are distributed to Music Services on a worldwide basis and certain Music Services
      or territories cannot be excluded.
      <br />
      6B.4. For each of Your Master Recording(s) you submit to the Distribution Service you grant to us, on an
      exclusive, worldwide and sublicensable basis, any and all rights necessary for us to distribute and exploit Your
      Master Recording(s) on the Music Services during the Distribution Period, including without limitation, the right
      to and to authorize Music Services and/or third parties to: i) create digital master files and reproduce, convert,
      adapt, encode and transcribe Your Master Recording(s) and to edit and/or adapt Metadata and Other Materials where
      necessary; ii) ingest and deliver Your Master Recording(s) and store it in digital form on storage servers; iii)
      supply, stream, perform, communicate to the public, make available, display, synchronize, distribute, reproduce,
      compile, create and otherwise exploit Your Master Recording(s) on or in connection with the Music Services and/or
      their users, including the right to promote and advertise Your Master Recording(s) on the Services, and to and on
      Music Services and their (potential) users; iv) allow, create and exploit derivative works using Your Master
      Recording(s); v) create, reproduce, publicly perform and make available preview clips of Your Master Recording(s);
      vi) receive and distribute payments and royalties in relation to any and all income related to the exploitation,
      performance and/or other usages of Your Master Recording(s) on Music Services; vii) create specific ID files and
      unique identifiers, including through fingerprinting, to identify Your Master Recording(s) on Music Services
      and/or on our or Music Services&rsquo; servers; and viii) receive and collect royalties on your behalf, pay out
      royalties due to you and provide accounting statements to you in connection with the distribution, exploitation
      and licensing of Your Master Recording(s) through the Distribution Service. The rights granted by you to us in the
      foregoing are granted on an exclusive basis only with respect to Your Master Recording(s) you designated for
      distribution via the Distribution Service and only in relation to the Music Services in our network.
      <br />
      6B.5. You also grant to us a non-exclusive, royalty-free license to use and to authorize Music Services to use any
      Other Materials and Metadata in advertising, marketing and promotional materials during the Distribution Period
      worldwide.
      <br />
      6B.6. You cannot takedown or remove Your Master Recording(s) from the Distribution Service during the Distribution
      Period. However, only in the event we are summoned in writing by the rights holder of any item belonging to Your
      Master Recording(s), that has a legitimate claim that you have infringed upon its rights, to have us delete that
      particular part of Your Master Recording(s), Your Master Recording(s) can be taken down from the Distribution
      Service during the Distribution Period. You will not be able to grant any third party any digital distribution
      rights during the Distribution Period - by way of a transfer or a license of rights - to any part of Your Master
      Recordings you have submitted to the Distribution Service. We shall at all times continue the Distribution Service
      with Your Master Recording(s) even if such third- party claims to be your licensee or new rights holder, as any
      such license- or transfer agreement should respect your earlier agreement with us and license to us. Should you
      license or transfer Your Master Recording(s) to any third party, without mentioning our existing distribution
      rights, you will be in breach of your agreement with us and possibly with such third party. A takedown request can
      be sent to: legal@collabhouse.com. For the avoidance of doubt, removing Your Master Recording(s) from the
      Distribution Service during the Distribution Period constitutes a material breach by you of these General Terms
      and Conditions and you are liable for all of our costs and damages in this respect.
      <br />
      6B.7. You understand that, each specific Music Services has the right to select in their own discretion if, when
      and to what extent they exploit and make available Your Master Recording(s) to their users. We do not guarantee or
      control that Your Master Recording(s) will be accepted or exploited by the Music Services or taken down
      immediately, or if and/or how long it will take for Music Services to process, ingest and make Your Master
      Recording(s) available to their users. We are not responsible for what Music Services do with or how they offer
      Your Master Recording(s), and you agree to accept and have Your Master Recording(s) made available by Music
      Services to their users in accordance with each Music Service&rsquo;s pricing structure and other practices and
      policies from time to time, and in accordance with their agreement with Collabhouse.
      <br />
      6B.8. In relation to Your Master Recordings(s) distributed via the Distribution Service, you covenant not to sue
      any user of any social media or user generated content platform who usages and/or synchronizes Your Master
      Recording(s) in a content uploaded to the social media or user generated content platform&rsquo;s website, to the
      extent your claim is based on the alleged infringement of rights granted by you to us herein.
    </Body>
    <SizedBox height={rem(16)} />
    <Body>
      C. Collab Market
      <br />
      6C.1. You can use the Collab Market to search, propose, commission, negotiate and enter into Collaborations with
      other Profiles to obtain extra promotion for Your Master Recording(s) through your Collaborator&rsquo;s social
      media channels as further described in section 5 herein.
      <br />
      6C.2. When you enter into a Collaboration in connection with one of Your Master Recording(s), you grant to us; i)
      the right to provide your Collaborator with a permanent download of Your Master Recording(s) for the sole purpose
      of fulfilling the agreed Collaboration; and ii) the right to grant your Collaborator a non- exclusive, perpetual,
      worldwide license to use and synchronize Your Master Recording(s)(or any part thereof) in the agreed number of
      social media posts and share, post, publish and use those social medial posts on the agreed Collaborator&rsquo;s
      social media channel(s). For the avoidance of doubt, any such agreement between you and your Collaborator is
      solely governed by such agreement among these parties to which Collabhouse shall not be a contractual party. You
      shall indemnify Collabhouse at all times for any claims, damages or costs arising out of any dispute between you
      and your Collaborator(s).
    </Body>
    <SizedBox height={rem(16)} />
    <Body>
      D. Selling Royalty Split NFTs
      <br />
      6D.1. For any of Your Master Recording(s) distributed via the Distribution Service, you have the option to create
      and sell a certain percentage of Your Master Recording(s)&rsquo;s (future) Distribution Royalties in the form of a
      non-fungible token (&ldquo;Royalty Split NFT&rdquo;) via the NFT Market.
      <br />
      6D.2. When creating Royalty Split NFTs, you can select on which of Your Master Recording(s) you want to create
      these, together with the percentage of Distribution Royalties you want to include, how many NFTs you want to
      include in your drop together with the sales price of each NFT. You can sell up to fifty percent (50%) of Your
      Master Recording(s)&rsquo;s Distribution Royalties as Royalty Split NFTs.
      <br />
      6D.3. By creating Royalty Split NFTs on Your Master Recording(s), you agree to extent the Distribution Period for
      all Your Master Recording(s) included on that particular release with another five (5) years and the Distribution
      Model for the relevant release will be changed into the Commission Distribution Model for the remainder of the
      Distribution Period or as otherwise indicated on the Services at the time of creating your Royalty Split NFT.
      <br />
      6D.4. From the moment you create a Royalty Split NFT drop on one of Your Master Recording(s), we will deduct the
      Recording Royalty Split included from future track Distribution Royalties collected and accumulate and hold these
      royalties in reserve until claimed by the (future) NFT owner(s). As soon as a Royalty Split NFT has been sold via
      the NFT Market, the NFT owner is entitled to claim and collect the Recording Royalty Split bought as part of the
      Royalty Split NFT directly at Collabhouse for as long as the relevant track is generating Distribution Royalties.
      <br />
      6D.5. Creating Royalty Split NFTs is permanent. Once Royalty Split NFTs have been created, they cannot be undone.
      However, you can still change the sales price of your unsold Royalty Split NFTs available in the NFT Market.
      <br />
      6D.6. We charge a commission of twenty-five percent (25%) on all revenue we collect in connection with the sale of
      Royalty Split NFTs via the NFT Market.
    </Body>
    <SizedBox height={rem(16)} />
    <Body>
      E. Including Your Music Content in the Music Library
      <br />
      6E.1. Subject to approval of all other Publishing Split Owner(s) (if any), you have the option to include Your
      Master Recording(s), together with any underlying Publishing Rights controlled and/or owned by you therein, in our
      Music Library to allow other Profiles to: i) browse, discover and stream Your Music Content via the Music Library;
      and ii) subject to accepting the Music Library License Agreement to: (a) obtain via the Music Library
      non-exclusive worldwide free and/or paid synchronization licenses to Your Music Content (or any part thereof); and
      (b) obtain a permanent digital download of the licensed item of Your Music Content in connection with a Music
      Library License granted to such Profile.
      <br />
      6E.2. You, at your discretion, can determine on a Your Master Recording-by-Your Master Recording bases: i) if you
      wish to include it in the Music Library; ii) in what types of creative content (&ldquo;Content Types&rdquo;) the
      selected items of Your Music Content may be licensed for and synchronized to; and iii) the minimum license fee for
      each Content Type, using the Music Library Settings configurator (hereinafter referred to as &ldquo;Music Library
      Settings&rdquo;).
      <br />
      6E.3. If you do not own and/or control all (100%) of the exclusive worldwide Publishing Rights into the
      Compositions embodied in Your Master Recording(s), you must use the (Publishing) Royalty Split - allocation
      feature to indicate what entity does own such Publishing Rights and to what extent, and to subsequently submit
      requests to such entity to license such Publishing Rights to Collabhouse to allow Collabhouse to grant Music
      Library Licenses to Your Master Recording(s) together with the underlying Publishing Rights (&ldquo;Publishing
      Clearance Request&rdquo;).
      <br />
      6E.4. You are responsible for providing and allocating accurate, up-to-date and correct Recording and Publishing
      Royalty Splits for all Your Master Recording(s) that you upload to the Music Maker Services at all times.
      <br />
      6E.5. Publishing Royalty Splits may only be allocated to persons, companies or other entities that actually own
      and/or control any exclusive and worldwide Publishing Rights in Your Master Recording(s) and only to the extent
      they own and/control such rights. You hereby agree and warrant to provide up-to-date, complete, accurate and
      correct Publishing Royalty Splits for all (Your) Music Content that contains Your Master Recording(s) at all
      times, and that such Publishing Royalty Splits reflect actual Publishing Rights ownership and/or control
      percentages of the relevant parties.
      <br />
      6E.6. On all such Recording and Publishing Royalty Splits between Your Profile and other Profile(s) the applicable
      (local) laws between such contractual parties are applicable. We are not a party to any such split, offer,
      contract or agreement, but merely a provider of the services as part of Collabhouse to all parties. You hereby
      indemnify us against any and all claims, costs and damages arising from any dispute between you and/or Your
      Profile and any third party in connection with any Recordings and/or Publishing Royalty Splits allocated by you
      and/or Your Profile.
      <br />
      6E.7. If the Profile that submitted the Master Recording(s) to the Music Library owns and/or controls 100% of the
      Publishing Royalty Splits (100% of the required Publishing Rights) therein, such Music Content will be made
      available in the Music Library within twenty-four (24) hours from submission. Otherwise, Your Music Content is
      only made available in the Music Library after all Publishing Royalty Split Owners identified by the Profile that
      submitted the relevant Master Recording to the Music Library have: i) created and/or claimed their own Collabhouse
      Profile on Collabhouse; ii) accepted these General Terms and Conditions; and; iii) approved its Publishing Royalty
      Split together with the Music Library Settings provided for such Music Content.
      <br />
      6E.8. When Your Profile receives a Publishing Clearance Request in relation to Music Content designated for
      inclusion in the Music Library, and your and/or Your Profile does indeed own and/or control the Publishing Rights
      and Publishing Royalty Splits indicated into such Music Content exclusively and worldwide, you can approve the
      Publishing Clearance Request. You can reject a Publishing Clearance Request if you and/or Your Profile does not
      own and/control the Publishing Rights and/or Publishing Royalty Split indicated on such Publishing Clearance
      Request, or in the event you wish to refuse to license Your Music Content for inclusion in the Music Library.
      <br />
      6E.9. When you accept a Publishing Clearance Request received by Your Profile, you grant, and warrant to us that
      you and/or Your Profile has the rights to grant, to us the rights into Your Music Content as stated in section
      6E.10 below.
      <br />
      6E.10. For any item of Your Music Content you include in and/or authorize for use in the Music Library, you hereby
      grant us, and we hereby accept, a non-exclusive, worldwide right, license, privilege and authority during the
      period you select to include Your Music Content in the Music Library (&ldquo;Music Library Term&rdquo;), to: i)
      supply, stream, perform, communicate to the public, make available, display, distribute and reproduce Your Music
      Content through or in connection with the Music Library; ii) grant Music Library Licensees via the Music Library,
      in each instance, a non-exclusive, worldwide right, license, privilege and authority to create a single derivative
      work based on Your Music Content by copying and reproducing Your Music Content in a single audio(-visual) work of
      the Content Type specified by the Music Library Licensees at the time of obtaining the Music Library License, and
      to exhibit, publicly perform, communicate to the public, and/or digitally transmit Your Music Content together
      with and as part of the exhibition, public performance, communication to the public, and/or digital transmission
      of such audio(-visual) work during the license term selected by the Music Library Licensee during the process of
      obtaining a Music Library License; iii) allow Music Library Licensees that have obtained a Music Library License
      to use a Your Music Content to permanently download a copy of Your Music Content via the Services; and iv) to
      collect Music Library Royalties arising from exploitation of Your Music Content.
      <br />
      6E.11. Once Your Music Content has been included in the Music Library, only the Profile that submitted the
      relevant Master Recording to the Music Library can change its Music Library Settings and/or remove Your Music
      Content from the Music Library. Any changes made to Music Library Settings provided for Your Music Content trigger
      new Publishing Clearance Request(s) being send out that require approval from all Publishing Royalty Split Owners,
      and will cause Your Music Content to be (temporarily) removed from the Music Library until all Publishing Royalty
      Split Owners approve the new Publishing Clearance Request unless you and/or Your Profile owns and/or controls all
      required Publishing Rights and 100% of the Publishing Royalty Splits.
      <br />
      6E.12. You as Publishing Royalty Split Owner can terminate the Music Library Term for Your Music Content and ask
      us to remove it from the Music Library by sending your request via email to help@collabhouse.com.
      <br />
      6E.13. Termination of the Term and/or the Music Library Term, withdrawal of Your Music Content from the Music
      Library and any changes made to the Music Library Settings do not affect the terms and validity of any Music
      Library Licenses granted to Profile(s) in relation to Your Music Content prior to the end of the Term and/or Music
      Library Term or the moment of withdrawal of Your Music Content from the Music Library or the moment any changes
      were made to the Music Library Settings.
      <br />
      6E.14. We charge a commission of twenty-five percent (25%) on all revenue earned in connection with the sale of
      Music Library Licenses via the Music Library. The remainder is accounted and paid out as Music Library Royalties
      in accordance with section 7 herein.
    </Body>
    <SizedBox height={rem(16)} />
    <Body>
      F. Music Maker Obligations
      <br />
      6F.1. You are solely responsible for and must own or control or have obtained all necessary rights, licenses,
      waivers, clearances and permissions throughout the world for all Your Music Content and Other Materials you upload
      to and/or claim by accepting Publishing Clearance Requests via the Music Maker Services in order to enable us, the
      Music Services, relevant Music Library Licensees, NFT Owners and your Collaborators to fully utilize and exploit
      all their rights as contemplated hereunder free of any claims, liens, encumbrances or other restrictions,
      including without limitation, all rights, licenses, waivers, clearances and permissions necessary for the
      reproduction, delivery, distribution, transmission, synchronization, communication to the public, making available
      and public performance of Your Music Content. Your uploading to and/or claiming of Your Music Content by accepting
      Publishing Clearance Requests through the Music Maker Service shall be your unqualified warranty and
      representation to us that you own or control or you have obtained any and all such rights required and necessary
      for us, the Music Services and any relevant Music Library Licensees and Collaborators to sell, distribute,
      publicly perform, synchronize, promote, and otherwise use and/or exploit Your Music Content as contemplated
      hereunder, including the waiver of all so-called &ldquo;moral rights&rdquo;, under the laws of any jurisdiction,
      on your behalf as well as on behalf of any and all contributors involved in any manner with the creation, delivery
      and licensing of Your Music Content.
      <br />
      6F.2. You are solely responsible for and shall timely pay: i) any and all royalties due to artists, producers,
      songwriters and other persons who performed in the making of Your Music Content and other royalty participants;
      ii) all royalties due to any party as a result of samples (i.e. if you use any portions from other sound
      recordings) included in Your Music Content; iii) all payments that may be required under collective bargaining
      agreements applicable to you and your affiliates; and iv) all music publishing licenses and royalties due in
      connection with the rights granted in this section. You understand and acknowledge that we will not be making any
      such payments on your behalf or otherwise.
      <br />
      6F.3. In addition to the above, you shall at all times: i) be solely responsible for the proper and adequate
      uploading and submission of Your Music Content in accordance with the terms herein; ii) ensure that correct and
      complete Metadata is provided; iii) ensure that Your Music Content is technically satisfactory for exploitation
      via the Music Maker Services; iv) promptly provide us with all information and assistance reasonably required by
      us to fulfil our obligations hereunder, including in relation to handling any infringement notices, claims and
      other complaints, and to resolve any operational, legal and/or technical issues relating to Your Music Content and
      your usage of the Music Maker Services; v) fulfil any obligations directly applicable to you, and; vi) comply with
      all applicable laws, governmental regulations and requirements relating to the distribution, exploitation and/or
      marketing of Your Music Content and your usage of the Music Maker Services.
    </Body>
    <SizedBox height={rem(32)} />
    <Title as="h2" size="xxSmall">
      7. ROYALTIES AND PAYMENT
    </Title>
    <SizedBox height={rem(8)} />
    <Body>
      7.1. We collect, account and pay out two different types of royalties in connection with the distribution and
      licensing of Music Content via the Services: i) Recording Royalties, which include: a. one-hundred (100%) of
      Distribution Royalties; b. one-hundred (100%) of NFT Sales Royalties; and c. fifty percent (50%) of the Music
      Library Royalties (the so-called &lsquo;recording share&rsquo;); and ii) Publishing Royalties, which include:
      fifty percent (50%) of the Music Library Royalties (the so-called &lsquo;publishing share&rsquo;).
      <br />
      7.2. We collect, account and pay out these Royalties from various sources:
    </Body>
    <SizedBox height={rem(8)} />
    <Body>
      i) Distribution Royalties, which are collected, accounted and paid out in accordance with the Recording Royalty
      Splits provided for each item of (Your) Music Content on a monthly basis after deduction of applicable NFT Royalty
      Split(s) and/or Collab Royalty Split(s). Within one (1) month from the end of each calendar month during the
      Distribution Period, we will make available to you through your Collabhouse account a distribution accounting
      statement indicating the amount of Distribution Royalties due for the previous month. Any royalty amount shown to
      be due on those statements will be added to your or your Profile&rsquo;s royalty balance.
    </Body>
    <SizedBox height={rem(8)} />
    <Body>
      NFT Royalty Splits and Collab Royalty Splits allocated to Collaborators are collected, accounted and paid out in
      the same cycle as the Distribution Royalties on one-hundred percent (100%) of the Distribution Royalties collected
      in connection with the item of (Your) Music Content that is promoted, before any Recording Royalty Splits
      allocated to other Profiles and individual Profile Team Members are calculated.
    </Body>
    <SizedBox height={rem(8)} />
    <Body>
      ii) Music Library Royalties, which are collected, accounted and paid out on a daily basis as set out below:
      <br />
      Fifty percent (50%) is accounted and paid out as Recording Royalties in accordance with the Recording Royalty
      Splits set for that each particular item of (Your) Music Content; and
      <br />
      The other fifty percent (50%) is accounted and paid out as Publishing Royalties in accordance with the Publishing
      Royalty Splits set for that each particular item of (Your) Music Content.
      <br />
      Within forty-eight (48) hours after a Music Library License on an item of (Your) Music Content was sold, we will
      make available to you or your Profile through your Collabhouse account a Music Library License accounting
      statement indicating the amount of Music Library Royalties due to you or your Profile.
    </Body>
    <SizedBox height={rem(8)} />
    <Body>
      iii) NFT Sales Royalties: which are collected, accounted and paid out as Recording Royalties on a daily basis in
      accordance with the Recording Royalty Splits provided for the relevant item of (Your) Music Content. Within
      forty-eight (48) hours after a Royalty Split NFT on one of (Your) Master Recording(s) on which you or your Profile
      has a Recording Royalty Split was sold, we will make available to you or your Profile through your Collabhouse
      account a NFT Sales accounting statement indicating the amount of NFT Royalties due to you or your Profile.
    </Body>
    <Body>
      7.3. You can split any of the Recording Royalties due to Your Profile in connection with Your Master Recording(s)
      with one of more other Profiles by allocating a certain Recording Royalty Split to such other Profile(s), as part
      of a Collaboration or otherwise (&ldquo;Recording Royalty Split&rdquo;). Other Profiles can also allocate a
      certain Recording Royalty Split of the Recording Royalties they may earn in connection with the exploitation their
      Master Recording(s) through the Services to Your Profile, as part of a Collaboration or otherwise. On all such
      Recording Royalty Splits between Your Profile and other Profiles the applicable (local) laws between such
      contractual parties are applicable. We are not a party to any such split, offer, contract or agreement, but merely
      a provider of the services as part of Collabhouse to all parties. You hereby indemnify us against any and all
      claims, costs and damages arising from any dispute between you, Your Profile and other Profiles.
      <br />
      7.4. Once you have allocated any Royalty Splits for a particular item of Your Music Content to Your Profile(s) or
      to one or more other Profile(s) you have the ability to subsequently amend or stop such Royalty Splits in the
      section &lsquo;Royalty Splits&rsquo; on the release detail page in your Profile. Collab Royalty Splits allocated
      in connection with a completed Collaboration cannot be edited or stopped prematurely without our approval. Amended
      Publishing Royalty Splits will only take effect from the moment when approved by all (new) Publishing Royalty
      Split Owners.
      <br />
      7.5. For Royalties allocated and payable to Your Profile, you can select to have all such Royalties accounted and
      paid out on Profile level or on Team Member level to one or more individual Team Members of your Profile in
      accordance with the Royalty Splits allocated to such individual Team Members in your Profile settings menu in your
      Collabhouse account (&ldquo;Team Member Royalty Splits&rdquo;). On all such Team Member Royalty Splits between you
      and your Profile Team Members the applicable (local) laws between such contractual parties are applicable. We are
      not a party to any such split, offer, contract or agreement, but merely a provider of the services as part of
      Collabhouse to all parties. You hereby indemnify us against any and all claims, costs and damages arising from any
      dispute between you and your Team Member(s).
      <br />
      7.6. If you and/or your Profile are entitled to receive a Royalty Split and/or a Team Member Royalty Split you
      hereby engage and authorize us to collect and pay out any Royalty Split and/or Team Member Royalty Split allocated
      and/or payable to you or your Profile hereunder.
      <br />
      7.7. Royalties due to you or your Profile are added to your or your Profile&rsquo;s royalty balance. You can
      withdraw your entire royalty balance by requesting payment as soon as the total reaches our payment threshold of
      &euro;10, -. We will endeavor to process your royalty balance payment request for payment within seven (7) days
      from the moment you request payment. Payment of your royalty balance will be issued to the bank account provided
      by you for your User Account in &lsquo;My invoice and bank details&rsquo; section in &lsquo;My Settings&rsquo;.
      Payment of your Profile royalty balance will be issued to the bank account provided by you for Your Profile in the
      &lsquo;Invoice and bank details&rsquo; section in &lsquo;Settings&rsquo;.
      <br />
      7.8. All amounts payable hereunder will be computed and determined in Euros. We may compute currency conversions
      using conversion rates published by third parties.
      <br />
      7.9. We shall be entitled to rely on the payments and accountings received from Music Services and we have no
      obligation to independently review or confirm such payments or accountings. We assume no liability regarding
      payments and/or accountings from Music Services.
      <br />
      7.10. We shall be authorized to issue self-billed invoices on your behalf showing your name or company name (where
      applicable), address and VAT registration number (where applicable), as well as any other relevant details and you
      hereby agree to accept such invoices raised by us. You are responsible for indicating whether you are VAT-liable.
      It is your responsibility to keep the information in Your User Account(s) and Your Profile(s) up to date at all
      times, including your email address, invoice and bank details. You will be fully liable for &ndash; and indemnify
      us against - any and all claims, costs and damages caused by any incorrect, false or misleading information
      provided through Your User Account(s) and Your Profile(s).
      <br />
      7.11. We reserve the right to offset any amount due to or owed by you or Your Profile (and to and by any
      Profile(s) or/and Profile Team Member(s) to whom you allocated a Royalty Split) hereunder, including in the event
      of previous overpayment of any Royalties.
      <br />
      7.12. If we receive a claim or notice or otherwise reasonably suspect that Your Music Content or your use of the
      Music Maker Services breaches any agreement, infringes any third party rights, violates these General Terms and
      Conditions, any Collabhouse Documentation or any law, rule or regulation, or if there is a dispute regarding any
      of Your Music Content (including without limitation as to ownership or payment of royalties), or that your
      activities involve misrepresentation, misconduct, deception, fraud, or other inappropriate conduct, then in
      addition to any other available rights and remedies, we may withhold payment of Royalties due to you and Your
      Profile(s) (and to any Profile(s) or/and Profile Team Member(s) to whom you allocated a Royalty Split) in an
      amount reasonably attributable, as determined in our sole discretion, to the relevant items of Your Music Content
      and activity until and unless any and all claims or other conduct are favorably resolved to our reasonable
      satisfaction, and we may deduct from any Royalties due to you and Your Profile(s) (and to any Profile(s) or/and
      Profile Team Member(s) to whom you allocated a Royalty Split) our related reasonable attorneys&#39; fees and legal
      costs. You and Your Profile(s) (and any Profile(s) or/and Profile Team Member(s) to whom you allocated a Royalty
      Split) will forfeit any part of the Royalties that is attributable to your fraud, infringement or other illegal
      activity.
      <br />
      7.13. You understand and acknowledge that we may withhold and deduct any taxes such as sales, use, purchase,
      income, value-added or similar taxes, or other amounts as may be required under applicable laws, from amounts
      otherwise payable to you and Your Profile(s) (and to any Profile(s) or/and Profile Team Member(s) to whom you
      allocated a Royalty Split) hereunder, without liability to you (and to any Profile(s) or/and Profile Team
      Member(s) to whom you allocated a Royalty Split), without the obligation to gross-up payments to you (and to any
      Profile(s) or/and Profile Team Member(s) to whom you allocated a Royalty Split) or any obligation to indemnify you
      (and to any Profile(s) or/and Profile Team Member(s) to whom you allocated a Royalty Split) in respect of such
      withholdings. Each party is responsible for collecting and remitting any and all applicable taxes due in
      connection with the sale or license of such party&rsquo;s goods or services to its customers. You remain
      ultimately responsible at all times for the correct tax processing, tax administration and tax returns in
      accordance with your local tax laws and regulations.
    </Body>
    <SizedBox height={rem(32)} />
    <Title as="h2" size="xxSmall">
      8. PROHIBITED USE AND RESERVED RIGHTS
    </Title>
    <SizedBox height={rem(8)} />
    <Body>
      8.1. The Services may be used and accessed by you solely for lawful purposes and the rights and licenses granted
      herein by us are granted under the strict condition that you comply with these General Terms and Conditions while
      using the Services, any User Content and Music Content, including, without limitation, the following:
      <br />
      a) You may not use the Services to upload, store, display, copy, publish, distribute, promote, (continue to) make
      available or otherwise make public any content (including Your User Content and Your Music Content): i. that is,
      in our sole opinion, illegal, unlawful, harmful, threatening, defamatory, obscene, hateful, facilitates illegal
      activity, infringing, harassing or racially or ethnically offensive, or that invades the privacy of any third
      party, is deceptive, abusive, or is otherwise objectionable in our reasonable opinion; or ii. that includes any
      unsolicited or unauthorized advertising, promotional materials, junk mail, spam, chain letters or any other form
      of solicitation; iii. that infringes copyrighted works or otherwise violates rights, including but not limited to
      intellectual property rights, of any third party; and iv. that contains any information or content that you know
      is not correct and current;
      <br />
      b) You may not employ techniques or use services, automated or otherwise, that are intended to misrepresent or
      manipulate the popularity of Your Music Content on the Music Maker Services.
      <br />
      c) You may not use the Services to; i. impersonate yourself for another person or entity or otherwise misrepresent
      your involvement with any person or entity, or otherwise commit fraud; ii b. collect or store personal data about
      Creators;
      <br />
      d) You may not rip, edit, download, reproduce, reupload, copy, re-release, create derivative works from, make
      available or otherwise disclose, display, execute, transfer, share, distribute any User Content and Music Content
      on or from the Services or otherwise use or exploit such content, except to the extent that such content is Your
      User Content and Your Music Content, or where expressly permitted under these General Terms and Conditions;
      <br />
      e) You may not sell, rent, sublicense or lease any part of the Services or provide access to your User Account to
      a third party without our prior written consent;
      <br />
      f) You may not; i. upload, distribute or otherwise include any malicious content such as malware, Trojan horses,
      spyware, cancelbots or other viruses and malicious codes; ii. copy, distribute, reproduce, republish, download,
      display, post, transmit in any form or by any means, or alter or modify any part or parts of the Services except
      as expressly permitted herein; iii. circumvent any technology used by us, our licensors, or any third party to
      protect the Services or any content on the Service; iv. &ldquo;crawl&rdquo; the Services or otherwise use any
      automated means to collect information from us, any Creator, Profile and the Services; v. decompile, disassemble,
      reverse engineer or otherwise reduce to human-perceivable form all or any part of the Services; vi. attempt to
      intervene in any way in the Services, including without limitation, hacking, planting a virus, overloading, email
      bombing, or crashing our servers or systems; and vii. modify or create any derivative product based on the
      Services.
      <br />
      8.2. You acknowledge and agree that we have the right, in our sole discretion, to determine if you, your
      Profile(s), Your User Content and/or Your Music Content violates any of the other provisions herein. In case you
      violate one or more of the restrictions hereabove, you shall be solely responsible and liable for any and all
      costs (including legal fees), damages and claims or actions summoned by a third party and you hereby shall fully
      indemnify Collabhouse against such costs (including legal fees), damages and claims at all times.
      <br />
      8.3. We reserve the right, in our own discretion, with or without notice, to: a) temporarily or permanently
      take-down or block any or all of Your User Content and/or Your Music Content on the Services and/or the Music
      Services and/or any content, information and other materials on Your User Account and/or Your Profiles; b)
      temporarily or permanently suspend, disable or terminate Your User Account and/or Your Profiles and/or your access
      to the Services; and/or c) take any other action we deem appropriate if: i. you, Your Profile(s) or one of your
      Profile Team Members fails to comply with any of the terms set out herein; ii. Your User Content, Your Music
      Content and/or any of your Profile(s) is the subject of an alleged copyright claim or any alleged fraudulent
      activity; iii. there is any (alleged) unauthorized, fraudulent, abusive, or otherwise illegal activity on your
      User Account, Your Profile(s) and/or Your Music Content; or iv. for any other reason in our sole and absolute
      judgment necessary to protect our interests.
    </Body>
    <SizedBox height={rem(32)} />
    <Title as="h2" size="xxSmall">
      9. YOUR WARRANTIES AND REPRESENTATIONS; INDEMNIFICATION
    </Title>
    <SizedBox height={rem(8)} />
    <Body>
      9.1 You represent and warrant to us that: a) you have the legal capacity, right and authority to enter into these
      terms and to grant all rights specified herein; b) you are and will be in full and complete compliance with these
      terms at all times; c) Your User Content, Your Music Content including any Royalty Splits set, Metadata and any
      Other Material or information you provide to us is true, accurate and complete at all times; c) you are solely
      responsible for your usage of and activity on the Services and for all Your User Content and Your Music Content
      you make available to and/or claim on the Services; d) you own, control, administer and/or have obtained the
      necessary copyrights and other rights in order to make the grant of rights, licenses and permissions herein, and
      that the exercise of such rights, licenses and permissions by us, the relevant Creators and/or Profiles, and our
      respective permitted licensees, successors and assigns shall not violate or infringe any applicable law, rule or
      regulation or the rights of any third party; e) you are not and will not become a party to an agreement with any
      third party that may jeopardize and/or limit our ability to exploit and use Your Music Content in accordance with
      these terms; and vii) we shall not be responsible for payments or any other liability to any third party in
      connection with the use of Your User Content, Your Music Content or your activity on the Services or otherwise in
      connection with these terms.
      <br />
      9.2. You agree to use the Services at your own sole risk, and you hereby agree to indemnify and hold harmless, and
      upon our request, defend us, our affiliates and sublicensees (including relevant Creators and Profiles and third
      parties providing services on our behalf), and our and their officers, agents, investors, shareholders,
      representatives, past and present employees, partners, directors, controlling persons, advisors and permitted
      assigns at your own cost and expense from any and all liability, harm, damages, costs (including reasonable
      attorney&#39;s fees and legal and court costs), expenses, allegations, claims and legal action of any kind at any
      time or of any sort that may arise from: a) from your use of or activity on the Services; b) any breach or alleged
      breach by you of any of your obligations, representations and/or warranties under these terms; c) any claim
      related to the use of Your User Content, Your Music Content or any other material uploaded to and/or claimed by
      you on the Services as permitted herein; c) any activity related to Your User Account, Your Music Content and/or
      any of Your Profiles, by you or another person who, with or without your permission, accesses Your User Account
      and/or of Your Profile(s); d) your of Your Profile&rsquo;s violation of any law or the rights of a third party;
      and e) a dispute between Your Profile, other Profile(s), you, and/or your Collaborator or among Team Members of
      Your Profile.
      <br />
      9.3. You will promptly reimburse us and any other indemnified parties on demand for any amounts subject to
      indemnification. We shall notify you of any such claim and shall control the defense thereof, though you may
      participate in such defense at your own expense. You may not settle any claim for which we may be liable without
      our prior written consent, which we will not withhold unreasonably. If any facts, claims, proceedings or other
      circumstances arise that would be subject to indemnification, then we, in addition to any other right or remedy,
      shall have the right to withhold from any payments otherwise due to you and/or Your Profile(s) an amount
      reasonably related thereto until the claim, proceeding or circumstance has been finally resolved, settled or fully
      adjudicated and the judgment satisfied, or that the statute of limitations on such claim has run, or when you have
      provided reasonable and adequate security for the claim. We reserve the right to charge you and/or Your Profile(s)
      (or deduct from monies payable to you and/or Your Profile(s)) for any reasonable legal fees incurred by us as a
      result of your and/or Your Profile&rsquo;s violation of these terms.
    </Body>
    <SizedBox height={rem(32)} />
    <Title as="h2" size="xxSmall">
      10. NO WARRANTIES; LIMITATION OF LIABILITY
    </Title>
    <SizedBox height={rem(8)} />
    <Body>
      10.1. YOU UNDERSTAND AND AGREE THAT THE SERVICES AS WELL AS ANY AND ALL INFORMATION, CONTENT AND MATERIALS
      PROVIDED ON OR VIA THE SERVICES IS PROVIDED TO YOU &ldquo;AS IS&rdquo; AND &ldquo;AS AVAILABLE&rdquo;. WE MAKE NO
      GUARANTEES, REPRESENTATIONS OR WARRANTIES, EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING WITHOUT
      LIMITATION AS TO THE FUNCTIONALITY, CONDITION, CORRECTNESS, QUALITY, CONTINUITY OF OPERATION, PERFORMANCE,
      MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR RESULTS OF THE SERVICES AND THE INFORMATION, CONTENT AND
      MATERIALS PROVIDED VIA THE SERVICES. WE DO NOT GUARANTEE THAT ACCESS TO OR USE OF THE SERVICES WILL BE CONTINUOUS,
      UNINTERRUPTED, ERROR-FREE OR SECURE. YOUR ACCESS TO AND USE OF THE SERVICES AND THE INFORMATION, CONTENT AND
      MATERIALS PROVIDED VIA THE SERVICES IS AT YOUR OWN RISK. ANY AND ALL WARRANTIES ARE EXPRESSLY DISCLAIMED AND
      EXCLUDED.
      <br />
      10.2. TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT WILL COLLABHOUSE, ITS OFFICERS, SHAREHOLDERS, EMPLOYEES,
      AGENTS, DIRECTORS, SUBSIDIARIES, AFFILIATES, SUCCESSORS, ASSIGNS, SUPPLIERS, OR LICENSEES BE LIABLE FOR: A) ANY
      INDIRECT, SPECIAL, INCIDENTAL, EXEMPLARY, OR CONSEQUENTIAL DAMAGES; B) ANY LOSS OF USE, DATA, BUSINESS, OR PROFITS
      (WHETHER DIRECT OR INDIRECT), IN ALL CASES ARISING OUT OF THE USE OR INABILITY TO USE THE SERVICES, REGARDLESS OF
      LEGAL THEORY, WITHOUT REGARD TO WHETHER WE HAVE BEEN WARNED OF THE POSSIBILITY OF THOSE DAMAGES, AND EVEN IF A
      REMEDY FAILS OF ITS ESSENTIAL PURPOSE. IN NO EVENT WILL COLLABHOUSE&#39;S AGGREGATE LIABILITY ARISING OUT OF OR IN
      CONNECTION WITH THESE GENERAL TERMS AND CONDITIONS EXCEED &euro;500 EUR (FIVE-HUNDRED EURO).
    </Body>
    <SizedBox height={rem(32)} />
    <Title as="h2" size="xxSmall">
      11. PERSONAL DATA
    </Title>
    <SizedBox height={rem(8)} />
    <Body>
      You acknowledge that, to the extent Your Music Content contains personal data (including any personal data
      embodied in Metadata), we may use, store, transmit, and otherwise process such personal data to the extent
      necessary for us to perform our obligations under these terms, including transferring such personal data to our
      third-party service providers and to Music Services in order to make Your Music Content available on such Music
      Services as contemplated herein. All personal data that you provide to us in connection with your use of the
      Services is collected, stored, used, disclosed and otherwise processed by us in accordance with our Privacy
      Policy.
    </Body>
    <SizedBox height={rem(32)} />
    <Title as="h2" size="xxSmall">
      12. OWNERSHIP
    </Title>
    <SizedBox height={rem(8)} />
    <Body>
      12.1. All right, title and interest, including any intellectual property rights, in the Services, including but
      not limited to, all related technology, data, documentation, tools, and design, are exclusively owned by us, our
      affiliates (as applicable) or our licensors.
      <br />
      12.2. The Collabhouse trademarks, service marks, trade names, logos, domain names, and any other features of the
      Collabhouse brand are our sole property and that of our affiliates. These terms do not grant you any rights to use
      any of our Collabhouse brand features and trademarks whether for commercial or non-commercial use.
      <br />
      12.3. We exclusively own any and all user and usage data generated in connection with the Services, and such data
      may be used and disclosed by us (including for marketing purposes), and no ownership interest or other rights in
      any of the foregoing are being transferred to you by virtue of these terms.
      <br />
      12.4. Nothing in these terms shall be construed to convey any ownership interest in Your User Content or Your
      Music Content to us.
      <br />
      12.5. All User Content and Music Content with the exception of Your Content and Your Music Content is the property
      of the relevant Creator and/or Profile(s) who provided and/or claimed such User Content and Music Content, and is
      or may be subject to copyrights, brand rights or other (intellectual) property rights. You may not copy,
      distribute, publicly perform, publicly display, digitally perform, or create derivative works from any copyrighted
      work, excluding Your User Content and Your Music Content, made available or accessible via the Services unless
      expressly provided herein.
    </Body>
    <SizedBox height={rem(32)} />
    <Title as="h2" size="xxSmall">
      13. COPYRIGHT INFRINGEMENT AND REPORTING CONTENT
    </Title>
    <SizedBox height={rem(8)} />
    <Body>
      13.1. We respect the rights of content creators and copyright owners. If you find any User Content or Music
      Content on the Services that you believe violates your copyright, you can report it to us by sending an email to
      legal@collabhouse.com as further described in our Copyright Policy. If we are notified by a rightsholder that any
      of Your User Content or Your Music Content infringes a copyright, we may in our sole discretion take any action
      without notifying you or Your Profile, including but not limited to, removing such item(s) of Your User Content
      and /or Your Music Content from the Services.
      <br />
      13.2. If you find any User Content or Music Content on the Services that you believe violates or infringes one or
      more of your other rights, is defamatory, obscene, hateful or otherwise generally offensive, or that you otherwise
      assess as a violation of these General Terms and Conditions or applicable law, you can report it to us by sending
      an email to legal@collabhouse.com.
    </Body>
    <SizedBox height={rem(32)} />
    <Title as="h2" size="xxSmall">
      14. THIRD PARTY APPLICATIONS
    </Title>
    <SizedBox height={rem(8)} />
    <Body>
      14.1. The Services may be integrated with third party applications, websites, and services (&ldquo;Third Party
      Applications&rdquo;) to make the Services available to you. These Third-Party Applications may have their own
      terms and conditions and privacy policies and your use of these Third-Party Applications will be governed by and
      subject to such terms and conditions and privacy policies.
      <br />
      14.2. We do not endorse and are not responsible or liable for the behavior, features, or content of any
      Third-Party Application or for any transaction you may enter into with the provider of any such Third-Party
      Applications.
    </Body>
    <SizedBox height={rem(32)} />
    <Title as="h2" size="xxSmall">
      15. DATA PROTECTION, PRIVACY AND COOKIES
    </Title>
    <SizedBox height={rem(8)} />
    <Body>
      All personal data that you provide to us in connection with your use of the Services is collected, stored, used,
      disclosed and otherwise processed by us in accordance with our Privacy Policy. We use cookies to better understand
      how the Services are used to improve the Services. Our use of cookies, and how to disable them, is set out in the
      Cookie Policy section in our Privacy Policy.
    </Body>
    <SizedBox height={rem(32)} />
    <Title as="h2" size="xxSmall">
      {' '}
      16. CONFIDENTIALITY
    </Title>
    <SizedBox height={rem(8)} />
    <Body>
      You acknowledge and agree that, in the course of your use of the Services or otherwise transacting business with
      Collabhouse, Creators and/or Profiles, you may become aware of certain confidential information related to
      Collabhouse and such Creators and/or Profiles, including but not limited to Collabhouse&rsquo;s and such
      Creator&rsquo;s and/or Profile&rsquo;s business and business practices. Except to the extent that such information
      is otherwise generally available to third parties or is required to be divulged by operation of law, you agree to
      keep such information confidential at all times.
    </Body>
    <SizedBox height={rem(32)} />
    <Title as="h2" size="xxSmall">
      17. TERMINATION
    </Title>
    <SizedBox height={rem(8)} />
    <Body>
      17.1. These General Terms and Conditions shall apply to your access and usage of the Services during the Term.
      <br />
      17.2. You can terminate these General Terms and Conditions, Your User Account and Your Profile(s) by sending us a
      thirty (30) day advance written notice of your intention to terminate these General Terms and Conditions via email
      to legal@collabhouse.com, with the following exceptions; a) if you are participating in any active
      Collaboration(s), you can only terminate these General Terms and Conditions and Your User Account and Your
      Profile(s) after completing such active Collaboration(s) and b) if you are distributing one or more of Your Master
      Recording(s) via the Distribution Service, you can only terminate these General Terms and Conditions and Your User
      Account and Your Profile(s) following the end of applicable Distribution Period.
      <br />
      17.4. We may suspend your access and usage of to the Services and/or terminate these General Terms and Conditions
      at any time: a) if you fail to comply with any of the General Terms and Conditions set out herein; b) if Your User
      Content, Your Music Content and/or any of Your Profile(s) is the subject of an alleged copyright claim or any
      alleged fraudulent activity; c) if there is any (alleged) unauthorized, fraudulent, abusive, or otherwise illegal
      activity on Your User Account and/or Your Profile; or d) for any other reason in our sole and absolute judgment
      necessary to protect our interests.
      <br />
      17.5. Following termination of the Term, we will irretrievably delete all content (including Your User Content) in
      Your User Account and Your Profile(s) as well as any other content related to activity on Your User Account,
      except to the extent that we are required to or authorized to retain Your User Content, Your Music Content, data
      or information in accordance with applicable laws and regulations and/or to protect our legitimate business
      interests. We do not accept any liability for any material that has been deleted after termination of your User
      Account and/or Your Profile(s).
      <br />
      17.6. Termination of these General Terms and Conditions shall not affect any rights, remedies, obligations or
      liabilities of the parties that have accrued up to the date of termination, including our right to claim damages
      in respect of any breach by you of these General Terms and Conditions which existed on or before the date of
      termination. All provisions intended to survive termination of these General Terms and Conditions shall continue
      to do so.
    </Body>
    <SizedBox height={rem(32)} />
    <Title as="h2" size="xxSmall">
      18. MISCELLANEOUS
    </Title>
    <SizedBox height={rem(8)} />
    <Body>
      18.1. These General Terms and Conditions, together with our Privacy Policy, contain the parties&#39; entire
      understanding and supersede any prior or contemporaneous correspondence, agreements or understandings regarding
      the subject matter herein, unless otherwise agreed between the parties in writing.
      <br />
      18.2. Should any provision of these General Terms and Conditions be held invalid or unenforceable for any reason
      or to any extent, such invalidity or unenforceability shall not in any manner affect or render invalid or
      unenforceable the remaining provisions of these General Terms and Conditions, and the application of that
      provision shall be enforced to the extent permitted by law.
      <br />
      18.3. Any failure by us to enforce these General Terms and Conditions or any provision thereof shall not waive our
      or the applicable third-party beneficiary&rsquo;s right to do so.
      <br />
      18.4. We will not be liable for a curable breach of these General Terms and Conditions unless you provide us with
      written notice specifying the alleged breach that we confirm receipt of, and we fail to cure such breach within
      ninety (90) days thereafter.
      <br />
      18.5. We will not be liable for any delay or failure in performance resulting from acts or occurrences beyond our
      reasonable control, including, without limitation, (and whether similar or dissimilar) acts of God, acts of war,
      terrorism, riot, fire, flood, or other disaster or other natural occurrence, acts of government, strike, lockout,
      or power or Internet failure.
      <br />
      18.6. We may direct all notices and communications to you via the email address associated with your User Account
      and/or via your dashboard account on the Services. All notices to us shall be sent to us at legal@collabhouse.com
      or to our address indicated herein.
      <br />
      18.7. You may not assign, transfer or delegate any of your rights or obligations hereunder without our prior
      written consent. We may assign, delegate, pledge, encumber, sublicense and otherwise transfer, these General Terms
      and Conditions and/or any or all of our rights and obligations in order to operate the Services. These General
      Terms and Conditions will be binding on and inure to the benefit of the parties and their respective assigns and
      successors in interest.
      <br />
      18.8. These General Terms and Conditions and the Services shall be governed by and subject to the laws of The
      Netherlands and any dispute regarding these General Terms and Conditions shall be submitted to the exclusive
      jurisdiction of the court of Amsterdam, The Netherlands, or any higher court as relevant.
    </Body>
    <SizedBox height={rem(32)} />
    <Title as="h2" size="xxSmall">
      CONTACT US
    </Title>
    <SizedBox height={rem(8)} />
    <Body>
      If you have any questions concerning these General Terms and Conditions or your access and/or usage of the
      Services in general, we would be happy to hear from you. Please contact us on: help@collabhouse.com
    </Body>
    <Body>Version 3: March 2023</Body>
    <Body>
      Collabhouse B.V.
      <br />
      Koninginneweg 9 Hilversum
      <br />
      1217 KP, Hilversum
      <br />
      The Netherlands
    </Body>
  </>
)

const Terms: FunctionComponent<React.PropsWithChildren<RouteComponentProps>> = () => {
  const { isLoggedIn } = useUser()

  // Force login when user is not logged in.
  if (inBrowser() && !isLoggedIn) {
    return <Redirect from="/" noThrow to={routes.login.url} />
  }

  return (
    <Page hasPadding={false}>
      <Helmet>
        <title>General Terms and Conditions</title>
      </Helmet>

      <TopBarSpacer />

      <InformationHero label="Collabhouse" title="General Terms and Conditions" />
      <Container>
        <Grid columns={12}>
          <GridItem mobile={12} desktop={{ end: 11, start: 3 }}>
            <GeneralTermsCopy />
          </GridItem>
        </Grid>
      </Container>
      <Spacer type="content-bottom" />
    </Page>
  )
}

export default Terms
