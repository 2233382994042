const WEB_URL = process.env.WEB_URL || 'https://www.ledomusic.is/'

export const routes = {
  '404': {
    path: '/404',
    url: '/404',
  },
  account: {
    basicInfo: {
      path: '/account/basic-info',
      url: '/account/basic-info',
    },
    overview: {
      path: '/account',
      url: '/account',
    },
    payouts: {
      path: '/account/payouts',
      url: '/account/payouts',
    },
    teams: {
      path: '/account/teams',
      url: '/account/teams',
    },
    updatePassword: {
      path: '/account/update-password',
      url: '/account/update-password',
    },
  },
  assets: {
    details: {
      collabs: {
        path: 'collabs',
        url: '/assets/:trackId/collabs',
      },
      licenses: {
        path: 'licenses',
        url: '/assets/:trackId/licenses',
      },
      nfts: {
        path: 'nfts',
        url: '/assets/:trackId/nfts',
      },
      path: '/assets/:trackId/*',
      royaltySplits: {
        path: 'royalty-splits',
        url: '/assets/:trackId/royalty-splits',
      },
      stats: {
        path: '/*',
        url: '/assets/:trackId/stats',
      },
      url: '/assets/:trackId/',
    },
    overview: {
      collabs: {
        path: 'collabs',
        url: '/assets/overview/collabs',
      },
      licenses: {
        path: 'licenses',
        url: '/assets/overview/licenses',
      },
      nfts: {
        path: 'nfts',
        url: '/assets/overview/nfts',
      },
      path: '/assets/overview/*',
      royaltySplits: {
        path: 'royalty-splits',
        url: '/assets/overview/royalty-splits',
      },
      url: '/assets/overview',
    },
    path: '/assets',
  },
  campaigns: {
    path: '/campaigns',
    url: '/campaigns',
  },
  chat: {
    contactProfile: {
      path: '/chat/:groupId',
      url: '/chat/:groupId',
    },
    path: '/chat',
    url: '/chat',
  },
  collaboration: {
    createProposal: {
      path: '/collab-market/proposal/create',
      url: '/collab-market/proposal/create',
    },
    details: {
      deliverDraft: {
        path: '/deliver-draft/:collaborationPostId',
        url: '/collab-market/:collaborationId/deliver-draft/:collaborationPostId',
      },
      deliverProofOfDelivery: {
        path: '/proof-of-delivery/:collaborationPostId',
        url: '/collab-market/:collaborationId/proof-of-delivery/:collaborationPostId',
      },
      path: '/collab-market/:collaborationId/*',
      payment: {
        path: '/payment',
        url: '/collab-market/:collaborationId/payment',
      },
      reviewDraft: {
        path: '/review-draft/:proofOfDeliveryId',
        url: '/collab-market/:collaborationId/review-draft/:proofOfDeliveryId',
      },
      reviewProofOfDelivery: {
        path: '/review-proof-of-delivery/:proofOfDeliveryId',
        url: '/collab-market/:collaborationId/review-proof-of-delivery/:proofOfDeliveryId',
      },
      url: '/collab-market/:collaborationId',
    },
    overview: {
      allTeams: {
        path: '/all-teams',
        url: '/collab-market/overview/all-teams',
      },
      path: '/collab-market/overview/*',
      url: '/collab-market/overview',
    },
    path: '/collab-market',
    publicGroup: {
      path: '/collab-market/:groupId/public/*',
      url: '/collab-market/:groupId/public',
    },
  },
  contact: {
    path: '/contact',
    url: '/contact',
  },
  copyrightPolicy: {
    path: '/copyright-policy',
    url: '/copyright-policy',
  },
  createGroup: {
    path: '/groups/create',
    url: '/groups/create',
  },
  faq: {
    collabHouse: {
      path: '/faq/collab-market',
      url: '/faq/collab-market',
    },

    distribution: {
      path: '/faq/distribution',
      url: '/faq/distribution',
    },
    /**
     * The URLs below are coming from ledo-react so shouldn't be changed here
     * @see https://bitbucket.org/ledo-development/ledo-react/src/master/src/data/faq.json
     */
    freeMusicLibrary: {
      path: '/faq/free-music-library',
      url: '/faq/free-music-library',
    },
    overview: {
      path: '/faq',
      url: '/faq',
    },
  },
  forgotPassword: {
    path: '/password/forgot',
    url: '/password/forgot',
  },
  group: {
    getPromoted: {
      path: '/settings/get-promoted',
      url: '/settings/get-promoted',
    },
    members: {
      path: '/settings/members',
      url: '/settings/members',
    },
    overview: {
      path: '/settings',
      url: '/settings',
    },
    payouts: {
      path: '/settings/payouts',
      url: '/settings/payouts',
    },
    promoteOthers: {
      path: '/settings/promote-others',
      url: '/settings/promote-others',
    },
    publicProfile: {
      path: '/settings/team-profile',
      url: '/settings/team-profile',
    },
    royaltySplits: {
      path: '/settings/royalty-splits',
      url: '/settings/royalty-splits',
    },
  },
  home: {
    path: '/',
    url: '/',
  },
  howToChat: {
    path: '/chat-help',
    url: '/chat-help',
  },
  invoices: {
    account: {
      path: '/invoices/account',
      url: '/invoices/account',
    },
    accountDistribution: {
      path: '/invoices/account/distribution',
      url: '/invoices/account/distribution',
    },
    accountML: {
      path: '/invoices/account/music-library',
      url: '/invoices/account/music-library',
    },
    accountNft: {
      path: '/invoices/account/nft',
      url: '/invoices/account/nft',
    },
    accountRoyalties: {
      path: '/invoices/account/royalties',
      url: '/invoices/account/royalties',
    },
    group: {
      path: '/invoices/group',
      url: '/invoices/group',
    },
    groupDistribution: {
      path: '/invoices/group/distribution',
      url: '/invoices/group/distribution',
    },
    groupML: {
      path: '/invoices/group/music-library',
      url: '/invoices/group/music-library',
    },
  },
  library: {
    path: new URL('library', WEB_URL).toString(),
    url: new URL('library', WEB_URL).toString(),
  },
  login: {
    path: '/login',
    url: '/login',
  },
  musicLibrary: {
    licenseTrack: {
      path: '/music-library/track/license/*',
      url: '/music-library/track/license',
    },
    myLicenses: {
      path: '/my-licenses',
      url: '/music-library/overview/my-licences',
    },
    overview: {
      path: '/music-library/overview/*',
      url: '/music-library/overview',
    },
    path: '/music-library',
  },
  myCollabs: {
    overview: {
      cancelled: {
        path: 'cancelled',
        url: '/my-collabs/overview/cancelled',
      },
      declined: {
        path: 'declined',
        url: '/my-collabs/overview/declined',
      },
      done: {
        path: 'done',
        url: '/my-collabs/overview/done',
      },
      ongoingPayment: {
        path: 'ongoing-payment',
        url: '/my-collabs/overview/ongoing-payment',
      },
      path: '/my-collabs/overview/*',
      proposal: {
        path: 'proposal',
        url: '/my-collabs/overview/proposal',
      },
      running: {
        path: 'active',
        url: '/my-collabs/overview/active',
      },
      upcomingPayment: {
        path: 'upcoming-payment',
        url: '/my-collabs/overview/upcoming-payment',
      },
      url: '/my-collabs/overview',
    },
    path: '/my-collabs',
    url: '/my-collabs',
  },
  nftMarket: {
    overview: {
      path: '/nft-market/overview/*',
      url: '/nft-market/overview',
    },
    path: '/nft-market/',
  },
  privacy: {
    path: '/privacy',
    url: '/privacy',
  },
  products: {
    create: {
      path: '/create',
      url: '/music-distribution/create',
    },
    overview: {
      path: '/*',
      url: '/music-distribution',
    },
    path: '/music-distribution',
    product: {
      createTrack: {
        path: '/tracks/create',
        url: '/music-distribution/:productId/tracks/create',
      },
      musicLibrary: {
        path: '/music-library',
        url: '/music-distribution/:productId/music-library',
      },
      nfts: {
        path: '/nfts',
        url: '/music-distribution/:productId/nfts',
      },
      overview: {
        path: '/*',
        url: '/music-distribution/:productId/',
      },
      path: '/:productId/*',
      publish: {
        path: '/publish',
        url: '/music-distribution/:productId/publish',
      },
      releaseDetails: {
        path: '/details',
        url: '/music-distribution/:productId/details',
      },
      royaltySplits: {
        path: '/royalty-splits',
        url: '/music-distribution/:productId/royalty-splits',
      },
      trackOverview: {
        details: {
          path: '/details',
          url: '/music-distribution/:productId/tracks/:trackId/details',
        },
        path: '/tracks/:trackId/*',
      },
      tracks: {
        path: '/tracks',
        url: '/music-distribution/:productId/tracks',
      },
    },
  },
  publishingClearances: {
    details: {
      path: '/publishing-clearances/details/:trackId',
      url: '/publishing-clearances/details/:trackId',
    },
    overview: {
      accepted: {
        path: '/publishing-clearances/accepted',
        url: '/publishing-clearances/accepted',
      },
      path: '/publishing-clearances/*',
      pending: {
        path: '/publishing-clearances/pending',
        url: '/publishing-clearances/pending',
      },
      rejected: {
        path: '/publishing-clearances/rejected',
        url: '/publishing-clearances/rejected',
      },
      url: '/publishing-clearances/',
    },
    path: '/publishing-clearances',
    url: '/publishing-clearances',
  },
  register: {
    path: '/register',
    url: '/register',
  },
  royalties: {
    account: {
      path: '/royalties/account',
      url: '/royalties/account',
    },
    accountMusicLibrary: {
      path: '/royalties/account/music-library',
      url: '/royalties/account/music-library',
    },
    accountNft: {
      path: '/royalties/account/nft',
      url: '/royalties/account/nft',
    },
    accountPayments: {
      path: '/royalties/account/payments',
      url: '/royalties/account/payments',
    },
    group: {
      path: '/royalties/group',
      url: '/royalties/group',
    },
    groupMusicLibrary: {
      path: '/royalties/group/music-library',
      url: '/royalties/group/music-library',
    },
    groupNft: {
      path: '/royalties/group/nft',
      url: '/royalties/group/nft',
    },
    groupPayments: {
      path: '/royalties/group/payments',
      url: '/royalties/group/payments',
    },
  },
  stats: {
    details: {
      path: '/details',
      url: '/stats/details',
    },
    overview: {
      path: '/overview',
      url: '/stats/overview',
    },
    path: '/stats',
  },
  terms: {
    distribution: {
      path: '/terms-distribution',
      url: '/terms-distribution',
    },
    general: {
      path: '/terms-general',
      url: '/terms-general',
    },
    musicLibrary: {
      path: '/terms-ml',
      url: '/terms-ml',
    },
    overview: {
      path: '/terms',
      url: '/terms',
    },
  },
  tutorials: {
    path: '/tutorials',
    url: '/tutorials',
  },
}

export const getUrlWithParams = (
  url: string,
  params: {
    [key: string]: string
  },
): string => {
  let generatedUrl = url

  Object.keys(params).forEach((paramKey) => {
    generatedUrl = generatedUrl.replace(`:${paramKey}`, params[paramKey])
  })

  return generatedUrl
}
