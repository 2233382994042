import { type FunctionComponent, type PropsWithChildren, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'

export interface PortalProps {
  portal?: string
}

/**
 * The portal component will check if there is a HTMLElement with the given
 * portal name, or create that element when it is not found, and render the
 * children in that element using React's portal API.
 *
 * @important If you use multiple portals and you need to ensure the correct
 * stacking order for your portals, please make sure that the portals already
 * exist in your HTML before React boots.
 */
export const Portal: FunctionComponent<React.PropsWithChildren<PropsWithChildren<PortalProps>>> = ({
  portal = 'portal',
  ...props
}) => {
  const [element, setElement] = useState<HTMLElement | null>(null)

  useEffect(() => {
    let element = window.document.getElementById(portal)

    if (!element) {
      element = window.document.createElement('div')
      element.id = portal
      window.document.body.appendChild(element)
    }

    setElement(element)
  }, [portal])

  return element ? ReactDOM.createPortal(props.children, element) : null
}

export default Portal
