import React, { type FunctionComponent, useCallback } from 'react'

import { rem } from '../../utils/rem'
import Dialog, { type DialogProps } from '../dialog/Dialog'
import IndeterminateProgress from '../indeterminate-progress/IndeterminateProgress'
import SizedBox from '../sized-box/SizedBox'
import TextButton from '../text-button/TextButton'

export interface ConfirmationDialogProps extends DialogProps {
  disabled?: boolean
  loading?: boolean
  onConfirm?: () => void
  onRedirect?: () => void
}

const ConfirmationDialog: FunctionComponent<ConfirmationDialogProps> = ({
  onConfirm,
  onRedirect,
  loading = false,
  onClose,
  ...props
}) => {
  const handleClose = useCallback(() => {
    onClose?.('button')
  }, [onClose])

  return (
    <Dialog
      size="medium"
      actions={
        <>
          {onRedirect && (
            <TextButton size="small" variant="tertiary" onClick={onRedirect}>
              Edit payment details
            </TextButton>
          )}
          {onClose && (
            <TextButton size="small" variant="tertiary" onClick={handleClose}>
              Cancel
            </TextButton>
          )}
          <SizedBox width={{ desktop: rem(24), mobile: rem(16) }} />
          <TextButton
            leading={loading ? <IndeterminateProgress /> : undefined}
            size="small"
            disabled={loading || props.disabled}
            onClick={onConfirm}
          >
            Confirm
          </TextButton>
        </>
      }
      extendedFooter={!!onRedirect}
      {...props}
    />
  )
}

export default ConfirmationDialog
