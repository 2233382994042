import React, { type FunctionComponent } from 'react'

import Anchor, { type AnchorProps } from '../anchor/Anchor'

export interface ExternalLinkProps extends Omit<AnchorProps, 'href'> {
  to: string
}

const ExternalLink: FunctionComponent<React.PropsWithChildren<ExternalLinkProps>> = ({ to, ...props }) => (
  <Anchor target="_blank" rel="noopener noreferrer" {...props} href={to} />
)

export default ExternalLink
