import { GraphQLResult } from '@aws-amplify/api-graphql'
import { CognitoUser } from 'amazon-cognito-identity-js'
import { API, Auth, graphqlOperation } from 'aws-amplify'
import React, { createContext, Dispatch, FC, ReactNode, useCallback, useEffect, useState } from 'react'
import { v4 as uuid } from 'uuid'

import { Address } from '../graphql/address'
import { AddGroupMutationVariables, GeneralTermsStatus, GetMyGroupsQuery, PaypalAccount } from '../graphql/generated'
import * as groupOperations from '../graphql/group'
import { PaymentSettings } from '../graphql/groupPaymentSettings'
import * as userProfileOperations from '../graphql/userProfile'
import { getMyUserProfile } from '../graphql/userProfile'
import { VatDetails } from '../graphql/vatDetails'
import { useDataLayer } from '../hooks/useDataLayer'
import { useQueryParameter } from '../hooks/useQueryParameter'
import { RemoteImage, upload } from '../utils/storage'
import { Group, GroupInput } from './GroupProvider'

export interface AcceptedTerm {
  acceptedAt?: string
  id?: string
  terms?: string
}

export interface UserContext {
  addGroup: (input: GroupInput) => Promise<Group>
  groups?: GetMyGroupsQuery['getMyGroups']
  hydrate: () => Promise<void>
  isCreatingProfile: boolean
  isLoading: boolean
  isLoggedIn: boolean
  ready: boolean
  selectGroup: Dispatch<string>
  selectedGroupId?: string
  setIsCreatingProfile: Dispatch<boolean>
  setUser: Dispatch<CognitoUser>
  user?: CognitoUser
  userProfile?: UserProfile
}

export interface BankAccount {
  accountHolderName: string
  details: IbanBankAccountDetails | SortCodeBankAccountDetails
  type: BankAccountType
}

export enum BankAccountType {
  iban = 'iban',
  sortCode = 'sortCode',
}

export interface IbanBankAccountDetails {
  iban: string
}

export interface SortCodeBankAccountDetails {
  accountNumber: string
  sortCode: string
}

export interface UserProfile {
  acceptedTerms?: AcceptedTerm[]
  address?: Address
  avatar?: RemoteImage
  bankAccount?: BankAccount
  email: string
  generalTermsStatus: GeneralTermsStatus
  id: string
  name?: string
  paymentSettings?: PaymentSettings
  paypalAccount?: PaypalAccount
  userId: string
  vatDetails?: VatDetails
  walletAddress?: string
}

export interface AddUserProfileInput {
  avatar?: File
  email: string
  name?: string
}

export const userContext = createContext<UserContext>({
  addGroup: () => undefined,
  groups: undefined,
  hydrate: async () => undefined,
  isCreatingProfile: undefined,
  isLoading: true,
  isLoggedIn: undefined,
  ready: false,
  selectGroup: () => undefined,
  setIsCreatingProfile: () => undefined,
  setUser: () => undefined,
  user: undefined,
  userProfile: undefined,
})

interface UserProviderProps {
  children: ((context: UserContext) => ReactNode) | ReactNode
}

type Loadable<T> =
  | { data?: undefined; state: 'idle' }
  | { data?: undefined; state: 'loading' }
  | { data: T; state: 'done' }
  | { data?: undefined; error: Error; state: 'error' }

const UserProvider: FC<UserProviderProps> = ({ children }) => {
  /////////////////
  ///// STATE /////
  /////////////////

  const [isLoadingUser, setIsLoadingUser] = useState(true)
  const [isLoadingProfileAndGroup, setIsLoadingProfileAndGroup] = useState(true)
  const [user, setUser] = useState<CognitoUser>(undefined)
  const [userAttributes, setUserAttributes] = useState<Record<string, string>>(undefined)
  const [userProfile, setUserProfile] = useState<UserProfile>(undefined)
  const [groups, setGroups] = useState<Loadable<GetMyGroupsQuery['getMyGroups']>>({
    state: 'idle',
  })
  const [ready, setReady] = useState(false)
  const [selectedGroupId, setSelectedGroupId] = useState<string>(undefined)
  const [isCreatingProfile, setIsCreatingProfile] = useState<boolean>(false)
  const { update: updateDataLayer } = useDataLayer()
  const { value: groupIdQueryParamValue } = useQueryParameter('groupId')
  const [switchingGroups, setSwitchingGroups] = useState(false)

  //////////////////
  ///// PUBLIC /////
  //////////////////

  /**
   * Select the group with the given ID.
   */
  const selectGroup = (id: string) => {
    setSelectedGroupId(id)
    setReady(true)
    window.localStorage.setItem('selectedGroupId', JSON.stringify(id))
  }

  /**
   * Create a new group for the user.
   */
  const addGroup = async ({ avatar, ...input }: GroupInput) => {
    // Upload the avatar.
    const remoteAvatar = avatar ? await upload(uuid(), avatar) : undefined

    // Create a new group.
    const groupVars: AddGroupMutationVariables = {
      input: {
        ...input,
        avatar: remoteAvatar,
        role: input.role,
      },
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const addGroupResult: any = await API.graphql(graphqlOperation(groupOperations.addGroup, groupVars))

    await hydrate()

    return addGroupResult.data.addGroup as Group
  }

  ///////////////////
  ///// PRIVATE /////
  ///////////////////

  /**
   * Returns the profile of the user.
   *
   * @returns {UserProfile}
   */
  const getUserProfile = useCallback(async () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const result: any = await API.graphql(graphqlOperation(getMyUserProfile))

    return result.data?.getMyUserProfile as UserProfile
  }, [])

  /**
   * Add the user profile.
   *
   * @return {UserProfile}
   */
  const addUserProfile = async (input: AddUserProfileInput): Promise<UserProfile> => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const result: any = await API.graphql(graphqlOperation(userProfileOperations.addMyUserProfile, { input }))

    return result.data?.addMyUserProfile as UserProfile
  }

  /**
   * Retrieve the user's groups.
   * @todo Move to User model.
   */
  const getGroups = useCallback(async () => {
    const result = await (API.graphql(graphqlOperation(groupOperations.getMyGroups)) as Promise<
      GraphQLResult<GetMyGroupsQuery>
    >)

    return result.data.getMyGroups
  }, [])

  /**
   * Hydrate the provider.
   */
  const hydrate = useCallback(async () => {
    setUserProfile(await getUserProfile())
    setGroups({ state: 'loading' })
    try {
      const groups = await getGroups()
      setGroups({ data: groups, state: 'done' })
    } catch {
      setGroups({ error: new Error('Failed to load groups'), state: 'error' })
    }
  }, [getUserProfile, getGroups])

  /**
   * Clears the provider's state.
   */
  const clear = useCallback(async () => {
    setUserProfile(undefined)
    setGroups({ state: 'idle' })
  }, [])

  ///////////////////
  ///// EFFECTS /////
  ///////////////////

  // Initialize the provider.
  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(setUser)
      .catch(() => setUser(undefined))
      .finally(() => setIsLoadingUser(false))
  }, [])

  // Update the state when the user changes.
  useEffect(() => {
    if (!isLoadingUser) {
      const action = user ? hydrate : clear

      setIsLoadingProfileAndGroup(true)

      void action().finally(() => {
        // We can only be sure we're done loading when Auth.currentAuthenticatedUser() is done.
        setIsLoadingProfileAndGroup(false)
      })
    }
  }, [user, clear, hydrate, isLoadingUser])

  // Update selected group id when there is an query param
  useEffect(() => {
    if (groups.state !== 'done') return

    if (groupIdQueryParamValue && !switchingGroups && selectedGroupId !== groupIdQueryParamValue) {
      setSwitchingGroups(true)

      if (groups.data?.find((group) => group.id === groupIdQueryParamValue)) {
        setSelectedGroupId(groupIdQueryParamValue)
      } else {
        setSwitchingGroups(false)
      }
    }
  }, [groupIdQueryParamValue, switchingGroups, groups, setSelectedGroupId, selectedGroupId])

  useEffect(() => {
    if (switchingGroups && selectedGroupId === groupIdQueryParamValue) {
      setSwitchingGroups(false)
    }
  }, [switchingGroups, groupIdQueryParamValue, selectedGroupId])

  // Get the user attributes from cognito.
  useEffect(() => {
    if (user) {
      user.getUserAttributes((error, attributes) => {
        if (error) {
          return
        }

        setUserAttributes(
          Object.fromEntries(attributes.map((attribute) => [attribute.getName(), attribute.getValue()])),
        )
      })
    } else {
      setUserAttributes(undefined)
      setUserProfile(undefined)
      setGroups({ state: 'idle' })
    }
  }, [user])

  // Update user tracking data.
  useEffect(() => {
    if (groups.state !== 'done') return

    updateDataLayer({
      event: 'user-activity',
      user: {
        // TODO: Fix typing
        amountOfGroups: groups.data ? groups.data.length : null,

        email: user?.['attributes']['email'],
        userName: user?.getUsername(),
      },
    })
  }, [user, groups, updateDataLayer])

  useEffect(() => {
    if (groups.state == 'loading' || groups.state == 'idle') {
      return
    }

    if (groups.state === 'error') {
      throw groups.error
    }

    if (groups.data.length == 0) {
      selectGroup(undefined)
      return
    }

    let groupId = selectedGroupId
    if (!groupId) {
      try {
        groupId = JSON.parse(window.localStorage.getItem('selectedGroupId'))
      } catch {
        // Ignore errors.
      }
    }

    if (!groupId || typeof groupId !== 'string') {
      groupId = groups.data[0].id
    }

    const foundGroup = groups.data?.find(({ id }) => id === groupId)

    if (foundGroup) {
      selectGroup(foundGroup.id)
    } else {
      selectGroup(groups.data[0].id)
    }
  }, [selectedGroupId, groups])

  //////////////////
  ///// RENDER /////
  //////////////////

  const context: UserContext = {
    addGroup,
    groups: groups.state === 'done' ? groups.data : undefined,
    hydrate,
    isCreatingProfile,
    isLoading: isLoadingUser || isLoadingProfileAndGroup,
    isLoggedIn: !!user,
    ready,
    selectGroup,
    selectedGroupId,
    setIsCreatingProfile,
    setUser,
    user,
    userProfile,
  }

  return (
    <userContext.Provider value={context}>
      {!switchingGroups && (typeof children === 'function' ? children(context) : children)}
    </userContext.Provider>
  )
}

export default UserProvider

export const UserConsumer = userContext.Consumer
