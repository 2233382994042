import React, { type FunctionComponent, useCallback } from 'react'
import styled, { css } from 'styled-components'

import { BrandColors } from '../../tokens/colors'
import { Icons } from '../../tokens/icons'
import { MediaQueries } from '../../tokens/mediaQueries'
import { Opacities } from '../../tokens/opacities'
import { rem } from '../../utils/rem'
import Icon from '../icon/Icon'
import PrimaryButton from '../primary-button/PrimaryButton'
import SecondaryButton, { type SecondaryButtonProps } from '../secondary-button/SecondaryButton'

export type PlayPauseButtonSize = 'small' | 'medium'
export type ColorScheme = 'light' | 'primary'

export interface PlayPauseButtonProps extends Omit<SecondaryButtonProps, 'size'> {
  colorScheme?: ColorScheme
  isHovered?: boolean
  playing?: boolean
  size?: PlayPauseButtonSize
}

const PlayPauseButton: FunctionComponent<React.PropsWithChildren<PlayPauseButtonProps>> = ({
  playing,
  size = 'medium',
  colorScheme = 'primary',
  isHovered = false,
  ...props
}) => {
  const getIconSize = useCallback(() => {
    switch (size) {
      case 'small':
        return rem(24)

      case 'medium':
      default:
        return rem(30)
    }
  }, [size])

  return (
    <Button
      type="button"
      as={size === 'small' ? SecondaryButton : PrimaryButton}
      colorScheme={colorScheme}
      size={size}
      isHovered={isHovered}
      {...props}
    >
      <Icon icon={playing ? Icons.pause : Icons.play} size={getIconSize()} />
    </Button>
  )
}
export default PlayPauseButton

const Button = styled('button')<PlayPauseButtonProps>(
  ({ size = 'medium', colorScheme = 'primary', isHovered }) => css`
    position: relative;
    border-radius: 50%;
    padding: 0;
    outline: none;
    border-color: transparent;
    ${isHovered ? `background: transparent;` : ''}
    ${colorScheme === 'light' &&
    `
      background: ${BrandColors.blackcurrant.alpha(Opacities.thirtyfive).toString()};
      color: ${BrandColors.white.toString()};
      `};

    :hover {
      background: transparent;
    }
    :after {
      content: '';
      position: absolute;
      top: -4px;
      left: -4px;
      width: calc(100% + 8px);
      height: calc(100% + 8px);
      border: 1px solid transparent;
      border-radius: inherit;
    }

    &:hover,
    &:focus {
      ${colorScheme === 'light' &&
      `
        background: ${BrandColors.blackcurrant.alpha(Opacities.thirtyfive).toString()};
        border-color: ${BrandColors.white.toString()};
      `};
    }
    ${MediaQueries.desktop} {
      padding: 0;
    }

    ${() => {
      switch (size) {
        case 'small':
          return css`
            width: ${rem(32)};
            height: ${rem(32)};

            ${MediaQueries.desktop} {
              width: ${rem(32)};
              height: ${rem(32)};
            }
          `

        case 'medium':
          return css`
            width: ${rem(48)};
            height: ${rem(48)};

            ${MediaQueries.desktop} {
              width: ${rem(56)};
              height: ${rem(56)};
            }
          `
      }
    }}
  `,
)
