import { type AnchorHTMLAttributes } from 'react'
import styled, { css } from 'styled-components'

import { BrandColors, SystemColors } from '../../tokens/colors'
import { Opacities } from '../../tokens/opacities'
import { Transitions } from '../../tokens/transitions'
import { rem } from '../../utils/rem'

export interface AnchorProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  bold?: boolean
  color?: string
  disabled?: boolean
}

const Anchor = styled.a<{ bold?: boolean; color?: string; disabled?: boolean }>(
  ({ disabled, bold, color }) => css`
    font-weight: ${bold !== false ? 'bold' : 'normal'};
    text-decoration: none;
    background-image: ${color
      ? `linear-gradient(
            ${color},
            ${color}
          )`
      : `linear-gradient(
            ${BrandColors.white.toString()},
            ${BrandColors.white.toString()}
          )`};
    background-repeat: no-repeat;
    background-position: 0 100%;
    background-size: 100% 1px;
    padding-bottom: ${rem(1)};
    transition: ${Transitions.micro};
    outline: none;
    color: ${color ? color : BrandColors.white.toString()};

    :hover {
      cursor: pointer;
      opacity: ${Opacities.fiftyfive};
    }

    :active {
      opacity: ${Opacities.fiftyfive};
    }

    :focus {
      background-image: linear-gradient(${SystemColors.info.toString()}, ${SystemColors.info.toString()});
    }

    :disabled {
      opacity: ${Opacities.thirtyfive};
    }

    ${disabled &&
    css`
      cursor: inherit;
      pointer-events: none;
    `}
  `,
)

export default Anchor
