// IconButton is only used for icons managed through the icomoon icon font library.
import { type ButtonHTMLAttributes, default as React } from 'react'
import styled from 'styled-components'

import { BrandColors } from '../../tokens/colors'
import { MediaQueries } from '../../tokens/mediaQueries'
import { Opacities } from '../../tokens/opacities'
import { Transitions } from '../../tokens/transitions'
import { rem } from '../../utils/rem'
import BaseButton from '../base-button/BaseButton'
import Icon from '../icon/Icon'

export type IconButtonProps = ButtonHTMLAttributes<HTMLButtonElement>

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  outline: 1px solid transparent;
  outline-offset: ${rem(2)};
  transition: background ${Transitions.micro};
  width: 100%;
  height: 100%;
`

const IconButton = styled(Icon).attrs<IconButtonProps>({
  as: BaseButton,
  children: <Background />,
})<IconButtonProps>`
  position: relative;
  padding: 0;
  outline: none;
  width: ${rem(32)};
  height: ${rem(32)};

  ${MediaQueries.desktop} {
    width: ${rem(40)};
    height: ${rem(40)};
  }

  &:hover {
    ${Background} {
      background: ${BrandColors.white.alpha(Opacities.eight).toString()};
    }
  }

  &:focus {
    ${Background} {
      background: ${BrandColors.white.alpha(Opacities.eight).toString()};
    }
  }

  &:active {
    ${Background} {
      background: ${BrandColors.white.alpha(Opacities.twelve).toString()};
    }
  }

  &:disabled {
    opacity: ${Opacities.thirtyfive};
  }
`

export default IconButton
