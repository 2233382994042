import React, { FC, ReactNode, useMemo } from 'react'

const EMAIL_REGEX =
  /((?:(?:(?:[^<>()[\]\\.,;:\s@"]+(?:\.[^<>()[\]\\.,;:\s@"]+)*)|(?:".+"))@(?:(?:\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(?:(?:[a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))))/g

const LINK_REGEX = new RegExp(
  '((?:https?://)?(?:www\\.)?[-a-zA-Z0-9@:%_]{1,256}(?:\\.[a-zA-Z0-9]{1,6})+(?:/[-a-zA-Z0-9@:%_.?&=/]*)?)',
  'g',
)

export interface AutoLinkProps {
  text: string
}

const AutoLink: FC<AutoLinkProps> = ({ text }): Array<string | ReactNode> =>
  createLinksForRegexes(
    [
      { create: createEmailLink, regex: EMAIL_REGEX },
      { create: createLink, regex: LINK_REGEX },
    ],
    [text],
  )

const createLink = (link: string, text: string = link) => {
  const normalizedText = useMemo(() => text.replace(/^https?:\/\//is, ''), [text])

  return (
    <a key={link} href={link} target="_blank" rel="noreferrer">
      {normalizedText}
    </a>
  )
}

const createEmailLink = (email: string) => createLink(`mailto:${email}`, email)

type CreateLink = (link: string) => ReactNode

type CreateLinkRegex = { create: CreateLink; regex: RegExp }

const createLinksForRegexes = (
  regexes: CreateLinkRegex[],
  nodes: Array<string | ReactNode>,
): Array<string | ReactNode> =>
  regexes.reduce(
    (nodes, regex) =>
      nodes.flatMap((node) =>
        typeof node === 'string' ? createLinksForRegex(node, regex.regex, regex.create) : [node],
      ),
    nodes,
  )

const createLinksForRegex = (
  text: string,
  regex: RegExp,
  create: (link: string) => ReactNode,
): Array<string | ReactNode> => {
  const matches = Array.from(text.matchAll(regex))
  if (matches.length === 0) {
    return [text]
  }

  const result: Array<string | ReactNode> = []
  let offset = 0
  for (const match of matches) {
    // Any text between last match (or start) and this match?
    if (match.index > offset) {
      result.push(text.slice(offset, match.index))
    }

    // Create a new link.
    result.push(create(match[0]))

    offset = match.index + match[0].length
  }
  if (offset < text.length) {
    result.push(text.slice(offset))
  }

  return result
}

export default AutoLink
