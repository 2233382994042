/**
 *
 * TODO: Mostly copied from ledo-web - should be re-used via @ledo-react
 *
 */

import { rem, SizedBox } from 'ledo-react'
import styled from 'styled-components'

interface SpacerProps {
  type: 'paragraph' | 'content-block' | 'content-title' | 'content-bottom'
}

const Spacer = styled(SizedBox).attrs<SpacerProps>(({ type }) => {
  switch (type) {
    case 'paragraph':
      return { height: rem(28) }
    case 'content-block':
      return {
        height: {
          desktop: rem(96),
          mobile: rem(80),
        },
      }
    case 'content-title':
      return { height: rem(24) }
    case 'content-bottom':
      return {
        height: {
          desktop: rem(160),
          mobile: rem(96),
        },
      }
  }
})<SpacerProps>``

export default Spacer
