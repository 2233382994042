import React, { createContext, type FunctionComponent, type ReactNode, useCallback, useState } from 'react'

interface Breadcrumb {
  id: string
  label: string
  pathname: string
}

interface BreadcrumbsContext {
  breadcrumbs?: Breadcrumb[]
  push?: (Breadcrumb) => void
  remove?: (id: string) => void
}

export const breadcrumbsContext = createContext<BreadcrumbsContext>({
  breadcrumbs: null,
  push: () => null,
  remove: () => null,
})

interface BreadcrumbsProviderProps {
  children: ReactNode
}

const BreadcrumbsProvider: FunctionComponent<React.PropsWithChildren<BreadcrumbsProviderProps>> = (props) => {
  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>([])

  /**
   * Add breadcrumb the breadcrumb list.
   */
  const push = useCallback(
    (crumb: Breadcrumb) => {
      setBreadcrumbs((crumbs) => {
        crumbs?.push(crumb)

        return crumbs || [crumb]
      })
    },
    [setBreadcrumbs],
  )

  /**
   * Remove breadcrumb with given id from the breadcrumb list.
   */
  const remove = useCallback(
    (id: string) => {
      setBreadcrumbs((crumbs) => crumbs?.filter((crumb) => crumb.id !== id))
    },
    [setBreadcrumbs],
  )

  const context = {
    breadcrumbs,
    push,
    remove,
  }

  return <breadcrumbsContext.Provider value={context}>{props.children}</breadcrumbsContext.Provider>
}

export default BreadcrumbsProvider

export const BreadcrumbsConsumer = breadcrumbsContext.Consumer
