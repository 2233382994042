import React, { FunctionComponent, ReactNode, useRef } from 'react'
import styled, { css } from 'styled-components'
import { v4 as uuid } from 'uuid'

import { BrandColors, SystemColors } from '../../tokens/colors'
import { MediaQueries } from '../../tokens/mediaQueries'
import { Opacities } from '../../tokens/opacities'
import { rem } from '../../utils/rem'
import Body from '../body/Body'
import Caption from '../caption/Caption'
import { CheckboxInputProps } from '../checkbox-input/CheckboxInput'
import Column from '../column/Column'
import Row from '../row/Row'
import SizedBox from '../sized-box/SizedBox'
import Square from '../square/Square'

export interface BoxedCheckboxInputProps extends CheckboxInputProps {
  description?: React.ReactChild
  hideCheckbox?: boolean
  icon?: ReactNode
  label?: React.ReactChild
}

const BoxedCheckbox: FunctionComponent<React.PropsWithChildren<BoxedCheckboxInputProps>> = ({
  error,
  label,
  description,
  icon,
  hideCheckbox,
  disabled,
  ...props
}) => {
  const idRef = useRef(uuid())
  const ariaLabel = label ? label.toString() : 'Checkbox input'

  return (
    <OuterWrapper>
      <Input type="checkbox" id={idRef.current} aria-label={ariaLabel} {...props} />

      <Wrapper as="label" htmlFor={idRef.current} error={error} hideCheckbox={hideCheckbox}>
        <ContentWrapper mainAxisAlignment="flex-start" crossAxisAlignment="flex-start">
          <Text>
            {icon && (
              <div>
                {icon}
                <SizedBox height={rem(12)} />
              </div>
            )}

            {label && (
              <>
                <TextColumn disabled={disabled}>
                  <Caption size="medium" bold>
                    {label}
                  </Caption>
                  <SizedBox height={rem(12)} />
                  {description && (
                    <Body
                      size="small"
                      color={disabled ? undefined : BrandColors.white.alpha(Opacities.fiftyfive).toString()}
                    >
                      {description}
                    </Body>
                  )}
                </TextColumn>
              </>
            )}
          </Text>
          <Square ghost size={{ desktop: '24px', mobile: '20px' }} color={BrandColors.white.toString()}>
            <div style={{ color: 'transparent' }}>
              <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 6L5 9L12 2" stroke="currentColor" strokeWidth="2" strokeLinecap="square" />
              </svg>
            </div>
          </Square>
        </ContentWrapper>
      </Wrapper>
    </OuterWrapper>
  )
}

export default BoxedCheckbox

const Input = styled.input<BoxedCheckboxInputProps>`
  position: absolute;
  appearance: none;
  opacity: 0;
`

const Label = styled(Caption)``

interface TextColumnProps {
  disabled?: boolean
}

const TextColumn = styled(Column)<TextColumnProps>`
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: ${Opacities.thirtyfive};
    `}
`

interface WrapperProps {
  borderless?: boolean
  error?: boolean
  hideCheckbox?: boolean
}

const OuterWrapper = styled.div`
  background: ${BrandColors.haiti.toString()};
`

const Wrapper = styled(Column)<WrapperProps>`
  position: relative;
  display: flex;
  border: 1px solid transparent;
  width: 100%;
  background: ${BrandColors.haiti.toString()};
  height: 100%;
  padding: ${rem(24)};

  input:not(:disabled) + &:hover {
    border-color: ${BrandColors.white.alpha(Opacities.fiftyfive).toString()};
  }

  input:checked + & {
    border-color: ${BrandColors.white.toString()};
    border-width: 1px;
    ${Square} {
      background: ${BrandColors.white.toString()};
      & svg {
        color: ${BrandColors.blackcurrant.toString()};
      }
    }

    :hover {
      border-color: ${BrandColors.white.alpha(Opacities.seventyfive).toString()};
    }
  }

  input:not(:checked) + & {
    ${Square} {
      opacity: ${Opacities.thirtyfive.toString()};
    }
  }

  ${({ error = false }) =>
    error &&
    css`
      border-color: ${SystemColors.danger.toString()}!important;

      ${Label} {
        color: ${BrandColors.white.toString()} !important;
      }
    `}

  input:disabled + & {
    pointer-events: none;
    border-color: ${BrandColors.white.alpha(Opacities.thirtyfive).toString()}!important;
    ${Square} {
      background: ${BrandColors.white.alpha(Opacities.thirtyfive).toString()};
      border-color: ${BrandColors.white.alpha(Opacities.thirtyfive).toString()};
    }
  }

  ${({ hideCheckbox }) =>
    hideCheckbox &&
    `
  ${Square} {
      background-color: transparent;
      border-color: transparent;
      display: none;
    }`}
`

const ContentWrapper = styled(Row)`
  justify-content: space-between;

  ${MediaQueries.desktop} {
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    height: 100%;
  }
`

const Text = styled.div`
  ${MediaQueries.desktop} {
    margin-bottom: ${rem(16)};
  }
`
