import { navigate } from 'gatsby'
import {
  Avatar,
  BaseButton,
  Body,
  BrandColors,
  IconButton,
  Icons,
  MediaQueries,
  Opacities,
  rem,
  SizedBox,
  Transitions,
} from 'ledo-react'
import React, { type FunctionComponent, type HTMLAttributes, type ReactNode } from 'react'
import styled, { css } from 'styled-components'

import { getImageSize, ImageSize } from '../../../utils/imageSize'
import { type RemoteImage } from '../../../utils/storage'

type DropdownItemSize = 'small' | 'large'

interface DropdownItemProps extends HTMLAttributes<HTMLButtonElement> {
  avatar?: RemoteImage
  children: ReactNode
  isActive?: boolean
  onSettingsClick?: () => void
  size?: DropdownItemSize
  to?: string
}

const DropdownItem: FunctionComponent<React.PropsWithChildren<DropdownItemProps>> = ({
  avatar,
  children,
  className,
  isActive = false,
  onClick,
  onSettingsClick,
  size = 'small',
  to,
  ...props
}) => (
  <StyledDropdownItem className={className}>
    <Link
      isActive={isActive}
      onClick={(event) => {
        onClick?.(event)

        if (to) {
          void navigate(to)
        }
      }}
      size={size}
      {...props}
    >
      {avatar !== undefined && (
        <>
          <Avatar src={getImageSize(avatar, ImageSize.small)?.url} size={{ desktop: rem(40), mobile: rem(32) }} />
          <SizedBox width={rem(12)} />
        </>
      )}
      <Content emphasis hasSettingsButton={Boolean(onSettingsClick)}>
        {children}
      </Content>
    </Link>
    {onSettingsClick && <SettingsButton icon={Icons.settings} onClick={onSettingsClick} />}
  </StyledDropdownItem>
)

export default DropdownItem

const StyledDropdownItem = styled.div`
  position: relative;
`

interface LinkProps {
  isActive: boolean
  size: DropdownItemSize
}

const Link = styled(BaseButton)<LinkProps>`
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  color: ${BrandColors.white.toString()};
  height: ${rem(48)};
  padding: 0 ${rem(16)};
  width: 100%;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background: ${BrandColors.springGreen.toString()};
    transition: transform ${Transitions.micro};
    width: ${rem(4)};
    height: ${rem(48)};
    transform: scaleX(0);
    transform-origin: left top;
  }

  ${MediaQueries.desktop} {
    padding: 0 ${rem(24)};
  }

  &:hover {
    background: ${BrandColors.white.alpha(Opacities.eight).toString()};
  }

  &:focus,
  &:active {
    background: transparent;

    &:before {
      transform: scaleX(1);
    }
  }

  ${({ isActive }) =>
    isActive &&
    css`
      &::before {
        transform: scaleX(1);
      }
    `}

  ${({ size }) =>
    size === 'large' &&
    css`
      height: ${rem(64)};

      &::before {
        top: ${rem(12)};
        height: ${rem(40)};
      }

      ${MediaQueries.desktop} {
        height: ${rem(72)};

        &::before {
          height: ${rem(48)};
        }
      }
    `}
`

interface ContentProps {
  hasSettingsButton: boolean
}

const Content = styled(Body)<ContentProps>`
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ hasSettingsButton }) =>
    hasSettingsButton &&
    css`
      max-width: ${rem(148)};

      ${MediaQueries.desktop} {
        max-width: ${rem(180)};
      }
    `}
`

const SettingsButton = styled(IconButton)`
  position: absolute;
  top: 50%;
  transform: translate3d(0, -50%, 0);
  right: ${rem(20)};

  &::after {
    content: '';
    position: absolute;
    display: block;
    left: ${rem(-12)};
    width: ${rem(1)};
    height: ${rem(32)};
    background-color: ${BrandColors.white.alpha(Opacities.twenty).toString()};
  }

  ${MediaQueries.desktop} {
    display: none;
  }
`
