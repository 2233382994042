import { type FC } from 'react'
import { animated } from 'react-spring'
import styled from 'styled-components'

import { BrandColors } from '../../tokens/colors'
import { Transitions } from '../../tokens/transitions'

export interface LinearDeterminateProgressProps {
  max?: number
  min?: number
  progress?: number
}

const LinearDeterminateProgress = animated(styled('div').attrs<LinearDeterminateProgressProps>(
  ({ min = 0, max = 100, progress = 0 }) => ({
    style: { width: `${((progress - min) / (max - min)) * 100}%` },
  }),
)`
  max-width: 100%;
  width: 0;
  height: 2px;
  transition: ${Transitions.micro};
  background: ${BrandColors.white.toString()};
`) as FC<React.PropsWithChildren<LinearDeterminateProgressProps>>

export default LinearDeterminateProgress
