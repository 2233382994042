import { type GraphQLResult } from '@aws-amplify/api-graphql'

import { graphqlApi } from '../utils/graphql'

export type Platform =
  | 'facebook'
  | 'instagram'
  | 'snapchat'
  | 'soundcloud'
  | 'spotify'
  | 'tikTok'
  | 'triller'
  | 'twitch'
  | 'youtube'
  | 'twitter'

export interface PlatformAccount {
  followers?: {
    high?: number
    low: number
  }
  id: string
  platform: Platform
  url: string
}

export type PlatformAccountInput = Omit<PlatformAccount, 'id'>

export const addPlatformAccount = async (variables: {
  groupId: string
  input: PlatformAccountInput
}): Promise<GraphQLResult<PlatformAccount>> =>
  graphqlApi<PlatformAccount>(
    `
      mutation AddPlatformAccount(
        $groupId: ID!,
        $input: PlatformAccountInput!
      ) {
        addPlatformAccount(groupId: $groupId, input: $input) {
          id
          platform
          url
          followers {
            low
            high
          }
        }
      }
  `,
    variables,
  )

export const editPlatformAccount = async (variables: {
  id: string
  input: PlatformAccountInput
}): Promise<GraphQLResult<PlatformAccount>> =>
  graphqlApi<PlatformAccount>(
    `
      mutation EditPlatformAccount(
        $id: ID!,
        $input: PlatformAccountInput!
      ) {
        editPlatformAccount(id: $id, input: $input) {
          id
          platform
          url
          followers {
            low
            high
          }
        }
      }
  `,
    variables,
  )

export const removePlatformAccount = async (variables: { id: string }): Promise<GraphQLResult<PlatformAccount>> =>
  graphqlApi<PlatformAccount>(
    `
      mutation RemovePlatformAccount($id: ID!) {
        removePlatformAccount(id: $id) {
          id
          platform
          url
          followers {
            low
            high
          }
        }
      }
  `,
    variables,
  )
