import React, { type FunctionComponent, type ReactElement, useRef } from 'react'
import styled, { css } from 'styled-components'

import { Portals } from '../../tokens/portals'
import { Transitions } from '../../tokens/transitions'
import { rem } from '../../utils/rem'
import Portal from '../portal/Portal'

export interface AudioPlayerContainerProps {
  children: ReactElement
  visible: boolean
}

const AudioPlayerContainer: FunctionComponent<React.PropsWithChildren<AudioPlayerContainerProps>> = ({
  visible,
  children,
  ...props
}) => {
  const container = useRef<HTMLDivElement>(null)

  return (
    <Portal portal={Portals.navigation}>
      <Container ref={container} visible={visible}>
        {children}
      </Container>
    </Portal>
  )
}

export default AudioPlayerContainer

const Container = styled.div<{ visible: boolean }>(
  ({ visible }) => css`
    position: fixed;
    z-index: 1;
    left: 0;
    bottom: 0;
    width: 100%;
    height: ${rem(80)};
    opacity: ${visible ? 1 : 0};
    transform: ${visible ? 'translateY(0)' : 'translateY(100vh)'};
    transition: ${Transitions.micro};
    transition-timing-function: ${visible ? 'ease-out' : 'ease-in'};
  `,
)
