import React, {
  createContext,
  type FC,
  type MouseEventHandler,
  type PropsWithChildren,
  type ReactNode,
  type RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'

export interface AudioPlayerItem {
  artworkUrl?: string
  audioUrl: string
  autoplay?: boolean
  bpm?: number
  genres?: string
  id?: string
  next?: Omit<AudioPlayerItem, 'next' | 'prev'>
  onDownloadClick?: MouseEventHandler
  prev?: Omit<AudioPlayerItem, 'next' | 'prev'>
  subtitle?: ReactNode
  title?: string
}

export interface AudioPlayerContext {
  audio?: RefObject<HTMLAudioElement>
  duration?: number
  expanded: boolean
  item: AudioPlayerItem | null
  pause?: () => void
  play?: () => void
  playing?: boolean
  repeat?: boolean
  reset?: () => void
  setExpanded: (value: boolean) => void
  setItem?: (data: AudioPlayerItem) => void
  setRepeat?: (value: boolean) => void
  setVolume?: (value: number) => void
  togglePlaying?: () => void
  volume?: number
}

export const audioPlayerContext = createContext<AudioPlayerContext>({
  audio: undefined,
  duration: undefined,
  expanded: false,
  item: null,
  pause: () => undefined,
  play: () => undefined,
  playing: false,
  repeat: false,
  reset: () => undefined,
  setExpanded: () => undefined,
  setItem: () => undefined,
  setRepeat: () => undefined,
  setVolume: () => undefined,
  togglePlaying: () => undefined,
  volume: undefined,
})

const AudioPlayerProvider: FC<React.PropsWithChildren<PropsWithChildren<Record<string, unknown>>>> = ({ children }) => {
  // const { isLoggedIn } = useUser()
  const [item, setCurrentItem] = useState<AudioPlayerItem | null>(null)
  const audio = useRef(new Audio(item?.audioUrl))
  const [playing, setPlaying] = useState(false)
  const [repeat, setRepeat] = useState(false)
  const [volume, setVolume] = useState(audio.current?.volume || 0.5)
  const [duration, setDuration] = useState(audio.current?.duration || 0)
  const [expanded, setExpanded] = useState(false)
  // const { update: updateDataLayer } = useDataLayer()
  /**
   * Reset all data.
   */
  const reset = useCallback(() => {
    setCurrentItem(null)
  }, [setCurrentItem])

  /**
   * Play audio.
   */
  const play = useCallback(() => {
    void audio.current?.play()
  }, [audio])

  /**
   * Pause audio.
   */
  const pause = useCallback(() => {
    audio.current?.pause()
  }, [audio])

  /**
   * Toggle playing state.
   */
  const togglePlaying = useCallback(() => {
    if (audio.current?.paused) {
      play()
    } else {
      pause()
    }
  }, [audio, play, pause])

  /**
   * Set data and remember last playing state.
   */
  const setItem = useCallback(
    (item: AudioPlayerItem) => {
      setCurrentItem(item)
      if (typeof item.autoplay === 'boolean') {
        audio.current.autoplay = item.autoplay
        setPlaying(item.autoplay)
      }
    },
    [audio, setCurrentItem, setPlaying],
  )

  /**
   * Set duration when audio is loaded.
   */
  const handleLoadedMetadata = useCallback(() => {
    setDuration(audio.current?.duration || 0)
  }, [audio, setDuration])

  /**
   * Update playing state when audio ended.
   */
  const handleEnded = useCallback(() => {
    if (repeat) {
      play()
    } else {
      setPlaying(false)
    }
  }, [repeat, play, setPlaying])

  /**
   * Update context playing state when audio playing state changed.
   */
  const handlePlay = useCallback(() => {
    setPlaying(true)
  }, [setPlaying])

  /**
   * Update context playing state when audio paused state changed.
   */
  const handlePause = useCallback(() => {
    setPlaying(false)
  }, [setPlaying])

  /**
   * Handle event listeners.
   */
  useEffect(() => {
    const audioRef = audio.current

    audioRef?.addEventListener('loadedmetadata', handleLoadedMetadata)
    audioRef?.addEventListener('play', handlePlay)
    audioRef?.addEventListener('pause', handlePause)
    audioRef?.addEventListener('ended', handleEnded)

    return () => {
      audioRef?.removeEventListener('loadedmetadata', handleLoadedMetadata)
      audioRef?.removeEventListener('play', handlePlay)
      audioRef?.removeEventListener('pause', handlePause)
      audioRef?.removeEventListener('ended', handleEnded)
    }
  }, [audio, handleLoadedMetadata, handlePlay, handlePause, handleEnded])

  /**
   * Set audio volume when volume has changed.
   */
  useEffect(() => {
    audio.current.volume = volume
  }, [volume])

  /**
   * Update audio src when item is changed.
   */
  useEffect(() => {
    if (audio.current && item?.audioUrl) {
      audio.current.src = item.audioUrl
    }
  }, [item])

  /**
   * Pause when there is no item available.
   */
  useEffect(() => {
    if (!item) {
      pause()
    }
  }, [item, pause])

  /**
   * Set ready and current item when login state changes.
   * TODO move to ledo ui
   */
  // useEffect(() => {
  //   if (isLoggedIn) {
  //     setCurrentItem(undefined)
  //   }
  // }, [setCurrentItem, isLoggedIn])

  /**
   * Update audio player tracking data.
   * TODO move to ledo ui
   */
  // useEffect(() => {
  //   updateDataLayer({
  //     event: 'audio-player',
  //     audioPlayer: {
  //       id: item?.id,
  //       playing: playing,
  //       expanded: expanded,
  //     },
  //   })
  // }, [item, playing, expanded, updateDataLayer])

  const context = {
    audio,
    duration,
    expanded,
    item,
    pause,
    play,
    playing,
    repeat,
    reset,
    setExpanded,
    setItem,
    setRepeat,
    setVolume,
    togglePlaying,
    volume,
  }

  return <audioPlayerContext.Provider value={context}>{children}</audioPlayerContext.Provider>
}

export default AudioPlayerProvider

export const AudioPlayerConsumer = audioPlayerContext.Consumer
