import React, { type FC, type ReactNode } from 'react'
import styled, { css } from 'styled-components'

import { BrandColors } from '../../tokens/colors'
import { Opacities } from '../../tokens/opacities'
import { rem } from '../../utils/rem'

interface Props {
  active: boolean
  children: ReactNode
  onClick: () => void
}

const TabItem: FC<Props> = ({ onClick, active, children }) => (
  <Item onClick={onClick} active={active}>
    {children}
  </Item>
)

export default TabItem

const Item = styled.li<{ active: boolean }>`
  list-style-type: none;
  background-color: ${BrandColors.steelGray.toString()};
  border-right: ${rem(2)} solid ${BrandColors.steelGray.toString()};
  transition:
    background 0.2s ease-in-out,
    border-color 0.2s ease-in-out;
  cursor: pointer;
  padding: ${rem(22)} ${rem(17)};
  text-align: center;
  color: ${BrandColors.white.alpha(Opacities.fiftyfive).toString()};

  @media (min-width: 1200px) {
    padding: ${rem(24)} ${rem(32)};
  }

  ${({ active }) =>
    active &&
    css`
      color: ${BrandColors.white.toString()};
      font-weight: bold;
      background: ${BrandColors.white.alpha(Opacities.twelve).toString()};
      border-right: ${rem(2)} solid ${BrandColors.springGreen.toString()};
      transition:
        background 0.2s ease-in-out,
        border-color 0.2s ease-in-out;
    `}

  &:hover {
    background: ${BrandColors.white.alpha(Opacities.twelve).toString()};
    transition: background 0.2s ease-in-out;
  }
`
