import React, { type FC } from 'react'

const SidebarExpandIcon: FC = ({ ...rest }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path d="M18 18V6H6V18H18Z" stroke="white" strokeLinecap="square" />
    <path d="M9.49219 6V18" stroke="white" strokeLinecap="square" />
    <path d="M12.3203 12.0024L15.157 9.63867V14.3661L12.3203 12.0024Z" fill="white" />
  </svg>
)

export default SidebarExpandIcon
