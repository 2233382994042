import React, { type FunctionComponent } from 'react'
import styled from 'styled-components'

import { useWindow } from '../../hooks/useWindow'
import SizedBox, { type SizedBoxProps } from '../sized-box/SizedBox'

export type FullscreenProps = SizedBoxProps

const Fullscreen: FunctionComponent<React.PropsWithChildren<FullscreenProps>> = ({
  children,
  width,
  height,
  ...props
}) => {
  const { width: windowWidth, height: windowHeight } = useWindow()

  return (
    <FullscreenContainer {...props} width={width || `${windowWidth}px`} height={height || `${windowHeight}px`}>
      {children}
    </FullscreenContainer>
  )
}

export default Fullscreen

const FullscreenContainer = styled(SizedBox)`
  position: relative;
`
