/**
 *
 * TODO: Mostly copied from ledo-web - should be re-used via @ledo-react
 * TODO: Change ledo socials into Collabhouse socials
 *
 */

import { Link } from 'gatsby'
import {
  BrandColors,
  Divider,
  ExternalLink,
  type ExternalLinkProps,
  Icon,
  Icons,
  MediaQueries,
  Padding,
  rem,
  Row,
  SizedBox,
  TextButton,
  useAudioPlayer,
  Wordmark,
} from 'ledo-react'
import React, { type FunctionComponent } from 'react'
import styled, { css } from 'styled-components'

import LinkedinIcon from '../../components/linkedin-icon/LinkedinIcon'
import { useUser } from '../../hooks/useUser'
import isLowKey from '../../tokens/isLowKey'
import { routes } from '../../utils/routes'
import Container from '../components/Container'
import IconButton from '../components/IconButton'

const footerPadding = {
  desktop: `${rem(64)} 0`,
  mobile: `${rem(40)} 0 ${rem(64)}`,
}

const footerWithAudioPlayerPadding = {
  desktop: `${rem(64)} 0 ${rem(64 + 80)}`,
  mobile: `${rem(40)} 0 ${rem(64 + 80)}`,
}

const Footer: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
  const { isLoggedIn } = useUser()
  const { item } = useAudioPlayer()

  const isAudioPlayerVisible = Boolean(item)
  const isWebContentVisible = isLoggedIn || (!isLoggedIn && !isLowKey)

  return (
    <>
      <Divider />
      <Padding padding={isAudioPlayerVisible ? footerWithAudioPlayerPadding : footerPadding}>
        <Container>
          <Wrapper mainAxisAlignment="space-between" crossAxisAlignment="center">
            <BrandLink href="https://collabhouse.com/" target="_blank" rel="noreferrer">
              <BrandIcon>
                <Wordmark height={rem(48)} />
              </BrandIcon>
            </BrandLink>

            <SocialIcons
              mainAxisAlignment="flex-end"
              crossAxisAlignment="center"
              as={Padding}
              top={{ desktop: '0', mobile: rem(40) }}
            >
              <IconButton
                as="a"
                target="_blank"
                href="https://www.youtube.com/@collabhouse_official"
                data-gtm-name="footer-navigation-youtube"
              >
                <Icon icon={Icons.youtube} />
              </IconButton>
              <SizedBox width={{ desktop: rem(40), mobile: rem(24) }} />
              <IconButton
                as="a"
                target="_blank"
                href="https://www.tiktok.com/@collabhouse.com"
                data-gtm-name="footer-navigation-tik-tok"
              >
                <Icon icon={Icons.tikTok} />
              </IconButton>
              <SizedBox width={{ desktop: rem(40), mobile: rem(24) }} />
              <IconButton
                as="a"
                target="_blank"
                href="https://www.linkedin.com/company/collabhouse/"
                data-gtm-name="footer-navigation-youtube"
              >
                <LinkedinIcon width={24} height={24} fill={BrandColors.white.toString()} />
              </IconButton>
              <SizedBox width={{ desktop: rem(40), mobile: rem(24) }} />
              <IconButton
                as="a"
                target="_blank"
                href="https://www.facebook.com/Collabhouse-bv-112214584837469/"
                data-gtm-name="footer-navigation-facebook"
              >
                <Icon icon={Icons.facebook} />
              </IconButton>
              <SizedBox width={{ desktop: rem(40), mobile: rem(24) }} />
              <IconButton
                as="a"
                target="_blank"
                href="https://www.instagram.com/collabhouse.official/"
                data-gtm-name="footer-navigation-instagram"
              >
                <Icon icon={Icons.instagram} />
              </IconButton>
            </SocialIcons>

            <WebContent isHidden={!isWebContentVisible} top={{ desktop: '0', mobile: rem(40) }}>
              <Row>
                <TextButton
                  variant="tertiary"
                  as={StyledExternalLink}
                  to={`${process.env.WEB_URL}contact#app`}
                  data-gtm-name="footer-navigation-contact"
                >
                  Contact
                </TextButton>
                <SizedBox width={{ desktop: rem(32), mobile: rem(24) }} />
                <TextButton
                  variant="tertiary"
                  as={StyledExternalLink}
                  to={`${process.env.WEB_URL}tutorials#app`}
                  data-gtm-name="footer-navigation-about"
                >
                  Tutorials
                </TextButton>
                <SizedBox width={{ desktop: rem(32), mobile: rem(24) }} />
                <TextButton
                  variant="tertiary"
                  as={StyledExternalLink}
                  to={`${process.env.WEB_URL}faq#app`}
                  data-gtm-name="footer-navigation-faq"
                >
                  FAQ
                </TextButton>
              </Row>
            </WebContent>
          </Wrapper>
          <Wrapper mainAxisAlignment="space-between" crossAxisAlignment="center">
            <Wrapper mainAxisAlignment="flex-start" crossAxisAlignment="center">
              {isWebContentVisible && (
                <>
                  <Padding top={{ desktop: rem(64), mobile: rem(40) }}>
                    <Row>
                      <TextButton
                        size="small"
                        variant="tertiary"
                        as={isLoggedIn ? Link : StyledExternalLink}
                        to={isLoggedIn ? routes.terms.overview.url : `${process.env.WEB_URL}terms`}
                        data-gtm-name="footer-navigation-terms"
                      >
                        Terms and conditions
                      </TextButton>
                      <SizedBox width={{ desktop: rem(32), mobile: rem(24) }} />
                      <TextButton
                        size="small"
                        variant="tertiary"
                        as={isLoggedIn ? Link : StyledExternalLink}
                        to={isLoggedIn ? routes.privacy.url : `${process.env.WEB_URL}privacy`}
                        data-gtm-name="footer-navigation-privacy"
                      >
                        Privacy policy
                      </TextButton>

                      <SizedBox width={{ desktop: rem(32), mobile: rem(24) }} />
                    </Row>
                  </Padding>
                  <Padding top={{ desktop: rem(64), mobile: rem(16) }}>
                    <TextButton
                      size="small"
                      variant="tertiary"
                      as={isLoggedIn ? Link : StyledExternalLink}
                      to={isLoggedIn ? routes.copyrightPolicy.url : `${process.env.WEB_URL}copyright-policy`}
                      data-gtm-name="footer-navigation-copyright-policy"
                    >
                      Copyright Policy
                    </TextButton>
                  </Padding>
                </>
              )}
            </Wrapper>

            <Padding top={rem(64)}>
              <CopyrightText as="span" variant="tertiary" size="small">
                © Copyright {new Date().getFullYear()} Collabhouse
              </CopyrightText>
            </Padding>
          </Wrapper>
        </Container>
      </Padding>
    </>
  )
}

const ExternalLinkSameTab: FunctionComponent<React.PropsWithChildren<ExternalLinkProps>> = (props) => (
  <ExternalLink {...props} target="_self">
    {props.children}
  </ExternalLink>
)

const WebContent = styled(Padding)<{ isHidden: boolean }>`
  ${({ isHidden }) =>
    isHidden &&
    css`
      opacity: 0;
      pointer-events: none;
    `}
`

const StyledExternalLink = styled(ExternalLinkSameTab)`
  background-image: none;
`

export default Footer

const Wrapper = styled(Row)`
  flex-direction: column;

  ${MediaQueries.desktop} {
    flex-direction: row;
  }
`

const BrandIcon = styled(Row)``

const SocialIcons = styled(Row)`
  ${MediaQueries.desktop} {
    order: 1;
  }
`

const CopyrightText = styled(TextButton)`
  pointer-events: none;
`

const BrandLink = styled.a`
  text-decoration: none;

  ${MediaQueries.desktop} {
    order: 1;
  }
`
