import { getPriorityConnector, initializeConnector } from '@web3-react/core'
import { Web3ReactHooks } from '@web3-react/core/dist/hooks'
import { MetaMask } from '@web3-react/metamask'
import { Connector } from '@web3-react/types'
import { WalletConnect as WalletConnectV2 } from '@web3-react/walletconnect-v2'
import { WalletLink } from '@web3-react/walletlink'
import { useCallback, useEffect } from 'react'

import { chains, ETH } from '../tokens/chains'

export const preferredChainId = process.env.GATSBY_WEB3_CHAIN_ID ? parseInt(process.env.GATSBY_WEB3_CHAIN_ID) : 80001

export const preferredChain = chains[preferredChainId]

export const [walletConnectV2, walletConnectV2Hooks] = initializeConnector<WalletConnectV2>(
  (actions) =>
    new WalletConnectV2({
      actions,
      options: {
        chains: [preferredChainId],
        projectId: process.env.WALLET_CONNECT_PROJECT_ID || 'cdeec5f9179ac2d466d3f5a093f1a5ad',
        showQrModal: true,
      },
    }),
)

export const [metaMask, metaMaskHooks, metaMaskStore] = initializeConnector<MetaMask>(
  (actions) => new MetaMask({ actions }),
)

export const [walletLink, walletLinkHooks, walletLinkStore] = initializeConnector<WalletLink>(
  (actions) =>
    typeof window !== 'undefined' &&
    new WalletLink(actions, {
      appName: 'Collabhouse',
      url: preferredChain.urls[0],
    }),
)

const CONNECTORS: [Connector, Web3ReactHooks][] = [
  [metaMask, metaMaskHooks],
  [walletLink, walletLinkHooks],
  [walletConnectV2, walletConnectV2Hooks],
]

export const getConnector = (): ReturnType<typeof getPriorityConnector> => getPriorityConnector(...CONNECTORS)

export const useConnectEagerly = () => {
  // instructs all wallets to connect on mount
  // use this when you want the wallet to be ready for signing/transactions etc.
  useEffect(() => {
    for (const [connector] of CONNECTORS) {
      void connector.connectEagerly()
    }
  }, [])
}

export const useIsConnected = (): boolean => {
  const { usePriorityAccount } = getConnector()
  const account = usePriorityAccount()
  return !!account
}

export const useDisconnect = (): (() => void) => {
  const { usePriorityConnector } = getConnector()

  const connector = usePriorityConnector()

  return useCallback(() => {
    void (async () => {
      if (connector instanceof MetaMask) {
        return connector.resetState()
      }

      await connector.deactivate()
    })()
  }, [connector])
}

export const useConnect = (connector: Connector): (() => Promise<void>) =>
  useCallback(async () => {
    if (connector instanceof MetaMask) {
      await connector.activate({
        blockExplorerUrls: 'blockExplorerUrls' in preferredChain ? preferredChain.blockExplorerUrls : [],
        chainId: preferredChainId,
        chainName: preferredChain.name,
        nativeCurrency: 'nativeCurrency' in preferredChain ? preferredChain.nativeCurrency : ETH,
        rpcUrls: preferredChain.urls,
      })
    } else {
      await connector.activate()
    }
  }, [connector])
