import React, { FC, Fragment } from 'react'
import styled from 'styled-components'

import { BrandColors } from '../../tokens/colors'
import { MediaQueries } from '../../tokens/mediaQueries'
import { MusicLibraryLicenseSetting } from '../../tokens/musicLibraryLicenseSetting'
import { Opacities } from '../../tokens/opacities'
import { rem } from '../../utils/rem'
import Body from '../body/Body'
import List from '../list/List'
import SizedBox from '../sized-box/SizedBox'

interface PricingSetting {
  basePrice: string
  configuredPrice?: string
  setting: string
  title: string
  withPadding?: boolean
}

interface PricingCategory {
  settings: PricingSetting[]
  title: string
}

export interface Props {
  categories: PricingCategory[]
  withPadding?: boolean
}

const PricingList: FC<React.PropsWithChildren<Props>> = ({ categories, withPadding }) => (
  <StyledList separator={<SizedBox height={rem(12)} />}>
    {categories.map((category, i) => (
      <Fragment key={i}>
        <Title key={category.title} emphasis size="small" withPadding={withPadding}>
          {category.title}
        </Title>

        {category.settings.map((setting, i) => (
          <PricingListSetting key={i} withPadding={withPadding} {...setting} />
        ))}
      </Fragment>
    ))}
  </StyledList>
)

const PricingListSetting: FC<React.PropsWithChildren<PricingSetting>> = ({
  title,
  setting,
  basePrice,
  configuredPrice,
  withPadding,
}) => {
  if (setting === MusicLibraryLicenseSetting.Disabled) {
    return null
  }

  const isCustom = !Object.values<string>(MusicLibraryLicenseSetting).includes(setting)

  const titleSizing = withPadding ? { desktop: '60%' } : { desktop: '46%' }
  return (
    <SettingWrapper size="small" withPadding={withPadding}>
      <SizedBox width={titleSizing}>
        <InlineBox>{title}</InlineBox>
      </SizedBox>
      <>
        <InlineBox>
          {setting !== MusicLibraryLicenseSetting.Free ? (
            <PriceLabel color={BrandColors.springGreen.toString()}>
              <SizedBox width={rem(64)}>
                {setting === MusicLibraryLicenseSetting.PaidLow && '$'}
                {setting === MusicLibraryLicenseSetting.PaidMid && '$$'}
                {setting === MusicLibraryLicenseSetting.PaidHigh && '$$$'}
                {isCustom && 'CUSTOM'}
              </SizedBox>
            </PriceLabel>
          ) : (
            <PriceLabel color={BrandColors.white.toString()}>
              <SizedBox width={rem(64)}>FREE</SizedBox>
            </PriceLabel>
          )}
        </InlineBox>
        <InlineBox width={{ desktop: rem(110) }}>
          {setting !== MusicLibraryLicenseSetting.Free && (
            <PriceAmount>
              from <Price>{isCustom ? configuredPrice : basePrice}</Price>
            </PriceAmount>
          )}
        </InlineBox>
      </>
    </SettingWrapper>
  )
}

export default PricingList

const SettingWrapper = styled(Body)<{ withPadding?: boolean }>`
  color: ${(props) => props.theme.foreground.alpha(Opacities.fiftyfive).toString()};
  font-size: ${rem(14)};
  display: flex;
  flex-wrap: wrap;
  ${MediaQueries.desktop} {
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    ${({ withPadding }) =>
      withPadding &&
      `
        padding-left: ${rem(44)};
  `};
  }
`

const PriceLabel = styled.span<{ color?: string }>`
  font-size: ${rem(12)};
  line-height: ${rem(16)};
  border: 1px solid ${(props) => props.theme.foreground.alpha(Opacities.twelve).toString()};
  padding: ${rem(2)} ${rem(4)};
  text-align: center;
  ${({ color }) => color && `color: ${color};`}
  display: inline-block;
  margin: 0 0.5rem;
  ${MediaQueries.desktop} {
    margin: 0 0 0 ${rem(4)};
  }
`
const Price = styled.span`
  color: ${BrandColors.white.toString()};
  display: inline-block;
`

const Title = styled(Body)<{ withPadding?: boolean }>`
  ${({ withPadding }) =>
    withPadding &&
    `
      ${MediaQueries.desktop} {
        padding-left: ${rem(44)};
      }
  `}
`

const PriceAmount = styled.span`
  ${MediaQueries.desktop} {
    text-align: right;
    display: block;
  }
`

const InlineBox = styled(SizedBox)`
  display: inline;
  ${MediaQueries.desktop} {
    display: block;
  }
`

const StyledList = styled(List)`
  width: 100%;
`
