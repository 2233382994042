import React, { Children, Fragment, type FunctionComponent, type ReactChild } from 'react'

import Flex, { type FlexProps } from '../flex/Flex'

export interface ListProps extends FlexProps {
  separator?: ReactChild
}

const List: FunctionComponent<React.PropsWithChildren<ListProps>> = ({
  direction = 'column',
  children,
  separator,
  ...props
}) => {
  let processed = Children.toArray(children)

  if (separator) {
    processed = processed.reduce<typeof processed>(
      (reduced, child, index) =>
        index < processed.length - 1 ? reduced.concat(child, separator) : reduced.concat(child),
      [],
    )
  }

  return (
    <Flex {...props} direction={direction}>
      {processed.map((item, index) => (
        <Fragment key={index}>{item}</Fragment>
      ))}
    </Flex>
  )
}

export default List
