import React, { type FunctionComponent, useRef } from 'react'
import styled from 'styled-components'
import { v4 as uuid } from 'uuid'

import FormField, { type FormFieldProps } from '../form-field/FormField'
import { default as BaseInput, type InputProps } from '../input/Input'

export interface DateFieldProps extends Omit<FormFieldProps, 'htmlFor'> {
  id?: string
  input?: Omit<InputProps, 'disabled' | 'error'>
}

// This regex matches strings which only contain numbers, dashes (-) and and forward slashes (/)
const allowedInputRegex = /^([-\d/])+$/

const DateField: FunctionComponent<React.PropsWithChildren<DateFieldProps>> = ({ id, input, ...props }) => {
  const idRef = useRef(id ?? uuid())

  return (
    <FormField {...props} htmlFor={idRef.current}>
      <Input
        {...input}
        onChange={(event) => {
          if (allowedInputRegex.test(event.currentTarget.value) || event.currentTarget.value === '') {
            input?.onChange?.(event)
          }
        }}
        type="date"
        id={idRef.current}
        disabled={props.disabled}
        error={!!props.error}
      />
    </FormField>
  )
}

export default DateField

const Input = styled(BaseInput)`
  position: relative;

  ::-webkit-calendar-picker-indicator {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    color: transparent;
    background: transparent;
  }
`
