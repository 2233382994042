import React, { FunctionComponent } from 'react'
import styled from 'styled-components'

import { BrandColors } from '../../tokens/colors'

interface Props {
  currentStep: number
  totalSteps?: number
}

const ProgressBar: FunctionComponent<Props> = ({ totalSteps, currentStep }) => (
  <Wrapper>
    <ProgressIndicator totalSteps={totalSteps} currentStep={currentStep} />
  </Wrapper>
)

export default ProgressBar

const Wrapper = styled.div`
  width: 100%;
  height: 4px;
  background-color: ${BrandColors.steelGray.toString()};
`

const ProgressIndicator = styled.div<{
  currentStep: number
  totalSteps?: number
}>`
  ${({ totalSteps, currentStep }) =>
    totalSteps
      ? `width: calc(
    100% / ${totalSteps} * ${currentStep}
  );`
      : `width: 5%;`}
  height: 4px;
  transition: width 0.5s ease-in-out;
  background-color: ${BrandColors.springGreen.toString()};
`
