import React, { createContext, Dispatch, FunctionComponent, ReactNode, useState } from 'react'

export interface DrawerContext {
  drawerOpen?: boolean
  setDrawerOpen: Dispatch<boolean>
}

export const drawerContext = createContext<DrawerContext>({
  drawerOpen: false,
  setDrawerOpen: () => undefined,
})

interface DrawerProviderProps {
  children: ReactNode
}

const DrawerProvider: FunctionComponent<React.PropsWithChildren<DrawerProviderProps>> = (props) => {
  useState(false)
  const [drawerOpen, setDrawerOpen] = useState(false)
  const context = {
    drawerOpen,
    setDrawerOpen,
  }

  return <drawerContext.Provider value={context}>{props.children}</drawerContext.Provider>
}

export default DrawerProvider

export const DrawerConsumer = drawerContext.Consumer
