import React, { type FunctionComponent, useRef } from 'react'
import styled from 'styled-components'
import { v4 as uuid } from 'uuid'

import { BrandColors, SystemColors } from '../../tokens/colors'
import { Opacities } from '../../tokens/opacities'
import { Transitions } from '../../tokens/transitions'
import { rem } from '../../utils/rem'
import Flex from '../flex/Flex'
import { type InputProps } from '../input/Input'

export interface LabeledToggleProps extends Omit<InputProps, 'type' | 'value'> {
  disabled?: boolean
  error?: boolean
  label?: string
  offLabel?: string
  onLabel?: string
  width?: 'medium' | 'large'
}

const LabeledToggle: FunctionComponent<React.PropsWithChildren<LabeledToggleProps>> = (props) => {
  const idRef = useRef(props.id ?? uuid())

  return (
    <Flex style={{ position: 'relative' }}>
      <NativeCheckbox id={idRef.current} aria-label={props.label} {...props} />
      <Checkbox
        aria-label={props.label}
        checked={props.checked}
        error={props.error}
        disabled={props.disabled}
        onLabel={props.onLabel}
        offLabel={props.offLabel}
        width={props.width}
        htmlFor={idRef.current}
      />
      <OffLabel width={props.width}>{props.offLabel ?? 'Free'}</OffLabel>
      <OnLabel width={props.width}>{props.onLabel ?? 'Paid'}</OnLabel>
    </Flex>
  )
}

export default LabeledToggle

const OffLabel = styled.span<Pick<LabeledToggleProps, 'width'>>`
  color: ${BrandColors.black.toString()};
  font-size: 1rem;
  text-align: center;
  position: absolute;
  pointer-events: none;
  width: ${({ width }) => rem(width === 'medium' ? 56 : 71)};
  top: ${rem(3)};
  bottom: ${rem(3)};
  left: ${rem(3)};
  line-height: ${rem(26)};
  height: ${rem(26)};
  transition: ${Transitions.micro};
`

const OnLabel = styled(OffLabel)`
  left: auto;
  right: ${rem(3)};
  color: ${BrandColors.white.toString()};
  opacity: 0.55;
`

const Checkbox = styled.label<LabeledToggleProps>`
  position: relative;
  cursor: pointer;
  width: ${({ width }) => rem(width === 'medium' ? 118 : 142)};
  height: ${rem(32)};
  border-radius: ${rem(15)};
  flex-shrink: 0;
  border: ${rem(1)} solid ${BrandColors.white.alpha(Opacities.thirtyfive).toString()};

  :hover {
    background-color: ${BrandColors.white.alpha(Opacities.eight).toString()};
  }

  input:focus + & {
    ::before {
      content: '';
      border: ${rem(1)} solid ${SystemColors.info.toString()};
      position: absolute;
      width: ${({ width }) => rem(width === 'medium' ? 124 : 144)};
      height: ${rem(38)};
      z-index: -1;
      top: ${rem(-4)};
      right: ${rem(-4)};
      border-radius: ${rem(100)};
    }
  }

  input:disabled + & {
    opacity: ${Opacities.thirtyfive};
    pointer-events: none;
    cursor: auto;
  }

  &::after {
    content: '';
    display: block;
    border-radius: ${rem(100)};
    width: ${({ width }) => rem(width === 'medium' ? 56 : 66)};
    height: ${rem(26)};
    margin-top: ${rem(2)};
    margin-left: ${rem(2)};
    background-color: ${BrandColors.white.toString()};
    transition: ${Transitions.micro};
  }

  ${({ error }) =>
    error &&
    `
    border:${rem(1)} solid
    ${SystemColors.danger.toString()};
  `}
`
const NativeCheckbox = styled.input.attrs({
  type: 'checkbox',
})<LabeledToggleProps>`
  position: absolute;
  z-index: -999;
  opacity: 0;

  &:checked + ${Checkbox} {
    :hover {
      background-color: ${BrandColors.steelGray.lighten(Opacities.sixteen).toString()};
    }

    input:focus + & {
      background-color: ${BrandColors.white.alpha(Opacities.eight).toString()};
      outline-color: ${SystemColors.info.alpha(Opacities.fiftyfive).toString()};
    }

    input:disabled + & {
      opacity: ${Opacities.thirtyfive};
    }

    ${({ error }) =>
      error &&
      `
    background-color: ${SystemColors.danger.toString()};

    :hover {
      background-color: ${SystemColors.danger.lighten(Opacities.eight).toString()};
    }
  `}

    &::after {
      content: '';
      border-radius: ${rem(100)};
      display: block;
      width: ${({ width }) => rem(width === 'medium' ? 56 : 75)};
      height: ${rem(26)};
      margin-left: ${({ width }) => rem(width === 'medium' ? 59 : 64)};
      background-color: ${BrandColors.springGreen.toString()};
      transition: ${Transitions.micro};
    }
  }

  &:checked ~ ${OffLabel} {
    color: ${BrandColors.white.toString()};
    opacity: 0.55;
  }

  &:checked ~ ${OnLabel} {
    color: ${BrandColors.black.toString()};
    opacity: 1;
  }
`
