import { MediaQueries, rem } from 'ledo-react'
import styled from 'styled-components'

const PaddedContainer = styled.div`
  position: relative;
  padding: 0 ${rem(20)};

  ${MediaQueries.desktop} {
    padding: 0 ${rem(112)};
  }
`

export default PaddedContainer
