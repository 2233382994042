import React, { FunctionComponent } from 'react'

const SearchIcon: FunctionComponent<React.PropsWithChildren<unknown>> = () => (
  <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0502 13.4633C10.7884 14.4273 9.21154 15 7.50098 15C3.35884 15 0.000976562 11.6421 0.000976562 7.5C0.000976562 3.35786 3.35884 0 7.50098 0C11.6431 0 15.001 3.35786 15.001 7.5C15.001 9.2105 14.4284 10.7873 13.4644 12.0491L18.7082 17.2928L17.2939 18.7071L12.0502 13.4633ZM13.001 7.5C13.001 10.5376 10.5385 13 7.50098 13C4.46341 13 2.00098 10.5376 2.00098 7.5C2.00098 4.46243 4.46341 2 7.50098 2C10.5385 2 13.001 4.46243 13.001 7.5Z"
      fill="currentColor"
    />
  </svg>
)

export default SearchIcon
