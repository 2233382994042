import Color from 'color'
import styled, { css } from 'styled-components'

import { IconData } from '../../tokens/icons'
import { MediaQueries } from '../../tokens/mediaQueries'
import { FontFamily } from '../../tokens/typography'

export interface ResponsiveSize {
  desktop?: string | number
  mobile?: string | number
}

export interface IconProps {
  color?: Color | string
  icon: IconData
  size?: string | number | ResponsiveSize
}

const Icon = styled.span.attrs<IconProps>((props) => ({
  className: `icon-${props.icon?.key}`,
}))<IconProps>(
  ({ size = 24, color, icon }) => css`
    /**
     * The !important flag is used to prevent issues with browser extensions that
     * change the font family.
     */
    font-family: ${FontFamily.icons} !important;
    color: ${color ?? 'inherit'};
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    display: inline-flex;
    justify-content: center;
    align-items: center;

    ${() =>
      typeof size === 'string' &&
      css`
        font-size: ${size};
        width: ${size};
        height: ${size};
      `}

    ${() =>
      typeof size === 'number' &&
      css`
        font-size: ${size}px;
        width: ${size}px;
        height: ${size}px;
      `}

  ${() =>
      typeof size === 'object' &&
      css`
        font-size: ${size.mobile};
        width: ${size.mobile};
        height: ${size.mobile};

        ${MediaQueries.desktop} {
          font-size: ${size.desktop};
          width: ${size.desktop};
          height: ${size.desktop};
        }
      `}

  &:before {
      content: '${icon?.glyph ?? ''}';
    }

    /* Custom CSS for certain icons. */
    &.icon-hour:before {
      transform: scale(0.75);
    }
  `,
)

export default Icon
