import React, { FunctionComponent, SVGAttributes } from 'react'
import styled, { css } from 'styled-components'

import { BrandColors } from '../../tokens/colors'

export interface CombinationMarkProps extends SVGAttributes<SVGSVGElement> {
  colored?: boolean
}

const CombinationMark: FunctionComponent<React.PropsWithChildren<CombinationMarkProps>> = ({ colored, ...props }) => (
  <svg width="160" height="76" viewBox="0 0 160 76" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M80.3928 3.61748V30.5613C77.1083 14.4342 63.2091 2.70811 44.8728 2.70811C23.8194 2.70811 8.50684 18.0688 8.50684 38.0007C8.50684 58.1271 23.7187 73.2933 44.772 73.2933C63.1957 73.2933 77.1068 61.7131 80.3928 45.4945V72.3825H151.64V3.61748H80.3928Z"
      fill={colored ? BrandColors.springGreen.toString() : 'white'}
    />
    <path
      d="M34.0902 30.9087C30.1465 30.9087 27.2798 33.7856 27.2798 37.5189C27.2798 41.2879 30.1287 44.129 34.0724 44.129C38.0339 44.129 40.8828 41.2707 40.8828 37.5189C40.8828 33.8041 38.0339 30.9087 34.0902 30.9087ZM33.8798 41.0005C31.8872 41.0005 30.2724 39.442 30.2724 37.5189C30.2724 35.5957 31.8872 34.0372 33.8798 34.0372C35.8724 34.0372 37.4872 35.5957 37.4872 37.5189C37.4872 39.442 35.8724 41.0005 33.8798 41.0005Z"
      fill="black"
    />
    <path
      d="M145.773 29C146.929 29 147.643 29.7764 147.643 30.753C147.643 31.7038 146.938 32.5145 145.8 32.5145C144.671 32.5145 143.948 31.7467 143.948 30.7616C143.948 29.7836 144.671 29 145.773 29ZM145.791 32.1814C146.73 32.1814 147.264 31.5794 147.264 30.7515C147.264 29.9151 146.722 29.3303 145.773 29.3303C144.87 29.3303 144.327 29.9323 144.327 30.7601C144.327 31.5623 144.87 32.1814 145.791 32.1814ZM145.593 30.9617V31.7024H145.222V29.8193H145.828C146.262 29.8193 146.496 30.0366 146.496 30.3855C146.496 30.63 146.362 30.8045 146.199 30.8731L146.569 31.701H146.181L145.883 30.9603H145.593V30.9617ZM145.593 30.141V30.6386H145.864C146.018 30.6386 146.099 30.5342 146.099 30.3855C146.099 30.2368 146.009 30.141 145.864 30.141H145.593Z"
      fill="black"
    />
    <path
      d="M16.394 37.5775C16.394 33.8242 19.277 30.9502 23.4118 30.9502C25.0622 30.9502 26.3333 31.4077 27.2814 32.0297L26.3703 34.7764C25.6681 34.3189 24.6637 33.8799 23.5244 33.8799C21.0963 33.8799 19.7689 35.5829 19.7689 37.5775C19.7689 39.5735 21.0963 41.3122 23.5244 41.3122C24.6622 41.3122 25.7244 40.8732 26.4266 40.4157L27.3377 43.1624C26.3896 43.7844 25.0622 44.2419 23.4118 44.2419C19.1259 44.2405 16.394 41.348 16.394 37.5775Z"
      fill="black"
    />
    <path d="M42.1553 43.9946V30.5985H45.3982V40.9033H49.3819V43.9946H42.1553Z" fill="black" />
    <path d="M50.8057 43.9946V30.5985H54.0486V40.9033H58.0323V43.9946H50.8057Z" fill="black" />
    <path
      d="M63.1288 41.1292L62.1807 44.0031H58.9185L63.5555 31.056H66.8755L71.5125 44.0031H68.2503L67.3022 41.1292H63.1288ZM65.2162 34.5019L63.9644 38.5669H66.4681L65.2162 34.5019Z"
      fill="black"
    />
    <path
      d="M77.6609 44.0032H72.7676V31.1346H77.5098C80.2031 31.1346 81.815 32.4329 81.815 34.6292C81.815 35.7273 81.2831 36.7896 80.3735 37.2829C81.6816 37.8134 82.2327 38.8572 82.2327 40.1755C82.2327 42.7764 80.2031 44.0032 77.6609 44.0032ZM75.9913 33.8613V36.1862H77.3765C78.1157 36.1862 78.5142 35.6915 78.5142 34.9966C78.5142 34.3189 78.135 33.8613 77.3379 33.8613H75.9913ZM75.9913 38.6942V41.3122H77.5661C78.4194 41.3122 78.8935 40.726 78.8935 39.9939C78.8935 39.2246 78.4387 38.6942 77.5854 38.6942H75.9913Z"
      fill="black"
    />
    <path
      d="M94.7941 44.0032H91.5511V38.9873H86.8089V44.0032H83.5659V31.1875H86.8089V36.1677H91.5511V31.1875H94.7941V44.0032Z"
      fill="black"
    />
    <path
      d="M112.184 39.0788V31.1875H115.427V39.0788C115.427 40.47 116.299 41.3308 117.741 41.3308C119.201 41.3308 120.016 40.47 120.016 39.0602V31.1875H123.259V39.0602C123.259 42.0629 121.229 44.2233 117.72 44.2233C114.155 44.2233 112.184 42.1358 112.184 39.0788Z"
      fill="black"
    />
    <path
      d="M133.714 33.9157L131.021 35.1053C130.756 34.5377 130.357 33.8413 129.351 33.8413C128.725 33.8413 128.308 34.2074 128.308 34.7021C128.308 36.4408 133.848 36.2577 133.848 40.1755C133.848 42.5376 132.102 44.2391 129.2 44.2391C126.222 44.2391 125.027 42.1529 124.686 41.1821L127.399 39.8824C127.759 40.633 128.48 41.3108 129.39 41.3108C130.168 41.3108 130.566 40.9819 130.566 40.4143C130.566 38.7113 125.065 38.8586 125.065 34.7578C125.065 32.4515 126.791 30.9502 129.428 30.9502C132.273 30.9502 133.373 32.9448 133.714 33.9157Z"
      fill="black"
    />
    <path
      d="M135.736 44.0032V31.1875H142.812V33.9886H138.981V36.1305H142.243V38.9129H138.981V41.2021H142.963V44.0032H135.736Z"
      fill="black"
    />
    <path
      d="M96.8638 31.1118V43.9903H110.207V31.1118H96.8638ZM107.261 40.9547H100.21V34.1502H107.261V40.9547Z"
      fill="black"
    />
  </svg>
)

CombinationMark.displayName = 'CombinationMark'

export default CombinationMark

const Svg = styled.svg<CombinationMarkProps>(
  ({ colored }) => css`
    ${colored &&
    css`
      fill: ${BrandColors.springGreen.toString()};
    `}
  `,
)
