import styled, { css } from 'styled-components'

import { BrandColors } from '../../tokens/colors'
import { MediaQueries } from '../../tokens/mediaQueries'
import { FontFamily } from '../../tokens/typography'
import { rem } from '../../utils/rem'
import Text, { type TextProps } from '../text/Text'

export type BigTitleSize = 'xSmall' | 'small' | 'medium' | 'large' | 'xLarge' | 'xxLarge'

export interface BigTitleProps extends TextProps {
  colored?: boolean
  size?: BigTitleSize
}

const BigTitle = styled(Text).attrs({
  as: 'h1',
})<BigTitleProps>(
  ({ size = 'medium', colored }) => css`
    font-family: ${FontFamily.regular};
    text-transform: uppercase;
    font-weight: bold;

    ${colored &&
    css`
      color: ${BrandColors.tickleMePink.toString()};
    `}

    ${() => {
      switch (size) {
        case 'xSmall':
          return css`
            font-size: ${rem(52)};
            line-height: ${rem(52)};

            ${MediaQueries.desktop} {
              font-size: 5vw;
              line-height: 5vw;
            }

            @media (min-width: 1330px) {
              font-size: ${rem(72)};
              line-height: ${rem(76)};
            }
          `

        case 'small':
          return css`
            font-size: ${rem(96)};
            line-height: ${rem(76)};

            ${MediaQueries.desktop} {
              font-size: ${rem(160)};
              line-height: ${rem(128)};
            }
          `

        case 'medium':
          return css`
            font-size: ${rem(104)};
            line-height: ${rem(80)};

            ${MediaQueries.desktop} {
              font-size: ${rem(200)};
              line-height: ${rem(160)};
            }
          `

        case 'large':
          return css`
            font-size: ${rem(112)};
            line-height: ${rem(88)};

            ${MediaQueries.desktop} {
              font-size: ${rem(240)};
              line-height: ${rem(184)};
            }
          `

        case 'xLarge':
          return css`
            font-size: ${rem(120)};
            line-height: ${rem(96)};

            ${MediaQueries.desktop} {
              font-size: ${rem(280)};
              line-height: ${rem(216)};
            }
          `

        case 'xxLarge':
          return css`
            font-size: ${rem(160)};
            line-height: ${rem(128)};

            ${MediaQueries.desktop} {
              font-size: ${rem(400)};
              line-height: ${rem(304)};
            }
          `
      }
    }}
  `,
)

export default BigTitle
