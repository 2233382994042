import React, { Dispatch, FC, ReactNode, useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import styled from 'styled-components'

import { BrandColors } from '../../tokens/colors'
import { MediaQueries } from '../../tokens/mediaQueries'
import { rem } from '../../utils/rem'
import Body from '../body/Body'
import Flex from '../flex/Flex'
import Padding from '../padding/Padding'
import PrimaryButton from '../primary-button/PrimaryButton'
import Row from '../row/Row'
import SecondaryButton from '../secondary-button/SecondaryButton'
import SizedBox from '../sized-box/SizedBox'
import Square from '../square/Square'
import Title from '../title/Title'

const ProgressIndicator: FC<React.PropsWithChildren<{ current: number; total: number }>> = ({ total, current }) => (
  <Row>
    {[...new Array(total).fill(null)].map((item, index) => (
      <>
        <Square
          size={rem(12)}
          color={index <= current ? BrandColors.springGreen.toString() : BrandColors.white.toString()}
          key={index}
          style={{ cursor: 'pointer' }}
          onClick={() => scrollToSlide(index)}
        />
        <SizedBox width={rem(8)} />
      </>
    ))}
  </Row>
)

const Item: FC<
  React.PropsWithChildren<{
    children: ReactNode
    index: number
    setCurrent: Dispatch<number>
  }>
> = ({ children, index, setCurrent }) => {
  const { ref, inView } = useInView({ threshold: 0.1 })

  useEffect(() => {
    if (inView) {
      setCurrent(index)
    }
  }, [inView, index, setCurrent])

  return (
    <SlideItem ref={ref} id={`slide-${index}`}>
      {children}
    </SlideItem>
  )
}

export interface Slide {
  description: ReactNode
  images: {
    desktopSrc: string
    mobileSrc: string
  }
  title: string
}
export interface SlideDeckProps {
  onClose: () => void
  slides: Slide[]
}

const scrollToSlide = (index: number) => window?.document?.querySelector(`#slide-${index}`)?.scrollIntoView()

const SlideDeck: FC<React.PropsWithChildren<SlideDeckProps>> = ({ slides, onClose }) => {
  const [current, setCurrent] = useState(0)
  return (
    <SlideWrapper>
      <Flex>
        {slides.map((slide, index) => (
          <Item key={index} setCurrent={setCurrent} index={index}>
            <ImageWrapper>
              <img src={slide.images.desktopSrc} />
              <img src={slide.images.mobileSrc} />
            </ImageWrapper>
            <Padding top={rem(40)} horizontal={rem(40)}>
              <Title size="medium">{slide.title}</Title>
              <SizedBox height={rem(16)} />

              <Body size="large">{slide.description}</Body>
            </Padding>
          </Item>
        ))}
      </Flex>
      <Navigation>
        <Flex>
          <MobileOnly>
            {current > 0 && (
              <>
                <SecondaryButton
                  onClick={() => {
                    if (current !== 0) {
                      scrollToSlide(current - 1)
                    }
                  }}
                >
                  Back
                </SecondaryButton>
                <SizedBox width={rem(16)} />
              </>
            )}
          </MobileOnly>

          <PrimaryButton
            onClick={() => {
              if (current + 1 !== slides.length) {
                scrollToSlide(current + 1)
              } else {
                onClose()
              }
            }}
          >
            {current !== slides.length - 1 ? 'Next' : 'Finish'}
          </PrimaryButton>
        </Flex>

        <SizedBox height={rem(32)} />

        <ProgressIndicator total={slides.length} current={current} />
      </Navigation>
    </SlideWrapper>
  )
}

const Navigation = styled.div`
  position: sticky;
  left: 0;
  width: 100%;
  padding: ${rem(40)};
  padding-top: ${rem(32)};
  display: flex;
  align-items: center;
  align-content: center;
  flex-direction: column;
`

const SlideWrapper = styled(Row)`
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`
const MobileOnly = styled.div`
  display: flex;

  ${MediaQueries.desktop} {
    display: none;
  }
`

const ImageWrapper = styled.div`
  display: flex;
  background: linear-gradient(115.72deg, #a58aff 1.23%, #5d2efb 66.26%);

  img {
    width: 100%;
  }

  img:first-of-type {
    aspect-ratio: 92 / 42;
    display: none;

    ${MediaQueries.desktop} {
      display: block;
    }
  }

  img:last-of-type {
    aspect-ratio: 317 / 256;

    ${MediaQueries.desktop} {
      display: none;
    }
  }
`

const SlideItem = styled.div`
  width: 100%;
  scroll-snap-align: center;
  flex-shrink: 0;
  text-align: center;
`

export default SlideDeck
