import { type CognitoUser } from 'amazon-cognito-identity-js'
import { isAfter, isBefore } from 'date-fns'
import { Body, type IconData, Icons } from 'ledo-react'
import { default as React, type ReactNode } from 'react'

import { type Storage } from '@/components/promotion-banner/PromotionBanner'
import { GroupRole } from '@/graphql/generated'
import { type Group } from '@/providers/GroupProvider'

export interface MaybeGetActiveDistributionPromotionOptions {
  group: Group | undefined
  isCreatingProfile: boolean
  user: CognitoUser | undefined
}

export interface DistributionPromotion {
  /**
   * The description of the promotiion (e.g. used by a promotional banner).
   */
  description: ReactNode | string

  /**
   * Promotional text to show in the dashboard to promote making releases.
   */
  distributionDescription: ReactNode | string

  /**
   * Start date of the promotion.
   */
  from: Date

  /**
   * Icon to show in the promotion banner (optional).
   */
  icon?: IconData

  /**
   * Label to pull attention to the promotion.
   */
  label?: { icon: IconData; title?: string }

  /**
   * The lead text (shown in  bold) for the promotion banner.
   */
  lead?: string

  /**
   * Storage method to store if the promotion should be hidden or not.
   */
  storage: Storage

  /**
   * End date (exclusive) of the promotion.
   */
  until: Date

  /**
   * Unique identifier for the promotion, used to identify if the promotion has been hidden or not.
   */
  uuid: string
}

const promotions: DistributionPromotion[] = [
  {
    description: (
      <>
        Thanks to you, we&#39;re celebrating 100K members with <b>free distribution in July!</b>
      </>
    ),
    distributionDescription: (
      <Body size="medium">
        <p>
          Get your music on Spotify, YouTube, TikTok, and 60+ platforms for FREE. Keep 100% of your earnings, get paid
          monthly. This exclusive offer is only available in July. Don’t miss out.
        </p>
      </Body>
    ),
    from: new Date('2024-07-01T00:00:00Z'),
    icon: Icons.gift,
    label: { icon: Icons.gift, title: 'Free until July 31' },
    lead: 'REWARD',
    storage: 'cookie',
    until: new Date('2024-08-01T00:00:00Z'),
    uuid: 'free-distribution-july',
  },
  {
    description: (
      <>
        Submit your track for a chance to win a{' '}
        <a href="https://www.collabhouse.com/promotion/win-promo-budget">€5000 promotion bundle</a>. Ends September 9th.
      </>
    ),
    distributionDescription: (
      <Body size="medium">
        <p>
          <b>Win €5000 promo budget to skyrocket your music</b>.<br />
          Are you a talented producer looking to take your music career to the next level? Collabhouse is here to make
          that happen with an exclusive TikTok campaign.
        </p>
      </Body>
    ),
    from: new Date('2024-08-16T00:00:00Z'),
    icon: Icons.gift,
    label: { icon: Icons.gift, title: 'Upload and win!' },
    lead: 'CONTEST',
    storage: 'sessionStorage',
    until: new Date('2024-09-09T00:00:00Z'),
    uuid: '5k',
  },
]

export const maybeGetActiveDistributionPromotion = ({
  group,
  user,
  isCreatingProfile,
}: MaybeGetActiveDistributionPromotionOptions): DistributionPromotion | undefined => {
  if (!user || isCreatingProfile) {
    return undefined
  }

  if (group?.role !== GroupRole.Artist && group?.role !== GroupRole.Label) {
    return undefined
  }

  const now = new Date()

  return promotions.find((promotion) => isBefore(promotion.from, now) && isAfter(promotion.until, now))
}
