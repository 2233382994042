import React, { type FunctionComponent } from 'react'
import styled, { css } from 'styled-components'

import { Portals } from '../../tokens/portals'
import { rem } from '../../utils/rem'
import AbsorbScroll from '../absorb-scroll/AbsorbScroll'
import Overlay from '../overlay/Overlay'

type position = 'left' | 'right'

export interface DrawerProps {
  onClose?: () => void
  open?: boolean
  portal?: string
  position?: position
  width?: string
}

const Drawer: FunctionComponent<React.PropsWithChildren<DrawerProps>> = ({
  children,
  open,
  onClose,
  portal = Portals.navigation,
  position = 'left',
  width = rem(288),
  ...overlayProps
}) => (
  <Overlay visible={open} portal={portal} {...overlayProps}>
    <AbsorbScroll absorbing={open}>
      <Scrim onClick={onClose} />
    </AbsorbScroll>
    <Content width={width} position={position}>
      {children}
    </Content>
  </Overlay>
)

const Scrim = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  :hover {
    cursor: pointer;
  }
`

interface ContentProps {
  position: position
  width: string
}

const Content = styled.span<ContentProps>`
  position: fixed;
  top: 0;
  width: ${({ width }) => width};
  max-width: 100%;
  height: 100vh;
  overflow-y: auto;

  ${({ position }) => css`
    ${position === 'left' &&
    css`
      left: 0;
    `}

    ${position === 'right' &&
    css`
      right: 0;
    `}
  `}
`

export default Drawer
