import 'flag-icons/css/flag-icons.min.css'

import React, { type FunctionComponent } from 'react'
import { ThemeProvider } from 'styled-components'

import SnackbarProvider from '../../providers/SnackbarProvider'
import blackcurrantTheme from '../../themes/blackcurrantTheme'
import { type LedoTheme } from '../../themes/LedoTheme'
import GlobalBackground from './global-styles/GlobalBackground'
import GlobalFonts from './global-styles/GlobalFonts'
import GlobalHeight from './global-styles/GlobalHeight'
import GlobalReset from './global-styles/GlobalReset'
import GlobalScale from './global-styles/GlobalScale'
import GlobalText from './global-styles/GlobalText'

export interface AppProps {
  rootSelector?: string
  theme?: LedoTheme
}

const App: FunctionComponent<React.PropsWithChildren<AppProps>> = ({
  rootSelector = '#___gatsby, #gatsby-focus-wrapper',
  theme = blackcurrantTheme,
  ...props
}) => (
  <ThemeProvider theme={theme}>
    <GlobalReset />
    <GlobalHeight rootSelector={rootSelector} />
    <GlobalBackground />
    <GlobalFonts />
    <GlobalScale />
    <GlobalText />
    <SnackbarProvider>{props.children}</SnackbarProvider>
  </ThemeProvider>
)

export default App
