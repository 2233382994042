import React, { FunctionComponent } from 'react'
import styled from 'styled-components'

import { MediaQueries } from '../../tokens/mediaQueries'
import { rem } from '../../utils/rem'
import Body from '../body/Body'
import Column from '../column/Column'
import Dialog from '../dialog/Dialog'
import IndeterminateProgress from '../indeterminate-progress/IndeterminateProgress'
import SizedBox from '../sized-box/SizedBox'
import Title from '../title/Title'

export interface LoadingDialogProps {
  description?: string
  title?: string
  visible: boolean
}

const LoadingDialog: FunctionComponent<React.PropsWithChildren<LoadingDialogProps>> = ({
  title,
  description,
  visible,
}) => (
  <Dialog visible={visible} size="small">
    <Content>
      <IndeterminateProgress
        size={{
          desktop: rem(96),
          mobile: rem(72),
        }}
      />

      <SizedBox height={rem(16)} />

      {title && <Title size="xxSmall">{title}</Title>}

      {title && description && <SizedBox height={{ desktop: rem(16), mobile: 0 }} />}

      {description && <Body size="small">{description}</Body>}
    </Content>
  </Dialog>
)

export default LoadingDialog

const Content = styled(Column)`
  text-align: center;
  overflow: hidden;
  padding: ${rem(24)} 0;

  ${MediaQueries.desktop} {
    padding: ${rem(32)} 0;
  }
`
