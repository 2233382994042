interface BaseMusicLibraryContentType {
  desc: string
  title: string
}

export interface MusicLibraryContentTypeParent extends BaseMusicLibraryContentType {
  children: MusicLibraryContentTypeChild[]
}

export interface MusicLibraryContentTypeChild extends BaseMusicLibraryContentType {
  key: string
}

export type MusicLibraryContentType = MusicLibraryContentTypeParent | MusicLibraryContentTypeChild

export interface MusicLibraryContentTypeCategory {
  children: MusicLibraryContentType[]
  desc: string
  title: string
}

const categories: MusicLibraryContentTypeCategory[] = [
  {
    children: [
      {
        desc: "Non-branded and non-sponsored social media posts only that don't include advertisement",
        key: 'socialMediaNonBranded',
        title: 'Non Branded (excl Ads)',
      },
      {
        desc: 'Branded or sponsored social media posts and posts that include advertisement',
        key: 'socialMediaBranded',
        title: 'Branded (incl Ads)',
      },
    ],
    desc: 'Includes use of the track in content you post on social media platforms such as Facebook, Instagram, TikTok, YouTube, Twitch, Triller, Snapchat, Twitter.',
    title: 'Social Media',
  },
  {
    children: [
      {
        desc: 'Non-branded and non-sponsored content only that does not include advertisement',
        key: 'podcastPrivateNonBranded',
        title: 'Non Branded (excl Ads)',
      },
      {
        desc: 'Branded or sponsored content and content that includes advertisement',
        key: 'podcastPrivateBranded',
        title: 'Branded (incl Ads)',
      },
    ],
    desc: 'Includes use of the track in a podcast, during a live stream (audio-visual and audio only) and in a private film (private use: ie. wedding, birthday films)',
    title: 'Podcast, Live Streaming & Private Filmmaking',
  },
  {
    children: [
      {
        desc: 'Company presentation or event',
        key: 'corporateNonProfitCompanyPresentation',
        title: 'Company Presentation',
      },
      {
        desc: 'Educational content',
        key: 'corporateNonProfitEducational',
        title: 'Educational',
      },
      {
        desc: 'Content published by a non-profit or charity organization',
        key: 'corporateNonProfitCharity',
        title: 'Non-Profit / Charity',
      },
    ],
    desc: 'Includes use of the track in content published or used in and during corporate presentations and events, by non-profit and charity organizations and for educational purposes',
    title: 'Corporate & Non-Profit',
  },
  {
    children: [
      {
        desc: 'In-game or In-App',
        key: 'gamesAppsInAppGame',
        title: 'In Game & In App',
      },
      {
        desc: 'Game trailer or promotional material for an App',
        key: 'gamesAppsTrailerPromo',
        title: 'Trailer / Promo',
      },
    ],
    desc: 'Includes use of the track in a game or App, in a game trailer or promotional material for an App ',
    title: 'Game & App',
  },
  {
    children: [
      {
        desc: 'Advertisement in all types of media (TV, radio, online, public location)',
        key: 'advertisementAllMedia',
        title: 'All Media',
      },
      {
        desc: 'Online advertisement only',
        key: 'advertisementOnlineOnly',
        title: 'Online Only',
      },
      {
        desc: 'Audio-only advertisement (jncluding radio and public location)',
        key: 'advertisementAudioOnly',
        title: 'Audio Only',
      },
    ],
    desc: 'Includes use of the track in advertisement',
    title: 'Advertisement',
  },
  {
    children: [
      {
        desc: 'TV show or program',
        key: 'radioTvTvShow',
        title: 'TV show',
      },
      {
        desc: 'Trailer or promo for a TV show or program',
        key: 'radioTvTrailer',
        title: 'TV Show Trailer',
      },
      {
        desc: 'Radio show or promo for a radio show',
        key: 'radioTvAudioOnly',
        title: 'Radio',
      },
    ],
    desc: 'Includes use of the track in content broadcast on TV or radio, including TV trailers and promos for radio shows',
    title: 'Radio & TV',
  },
  {
    children: [
      {
        children: [
          {
            desc: 'Online, cinema, TV',
            key: 'filmSmallAllMedia',
            title: 'All Media',
          },
          {
            desc: 'Online only',
            key: 'filmSmallOnlineOnly',
            title: 'Online Only',
          },
          {
            desc: 'Trailer for a film, series or documentary',
            key: 'filmSmallTrailer',
            title: 'Trailer',
          },
        ],
        desc: 'Includes use of the track in a film, documentary or in a series with a budget of less than EUR 5M',
        title: 'Small/Medium Budget <5M EUR',
      },
      {
        children: [
          {
            desc: 'Online, cinema, TV',
            key: 'filmLargeAllMedia',
            title: 'All Media',
          },
          {
            desc: 'Online only',
            key: 'filmLargeOnlineOnly',
            title: 'Online Only',
          },
          {
            desc: 'Trailer for a film, series or documentary',
            key: 'filmLargeTrailer',
            title: 'Trailer',
          },
        ],
        desc: 'Includes use of the track in a film, documentary or in a series with a budget of more than EUR 5M',
        title: 'Large Budget >5M EUR',
      },
    ],
    desc: 'Includes use of the track in a film, documentary or in an episode of a series',
    title: 'Film',
  },
]

export const musicLibraryContentTypeOptions = categories.flatMap((category) => ({
  label: category.title,
  options: category.children.flatMap((topLevel) =>
    'children' in topLevel
      ? topLevel.children.map((child) => ({
          label: `${topLevel.title} / ${child.title}`,
          value: child.key,
        }))
      : {
          label: topLevel.title,
          value: topLevel.key,
        },
  ),
}))

export default categories
