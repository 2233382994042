import React from 'react'
import styled, { css } from 'styled-components'

import { SystemColors } from '../../tokens/colors'
import { Opacities } from '../../tokens/opacities'
import { Transitions } from '../../tokens/transitions'
import { rem } from '../../utils/rem'
import Padding from '../padding/Padding'

export interface OutlineProps {
  borderRadius?: string
  color?: string
  offset?: string
  visible?: boolean
  width?: string
}
const Inner = styled(Padding)`
  position: relative;
  width: 100%;
  height: 100%;
`

const Outline = styled.div.attrs<OutlineProps>(({ children, offset = rem(3), width = '1px' }) => ({
  children: children ? <Inner all={`calc(${offset} - ${width})`}>{children}</Inner> : null,
}))<OutlineProps>(
  ({
    borderRadius = '0',
    children,
    color = SystemColors.info.alpha(Opacities.fiftyfive).toString(),
    offset = rem(3),
    visible = true,
    width = '1px',
  }) => css`
    position: absolute;
    top: -${offset};
    left: -${offset};
    width: calc(100% + ${offset} * 2);
    height: calc(100% + ${offset} * 2);
    border: ${width} solid transparent;
    border-radius: ${borderRadius};
    transition: ${Transitions.micro};
    transition-property: opacity, border;

    ${visible &&
    css`
      border-color: ${color};
    `};

    ${!children &&
    css`
      pointer-events: none;
    `};
  `,
)
export default Outline
