/**
 *
 * TODO: Mostly copied from ledo-web - should be re-used via @ledo-react
 *
 */

import { Column, ConstrainedBox, MediaQueries, rem } from 'ledo-react'
import React, { type FunctionComponent, type HTMLAttributes } from 'react'
import styled from 'styled-components'

export interface ContainerProps extends HTMLAttributes<HTMLDivElement> {
  center?: boolean
}

const Container: FunctionComponent<React.PropsWithChildren<ContainerProps>> = ({ center, children, ...props }) => (
  <ResponsiveColumn {...props} crossAxisAlignment="center" center={center}>
    <ConstrainedBox width="100%">
      <ResponsivePadding>{children}</ResponsivePadding>
    </ConstrainedBox>
  </ResponsiveColumn>
)

const ResponsiveColumn = styled(Column)<ContainerProps>`
  justify-content: flex-start;

  ${MediaQueries.desktop} {
    justify-content: ${(props) => props.center && 'center'};
  }
`

const ResponsivePadding = styled.div`
  padding: 0 ${rem(20)};

  ${MediaQueries.desktop} {
    padding: 0 ${rem(112)};
  }
`

export default Container
