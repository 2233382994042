import { type HTMLAttributes } from 'react'
import styled, { css } from 'styled-components'

export interface TextProps extends HTMLAttributes<HTMLSpanElement> {
  align?: 'left' | 'center' | 'right'
  block?: boolean
  fontSize?: string
  noWrap?: boolean
}

const Text = styled('span')<TextProps>`
  color: inherit;
  font-family: inherit;
  font-style: inherit;
  font-weight: inherit;
  font-size: ${(props) => props.fontSize ?? '1rem'};
  line-height: ${(props) => props.fontSize ?? '1rem'};
  ${(props) => (props.align ? `text-align: ${props.align};` : '')}
  ${(props) => (props.block ? 'display: block;' : '')}

  ${(props) =>
    props.noWrap &&
    css`
      max-width: 100%;
      display: inline-block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    `}
`

export default Text
