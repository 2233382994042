import React, { ReactNode } from 'react'

import ErrorMessage from './ErrorMessage'

interface ErrorBoundaryProps {
  children: ReactNode
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, { error: Error }> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { error: null }
  }

  static getDerivedStateFromError(error: Error): { error: Error } {
    // Update state so the next render will show the fallback UI.
    return { error }
  }

  render(): ReactNode {
    if (this.state.error) {
      return <ErrorMessage error={this.state.error} />
    }

    return this.props.children
  }
}

export default ErrorBoundary
