import { type IconData, Icons } from 'ledo-react'

import { BrandType, ContentType, Interest, SearchSpotifyArtist, VerifySocialAccountsResult } from '@/graphql/generated'

export enum Role {
  Artist = 'Artist',
  Brand = 'Brand / Creative',
  Fan = 'Fan',
  Influencer = 'Influencer',
  Label = 'Label / Publisher',
  Undefined = 'Not yet selected',
}

export const FunctionalityInfo: {
  [k in Interest]: {
    description: string
    icon: IconData
    label: string
  }
} = {
  distribution: {
    description: 'Global distribution to 65+ services',
    icon: Icons.distribution,
    label: 'Distribution',
  },
  licensing: {
    description: 'Make your music available for free or paid sync',
    icon: Icons.licensing,
    label: 'Licensing',
  },
  nft: {
    description: 'Sell, collect and trade music NFTs',
    icon: Icons.nft,
    label: 'NFT',
  },
  promotion: {
    description: 'Collab with content creators to promote music on social media',
    icon: Icons.campaign,
    label: 'Promotion',
  },
}

export enum RegistrationStepId {
  // Brand specific
  BrandType = 'What type of brand or creator are you?',
  BudgetForMusicLicense = "What's your budget for a single music license?",
  CompleteProfile = 'CompleteYourProfile',
  ConnectSocials = 'Connect your socials',
  // Influencer specific
  ContentType = 'What type of content do you create?',
  // Common
  CreateAccount = 'CreateAccount',
  // Artist specific
  CreateArtistProfile = 'Create your artist profile',

  Final = 'Final',

  // Fan specific
  InterestedGenres = 'What genres are you interested in?',
  InviteTeamMembers = 'InviteTeamMembers',

  SelectRole = 'WhichRoleSuitsYouBest',
  VerifyPhoneNumber = 'VerifyYourPhoneNumber',

  WhatAreYouLookingFor = 'WhatAreYouLookingFor',
}

const RegistrationStepDescription = {
  [RegistrationStepId.BrandType]: 'This is used to personalize your Collabhouse experience.',
  [RegistrationStepId.BudgetForMusicLicense]: 'This is used to personalize your Collabhouse experience.',
  [RegistrationStepId.ContentType]: 'Select at least one option.',
  [RegistrationStepId.ConnectSocials]: 'Enter user information to help complete your profile.',
  [RegistrationStepId.InterestedGenres]: 'Select at least one option. ',
}

export const RoleDescription = {
  [Role.Artist]: 'Distribute, promote and monetize your music',
  [Role.Label]: 'Promote and offer your catalog for sync licensing',
  [Role.Brand]: 'License music for any creative project',
  [Role.Influencer]: 'Get paid for using music in your videos',
  [Role.Fan]: 'Support favorite artist(s) with music NFTs',
}

export const RoleIcon = {
  [Role.Artist]: Icons.artist,
  [Role.Label]: Icons.publisher,
  [Role.Brand]: Icons.brand,
  [Role.Influencer]: Icons.campaign,
  [Role.Fan]: Icons.fan,
}

export class RegistrationStep {
  id?: RegistrationStepId
  next?: RegistrationStepId
  end?: boolean = false
  skippable?: boolean = false
  getTitle: () => string
  getDescription: () => string

  constructor({ id, end, skippable }: { end?: boolean; id?: RegistrationStepId; skippable?: boolean }) {
    this.id = id
    if (end !== undefined) this.end = end
    if (skippable !== undefined) this.skippable = skippable
    this.getTitle = (): string => this.id
    this.getDescription = (): string => RegistrationStepDescription[this.id]
  }
}

export interface GroupFormSteppedContext {
  artistProfile?: SearchSpotifyArtist
  gotoNextStep: () => void
  gotoPreviousStep: () => void
  isSubmitting: boolean
  onCancel: () => void
  progress: number
  resetSteps: () => void
  role: Role
  setArtistProfile: (profile: SearchSpotifyArtist) => void
  setIsSubmitting: (submitting: boolean) => void
  setRole: (role: Role) => void
  setSocials: (socials: VerifySocialAccountsResult) => void
  socials?: VerifySocialAccountsResult
  step: RegistrationStep
  totalSteps: number
}

export type LayoutRegistrationStepProps = {
  centered?: boolean
  children: React.ReactNode
  description?: string | null
  hideBack?: boolean
  hideFooter?: boolean
  nextText?: string
  onBack?: () => void
  onNext?: () => void
  skippable?: boolean
  skippableText?: string
  title: string
}

export const BrandTypeLabels: Record<BrandType, string> = {
  [BrandType.Agency]: 'Agency',
  [BrandType.Brand]: 'Brand',
  [BrandType.Freelance]: 'Freelance',
  [BrandType.NonProfit]: 'Non-Profit',
  [BrandType.YoutubeCreatorPodcaster]: 'YouTube Creator / Podcaster',
  [BrandType.SchoolUniversity]: 'School University',
  [BrandType.ProductionCompany]: 'Production Company',
  [BrandType.Other]: 'Other',
}

// TODO: should come from the backend?
export enum BudgetForMusicLicense {
  High = 'high',
  Low = 'low',
  LowMedium = 'lowMedium',
  Medium = 'medium',
  MediumHigh = 'mediumHigh',
  Undefined = 'undefined',
}

export const BudgetForMusicLicenseLabels: {
  [key in BudgetForMusicLicense]: string
} = {
  [BudgetForMusicLicense.Undefined]: 'Not yet selected',
  [BudgetForMusicLicense.Low]: '€0 - €99',
  [BudgetForMusicLicense.LowMedium]: '€100 - €249',
  [BudgetForMusicLicense.Medium]: '€250 - €499',
  [BudgetForMusicLicense.MediumHigh]: '€500 -€999',
  [BudgetForMusicLicense.High]: '€1,000+',
}

export const contentTypeLabels: { [key in ContentType]: string } = {
  [ContentType.Animals]: 'Animals',
  [ContentType.ArtAndPhotography]: 'Art & Photography',
  [ContentType.AthleteAndSports]: 'Athlete & Sports',
  [ContentType.Automotive]: 'Automotive',
  [ContentType.Beauty]: 'Beauty',
  [ContentType.Business]: 'Business',
  [ContentType.ComedyAndEntertainment]: 'Comedy & Entertainment',
  [ContentType.Culture]: 'Culture',
  [ContentType.Dance]: 'Dance',
  [ContentType.Family]: 'Family',
  [ContentType.Fashion]: 'Fashion',
  [ContentType.Food]: 'Food',
  [ContentType.Gaming]: 'Gaming',
  [ContentType.HealthAndFitness]: 'Health & Fitness',
  [ContentType.Lifestyle]: 'Lifestyle',
  [ContentType.Music]: 'Music',
  [ContentType.ScienceAndEducation]: 'Science & Education',
  [ContentType.Technology]: 'Technology',
  [ContentType.Travel]: 'Travel',
}
