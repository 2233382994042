import { ButtonHTMLAttributes } from 'react'
import styled, { css } from 'styled-components'

import { BrandColors } from '../../tokens/colors'
import { MediaQueries } from '../../tokens/mediaQueries'
import { Opacities } from '../../tokens/opacities'
import { Transitions } from '../../tokens/transitions'
import { FontFamily } from '../../tokens/typography'
import { rem } from '../../utils/rem'
import BaseButton from '../base-button/BaseButton'

export interface NavigationListItemProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  active?: boolean
  direction?: 'column' | 'row'
}

const NavigationListItem = styled(BaseButton)<NavigationListItemProps>(
  ({ direction = 'column', active = false, disabled = false }) => css`
    position: relative;
    display: flex;
    align-items: center;
    background: transparent;
    transition: background ${Transitions.micro};
    font-family: ${FontFamily.regular};
    font-weight: bold;
    font-size: ${rem(16)};
    line-height: ${rem(20)};
    padding: ${rem(12)} ${rem(20)};
    min-height: ${rem(48)};
    text-decoration: none;

    ${MediaQueries.desktop} {
      font-size: ${rem(18)};
      line-height: ${rem(20)};
      padding: ${rem(12)} ${rem(24)};
    }

    :before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      background: ${BrandColors.white.toString()};
      transition: transform ${Transitions.micro};
    }

    :hover {
      background: ${BrandColors.white.alpha(Opacities.eight).toString()};
    }

    :focus,
    :active {
      background: transparent;
    }

    ${disabled &&
    css`
      pointer-events: none;
      color: ${BrandColors.white.alpha(Opacities.thirtyfive).toString()};
    `}

    ${() => {
      switch (direction) {
        case 'row':
          return css`
            justify-content: center;
            min-width: ${rem(72)};
            height: ${rem(64)};

            ${MediaQueries.desktop} {
              min-width: ${rem(88)};
              height: ${rem(88)};
            }

            :before {
              width: 100%;
              height: ${rem(4)};
              transform: scaleY(0);
              transform-origin: left bottom;
            }

            :focus,
            :active {
              :before {
                transform: scaleY(1);
              }
            }

            ${active &&
            css`
              :before {
                transform: scaleY(1);
              }
            `}
          `
        case 'column':
        default:
          return css`
            text-align: left;
            justify-content: flex-start;
            width: 100%;
            min-height: ${rem(48)};

            :before {
              width: ${rem(4)};
              height: 100%;
              transform: scaleX(0);
              transform-origin: left top;
            }

            :focus,
            :active {
              :before {
                transform: scaleX(1);
              }
            }

            ${active &&
            css`
              :before {
                transform: scaleX(1);
              }
            `}
          `
      }
    }}
  `,
)

export default NavigationListItem
