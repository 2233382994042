import React, { FunctionComponent } from 'react'

import { rem } from '../../utils/rem'
import Dialog, { DialogProps } from '../dialog/Dialog'
import IndeterminateProgress from '../indeterminate-progress/IndeterminateProgress'
import SizedBox from '../sized-box/SizedBox'
import TextButton from '../text-button/TextButton'

export interface ConfirmationDialogProps extends DialogProps {
  disabled?: boolean
  loading?: boolean
  onConfirm?: () => void
  onRedirect?: () => void
}

const ConfirmationDialog: FunctionComponent<ConfirmationDialogProps> = ({
  onConfirm,
  onRedirect,
  loading = false,
  ...props
}) => (
  <Dialog
    size="medium"
    actions={
      <>
        {onRedirect && (
          <TextButton size="small" variant="tertiary" onClick={onRedirect}>
            Edit payment details
          </TextButton>
        )}
        {props.onClose && (
          <TextButton size="small" variant="tertiary" onClick={props.onClose}>
            Cancel
          </TextButton>
        )}
        <SizedBox width={{ desktop: rem(24), mobile: rem(16) }} />
        <TextButton
          leading={loading ? <IndeterminateProgress /> : undefined}
          size="small"
          disabled={loading || props.disabled}
          onClick={onConfirm}
        >
          Confirm
        </TextButton>
      </>
    }
    extendedFooter={!!onRedirect}
    {...props}
  />
)
export default ConfirmationDialog
