import React, { type FC, type ReactNode, useMemo } from 'react'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore Named capturing groups are not allowed before ES2018, but it's actually being compiled using ES2019.
const MARKDOWN_LINK_REGEX = /\[(?<text>.+?)\]\((?<url>.+?)\)/g

const EMAIL_REGEX =
  /((?:(?:(?:[^<>()[\]\\.,;:\s@"]+(?:\.[^<>()[\]\\.,;:\s@"]+)*)|(?:".+"))@(?:(?:\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(?:(?:[a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))))/g

const LINK_REGEX = new RegExp(
  '((?:https?://)?(?:www\\.)?[-a-zA-Z0-9@:%_]{1,256}(?:\\.[a-zA-Z0-9]{1,6})+(?:/[-a-zA-Z0-9@:%_.?&=/]*)?)',
  'g',
)

type MatchToNode = (link: string) => ReactNode
type GroupsToNode = (link: Record<string, string>) => ReactNode
type GeneratorFn = MatchToNode | GroupsToNode
type CreateLinkRegex = { create: GeneratorFn; regex: RegExp }

export interface AutoLinkProps {
  text: string
}

const AutoLink: FC<AutoLinkProps> = ({ text }): Array<string | ReactNode> =>
  createLinksForRegexes(
    [
      { create: createMarkdownLink, regex: MARKDOWN_LINK_REGEX },
      { create: createEmailLink, regex: EMAIL_REGEX },
      { create: createLink, regex: LINK_REGEX },
    ],
    [text],
  )

const createLink = (link: string, text: string = link) => {
  const normalizedText = useMemo(() => text.replace(/^https?:\/\//i, ''), [text])

  return (
    <a key={link} href={link} target="_blank" rel="noreferrer">
      {normalizedText}
    </a>
  )
}

const createMarkdownLink = (matches: Record<string, string>) => {
  if (!matches.url || !matches.text) return <></>

  return (
    <a key={matches.url} href={matches.url} target="_blank" rel="noreferrer">
      {matches.text}
    </a>
  )
}

const createEmailLink = (email: string) => createLink(`mailto:${email}`, email)

const createLinksForRegexes = (
  regexes: CreateLinkRegex[],
  nodes: Array<string | ReactNode>,
): Array<string | ReactNode> =>
  regexes.reduce(
    (nodes, { regex, create }) =>
      nodes.flatMap((node) => (typeof node === 'string' ? createLinksForRegex(node, regex, create) : [node])),
    nodes,
  )

const createLinksForRegex = (text: string, regex: RegExp, create: GeneratorFn): Array<string | ReactNode> => {
  const result: Array<string | ReactNode> = []
  const matches = Array.from(text.matchAll(regex))
  let offset = 0

  if (matches.length === 0) {
    return [text]
  }

  for (const match of matches) {
    // Any text between last match (or start) and this match?
    if (match.index > offset) {
      result.push(text.slice(offset, match.index))
    }

    // Create a new link.
    if (match.groups) {
      result.push((create as GroupsToNode)(match.groups))
    } else {
      result.push((create as MatchToNode)(match[0]))
    }

    offset = match.index + match[0].length
  }

  // Any text between final match and EOS?
  if (offset < text.length) {
    result.push(text.slice(offset))
  }

  return result
}

export default AutoLink
