import { type GraphQLResult } from '@aws-amplify/api-graphql'
import { API, graphqlOperation } from 'aws-amplify'
import React, { createContext, type FunctionComponent, type ReactNode } from 'react'
import { useQuery, useQueryClient } from 'react-query'

import * as termsOperations from '@/graphql/terms'
import { useUser } from '@/hooks/useUser'
import { type AcceptedTerm } from '@/providers/UserProvider'

export enum Terms {
  collabGuidelines = 'COLLAB_GUIDELINES',
}

export interface TermsContext {
  acceptTerms: (terms: Terms) => Promise<AcceptedTerm[]>
  hasAcceptedTerms?: (terms: string) => boolean
  myAcceptedTerms?: AcceptedTerm[]
}

export const termsContext = createContext<TermsContext>({
  acceptTerms: () => undefined,
  hasAcceptedTerms: () => undefined,
  myAcceptedTerms: undefined,
})

interface TermsProviderProps {
  children: ReactNode
}

const TermsProvider: FunctionComponent<React.PropsWithChildren<TermsProviderProps>> = (props) => {
  const { isLoggedIn } = useUser()
  const queryClient = useQueryClient()

  /**
   * Retrieve my accepted terms.
   */
  const { data: myAcceptedTerms } = useQuery(
    'getMyAcceptedTerms',
    async () => {
      const result = (await API.graphql(graphqlOperation(termsOperations.getMyAcceptedTerms))) as GraphQLResult<{
        getMyAcceptedTerms: AcceptedTerm[]
      }>

      return result.data?.getMyAcceptedTerms
    },
    { enabled: isLoggedIn },
  )

  /**
   * Add an accepted terms.
   */
  const addAcceptedTerms = async (terms: string) => {
    const result = (await API.graphql(
      graphqlOperation(termsOperations.addAcceptedTerms, {
        terms,
      }),
    )) as GraphQLResult<{ addAcceptedTerms: AcceptedTerm[] }>

    void queryClient.invalidateQueries('getMyAcceptedTerms')

    return result.data?.addAcceptedTerms
  }

  /**
   * Check if given terms are accepted.
   */
  const hasAcceptedTerms = (termsToCheck: Terms) =>
    myAcceptedTerms && myAcceptedTerms.filter((term: AcceptedTerm) => term.terms === termsToCheck).length > 0

  const context = {
    acceptTerms: addAcceptedTerms,
    hasAcceptedTerms,
    myAcceptedTerms,
  }

  return <termsContext.Provider value={context}>{props.children}</termsContext.Provider>
}

export default TermsProvider

export const TermsConsumer = termsContext.Consumer
