import { BrandColors } from '../tokens/colors'
import { LedoTheme } from './LedoTheme'

const tickleMePinkTheme: LedoTheme = {
  background: BrandColors.tickleMePink,
  foreground: BrandColors.blackcurrant,
  primary: BrandColors.darkBlue,
  secondary: BrandColors.darkBlue,
}

export default tickleMePinkTheme
