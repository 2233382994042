import { GraphQLResult } from '@aws-amplify/api-graphql'

import { graphqlApi } from '../utils/graphql'
import { GroupRole } from './generated'
import { musicLibraryTrackFragment } from './musicLibrary'

export const groupCollaborationSettingsFragment = /* GraphQL */ `
  fragment GroupCollaborationSettings on CollaborationSettings {
    id
    royaltyShareEnabled
    fixedFeeEnabled
    platforms {
      id
      platform {
        name
      }
    }
    genres {
      id
      genre {
        name
      }
    }
  }
`

export const getGroups = /* GraphQL */ `
  query GetGroups {
    getGroups {
      id
      role
      name
      biography
      countryCode
      manager {
        id
      }
    }
  }
`

export const getGroupsByEmail = /* GraphQL */ `
  query GetGroupsByEmail($email: String!) {
    getGroupsByEmail(email: $email) {
      id
      role
      name
      avatar {
        small {
          url
          expiresAt
        }
        medium {
          url
          expiresAt
        }
        large {
          url
          expiresAt
        }
      }
      countryCode
    }
  }
`

export const getMyGroups = /* GraphQL */ `
  query GetMyGroups {
    getMyGroups {
      id
      role
      avatar {
        small {
          url
          expiresAt
        }
        medium {
          url
          expiresAt
        }
        large {
          url
          expiresAt
        }
      }
      name
      website
      biography
      countryCode
    }
  }
`

export const getMyGroup = /* GraphQL */ `
  query GetMyGroup($id: ID!) {
    getMyGroup(id: $id) {
      id
      role
      configured
      avatar {
        small {
          url
          expiresAt
        }
        medium {
          url
          expiresAt
        }
      }
      name
      biography
      countryCode
      supportedChats {
        profileToProfile
      }
      website
      myMenu
      manager {
        id
        groupId
        distributedProducts: products(filters: { isDistributed: true }) {
          id
          name
          releaseVersion
          consumerReleaseDate
          fugaId
          publishedAt
          artwork {
            small {
              url
              expiresAt
              expiresIn
            }
            medium {
              url
              expiresAt
              expiresIn
            }
          }
          tracks {
            id
            fugaId
            name
            version
            trackArtists {
              primary
              position
              artist {
                name
              }
            }
            compressedAudio {
              url
              expiresAt
            }
          }
        }

        musicLibraryProducts: products(filters: { isDistributed: false }) {
          id
          name
          releaseVersion
          consumerReleaseDate
          fugaId
          publishedAt
          artwork {
            small {
              url
              expiresAt
              expiresIn
            }
            medium {
              url
              expiresAt
              expiresIn
            }
          }
          tracks(filters: { inMusicLibrary: true }) {
            id
            fugaId
            name
            version
            trackArtists {
              primary
              position
              artist {
                name
              }
            }
            compressedAudio {
              url
              expiresAt
            }
          }
        }
      }
      members {
        userId
        userProfile {
          avatar {
            small {
              url
              expiresAt
            }
            medium {
              url
              expiresAt
            }
          }
          name
          email
        }
      }
      platformAccounts {
        id
        platform
        url
        followers {
          high
          low
        }
      }
      paymentSettings {
        entityType
      }
      payoutSettings {
        payoutType
      }
      address {
        name
        companyName
        addressLine1
        addressLine2
        postalCode
        city
        district
        country
      }
      bankAccount {
        accountHolderName
        type
        details {
          ... on IbanBankAccountDetails {
            iban
          }
        }
        currencyCode
      }
      paypalAccount {
        email
      }
      vatDetails {
        isVatLiable
        vatNumber
        chamberOfCommerceNumber
      }
      influencerCollaborationSettings {
        ...GroupCollaborationSettings
      }
      artistCollaborationSettings {
        ...GroupCollaborationSettings
      }
    }
  }
  ${groupCollaborationSettingsFragment}
`

export const getGroup = /* GraphQL */ `
  query GetGroup($id: ID!) {
    getGroup(id: $id) {
      id
      role
      avatar {
        small {
          url
          expiresAt
        }
        medium {
          url
          expiresAt
        }
        large {
          url
          expiresAt
        }
      }
      supportedChats {
        profileToProfile
      }
      manager {
        id
        distributedProducts: products(filters: { isDistributed: true }) {
          name
          consumerReleaseDate
          artwork {
            small {
              url
              expiresAt
              expiresIn
            }
            medium {
              url
              expiresAt
              expiresIn
            }
          }
          tracks {
            ...MusicLibraryTrack
          }
        }
        musicLibraryProducts: products(filters: { isDistributed: false }) {
          name
          consumerReleaseDate
          artwork {
            small {
              url
              expiresAt
              expiresIn
            }
            medium {
              url
              expiresAt
              expiresIn
            }
          }
          tracks(filters: { inMusicLibrary: true }) {
            ...MusicLibraryTrack
          }
        }
      }
      name
      biography
      countryCode
      artistCollaborationSettings {
        royaltyShareEnabled
        fixedFeeEnabled
        platforms {
          id
          platform {
            name
          }
        }
        genres {
          id
          genre {
            name
          }
        }
      }
      influencerCollaborationSettings {
        fixedFeeEnabled
        royaltyShareEnabled
        platforms {
          id
          platform {
            name
          }
        }
        genres {
          id
          genre {
            name
          }
        }
      }
      platformAccounts {
        id
        platform
        url
        followers {
          high
          low
        }
      }
    }
  }

  ${musicLibraryTrackFragment}
`

export const addGroup = /* GraphQL */ `
  mutation AddGroup($input: GroupInput!) {
    addGroup(input: $input) {
      id
      role
      avatar {
        small {
          url
          expiresAt
        }
        medium {
          url
          expiresAt
        }
      }
      name
      biography
      countryCode
    }
  }
`

export const editGroup = /* GraphQL */ `
  mutation EditGroup($id: ID!, $input: GroupInput!) {
    editGroup(id: $id, input: $input) {
      id
      role
      avatar {
        small {
          url
          expiresAt
        }
        medium {
          url
          expiresAt
        }
      }
      name
      biography
      countryCode
      manager {
        id
        groupId
        products {
          id
          name
          releaseVersion
          consumerReleaseDate
          artwork {
            small {
              url
              expiresAt
              expiresIn
            }
            medium {
              url
              expiresAt
              expiresIn
            }
          }
        }
      }
    }
  }
`

export const filterGroup = /* GraphQL */ `
  query FilterGroups($filters: GroupFilters, $sort: GroupSortOptions, $page: Int, $size: Int) {
    filterGroups(filters: $filters, sort: $sort, page: $page, size: $size) {
      totalCount
      groups {
        ...GroupCard
      }
    }
  }

  fragment GroupCard on PublicGroup {
    id
    name
    avatar {
      medium {
        url
        path
        expiresIn
        expiresAt
      }
    }
    countryCode
    platformAccounts {
      platform
      url
      followers {
        low
        high
      }
    }
    influencerCollaborationSettings {
      ...GroupCollaborationSettings
    }
    artistCollaborationSettings {
      ...GroupCollaborationSettings
    }
  }

  ${groupCollaborationSettingsFragment}
`
export const verifyGroupNameAndSlug = /* GraphQL */ `
  mutation VerifyGroupNameAndSlug($input: VerifyGroupNameAndSlugInput!) {
    verifyGroupNameAndSlug(input: $input) {
      nameAvailable
      normalizedSlug
      slugAvailable
    }
  }
`

export const editGroupRole = async (variables: { groupId: string; role: GroupRole }): Promise<GraphQLResult<void>> =>
  graphqlApi<void>(
    /* GraphQL */ `
      mutation EditGroupRole($groupId: ID!, $role: GroupRole!) {
        editGroupRole(groupId: $groupId, role: $role)
      }
    `,
    variables,
  )
