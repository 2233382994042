import React, { type FunctionComponent, type ReactChild, useRef } from 'react'
import styled, { css } from 'styled-components'
import { v4 as uuid } from 'uuid'

import { BrandColors } from '../../tokens/colors'
import { MediaQueries } from '../../tokens/mediaQueries'
import { Opacities } from '../../tokens/opacities'
import { rem } from '../../utils/rem'
import { type BaseCheckboxInputProps } from '../base-checkbox-input/BaseCheckboxInput'
import CheckboxInput from '../checkbox-input/CheckboxInput'
import Column from '../column/Column'
import FormField, { type FormFieldProps } from '../form-field/FormField'
import Row from '../row/Row'
import SizedBox from '../sized-box/SizedBox'

export interface CheckboxFieldProps extends Omit<FormFieldProps, 'htmlFor'> {
  hideHint?: boolean
  id?: string
  input: Omit<BaseCheckboxInputProps, 'disabled' | 'error' | 'checkmark'>
  label: ReactChild
  small?: boolean
}

const CheckboxField: FunctionComponent<React.PropsWithChildren<CheckboxFieldProps>> = ({
  id,
  input,
  label,
  small,
  ...props
}) => {
  const idRef = useRef(id ?? uuid())
  return (
    <FormField {...props}>
      <Row crossAxisAlignment="flex-start">
        <CheckboxInputAligner>
          <CheckboxInput {...input} id={idRef.current} disabled={props.disabled} error={!!props.error} small={small} />
        </CheckboxInputAligner>
        <SizedBox width={rem(12)} />
        <Label htmlFor={idRef.current} disabled={props.disabled} ellipsis={props.hideHint}>
          {label}
        </Label>
      </Row>
    </FormField>
  )
}

export default CheckboxField

const CheckboxInputAligner = styled(Column).attrs({
  mainAxisAlignment: 'center',
})`
  height: ${rem(24)};

  ${MediaQueries.desktop} {
    height: ${rem(28)};
  }
`

interface LabelProps {
  disabled?: boolean
  ellipsis?: boolean
}

const Label = styled.label<LabelProps>`
  font-size: ${rem(16)};
  line-height: ${rem(24)};
  user-select: none;
  color: ${BrandColors.white.toString()};

  ${MediaQueries.desktop} {
    font-size: ${rem(18)};
    line-height: ${rem(22)};
  }

  ${({ ellipsis }) =>
    ellipsis &&
    css`
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    `}

  ${({ disabled = false }) =>
    disabled &&
    css`
      opacity: ${Opacities.thirtyfive};
    `}
`
