import React, { type FunctionComponent, useRef } from 'react'
import styled, { css } from 'styled-components'
import { v4 as uuid } from 'uuid'

import { BrandColors, SystemColors } from '../../tokens/colors'
import { rem } from '../../utils/rem'
import FormField, { type FormFieldProps } from '../form-field/FormField'
import SearchIcon from '../icon/SearchIcon'
import Input, { type InputProps } from '../input/Input'

export interface TextFieldProps extends Omit<FormFieldProps, 'htmlFor'> {
  id?: string
  input?: Omit<InputProps, 'disabled' | 'error'>
  light?: boolean
  onClickSearchButton?: () => void
  transparent?: boolean
}

const SearchField: FunctionComponent<React.PropsWithChildren<TextFieldProps>> = ({
  id,
  input,
  light = false,
  transparent = false,
  onClickSearchButton,
  ...props
}) => {
  const idRef = useRef(id ?? uuid())

  return (
    <FormField {...props} htmlFor={idRef.current}>
      <Wrapper>
        <StyledInput
          {...input}
          light={!!light}
          transparent={!!transparent}
          type="search"
          id={idRef.current}
          disabled={props.disabled}
          error={!!props.error}
          data-cy={input?.dataCy}
        />
        <SearchButton type="submit" onClick={onClickSearchButton}>
          <SearchIcon />
        </SearchButton>
      </Wrapper>
    </FormField>
  )
}

export default SearchField

const Wrapper = styled.div`
  position: relative;
`
const StyledInput = styled(Input)`
  padding-right: ${rem(40)};
  appearance: none;

  &::-webkit-search-cancel-button {
    appearance: none;
  }

  ${({ error }) => error && `border: 1px solid ${SystemColors.danger.toString()} !important; `}
`

const SearchButton = styled.button`
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  padding: 0 ${rem(18)};
  color: ${BrandColors.white.toString()};
`
