import React from 'react'
import styled, { css } from 'styled-components'

import { BrandColors, SystemColors } from '../../tokens/colors'
import { MediaQueries } from '../../tokens/mediaQueries'
import { Opacities } from '../../tokens/opacities'
import { Transitions } from '../../tokens/transitions'
import { rem } from '../../utils/rem'
import BaseCheckboxInput, { type BaseCheckboxInputProps } from '../base-checkbox-input/BaseCheckboxInput'

const checkmark = (
  <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2 6L5 9L12 2" stroke="currentColor" strokeWidth="2" strokeLinecap="square" />
  </svg>
)

export type CheckboxInputProps = Omit<BaseCheckboxInputProps, 'checkmark'>

const CheckboxInput = styled(BaseCheckboxInput).attrs({
  checkmark,
})`
  width: ${rem(20)};
  height: ${rem(20)};
  outline: 1px solid transparent;
  outline-offset: ${rem(2)};
  transition: ${Transitions.micro};
  background-color: ${BrandColors.white.alpha(Opacities.eight).toString()};
  ${MediaQueries.desktop} {
    width: ${rem(24)};
    height: ${rem(24)};
  }

  ${({ small }) =>
    small &&
    css`
      width: 1rem;
      height: 1rem;
      ${MediaQueries.desktop} {
        width: 1.2rem;
        height: 1.2rem;
      }
    `}

  ${({ transparent }) =>
    transparent &&
    css`
      background-color: transparent;
    `}
  ${({ checked }) =>
    checked &&
    css`
      color: ${BrandColors.white.toString()};
    `}

  input:focus + & {
    outline-color: ${SystemColors.info.toString()};
  }

  input:focus:not(:checked) + &,
  input:active + &,
  input:hover + & {
    background: ${BrandColors.white.alpha(Opacities.eight).toString()};
  }

  input:active + &,
  input:hover + & {
    ${({ checked }) =>
      checked &&
      css`
        border-color: ${BrandColors.white.toString()};
        background: ${BrandColors.white.alpha(Opacities.fiftyfive).toString()};
      `}
  }

  input:disabled + & {
    border-color: ${BrandColors.white.toString()};
    opacity: ${Opacities.thirtyfive};
  }

  ${({ error, checked }) =>
    error &&
    css`
      border-color: ${SystemColors.danger.toString()};

      input:focus + &,
      input:active + &,
      input:hover + & {
        background: ${SystemColors.danger.alpha(Opacities.eight).toString()};
      }

      ${checked &&
      css`
        background: ${SystemColors.danger.toString()};
        input:focus + &,
        input:active + &,
        input:hover + & {
          border-color: ${SystemColors.danger.toString()};
          background: ${SystemColors.danger.alpha(Opacities.fiftyfive).toString()};
        }
      `}
    `}
`

export default CheckboxInput
