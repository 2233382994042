import React, { FunctionComponent } from 'react'

import { BrandColors } from '../../tokens/colors'
import { IconData } from '../../tokens/icons'
import { Opacities } from '../../tokens/opacities'
import { rem } from '../../utils/rem'
import Circle from '../circle/Circle'
import Icon from '../icon/Icon'
import { ResponsiveSize } from '../sized-box/SizedBox'

export interface IconCircleProps {
  icon: IconData
  iconSize?: string
  size?: string | number | ResponsiveSize
}

const IconCircle: FunctionComponent<React.PropsWithChildren<IconCircleProps>> = ({
  icon,
  size = { desktop: rem(48), mobile: rem(40) },
  iconSize,
}) => (
  <Circle size={size} color={BrandColors.white.alpha(Opacities.eight).toString()}>
    <Icon icon={icon} size={iconSize} />
  </Circle>
)

export default IconCircle
